import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import { useState } from "react";

export default function OrganizationDate({
  onChange,
}: {
  onChange: (dateString: string) => void;
}) {
  const [currDate, setCurrDate] = useState<string>(moment().format("MMM YYYY"));

  const handleLeft = () => {
    const _date = moment(currDate, "MMM YYYY")
      .subtract(1, "M")
      .format("MMM YYYY");
    setCurrDate(_date);
    onChange(_date);
  };

  const handleRight = () => {
    const _date = moment(currDate, "MMM YYYY").add(1, "M").format("MMM YYYY");
    setCurrDate(_date);
    onChange(_date);
  };

  return (
    <div
      className="divSpread divAlignItemsOnly p-0"
      style={{
        background: "linear-gradient(135deg, #6253e1, #04befe)",
        fontWeight: "bold",
        color: "white",
      }}
    >
      <Button
        onClick={handleLeft}
        id="date-left"
        type="text"
        style={{ color: "white" }}
        icon={<CaretLeftOutlined />}
      ></Button>

      <div className="mx-">
        {moment(currDate, "MMM YYYY").format("MMM YYYY")}
      </div>

      <Button
        onClick={handleRight}
        id="date-left"
        type="text"
        style={{ color: "white" }}
        icon={<CaretRightOutlined />}
      ></Button>
    </div>
  );
}
