import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  Empty,
  Flex,
  Image,
  Input,
  Row,
  Select,
  Skeleton,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import Title from "antd/es/typography/Title";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateProductImage } from "../../../api/api";
import { useDeleteProductImage } from "../../../api/api.delete";
import {
  useGetOrganizationById,
  useGetProductById,
  useGetProductImages,
  useGetVendors,
} from "../../../api/api.get";
import {
  useUpdateProduct,
  useUpdateProductCover,
} from "../../../api/api.update";
import ErrorPanel from "../../../components/error.panel.component";
import { InfoIcon } from "../../../components/icon.component";
import { useMessage } from "../../../components/notifications/message";
import { useNotifications } from "../../../components/notifications/notification";
import { useCurrency } from "../../../hooks/useCurrency";
import { IProduct, IProductImage, IVendor } from "../../../types/types";
import { toReadableDateUtc } from "../../../utils/date.utils";
import { getBase64 } from "../../../utils/file.utils";
import { capitalize, getCurrencyCode } from "../../../utils/functions";

export default function ProductsEditPage() {
  const { id, pid } = useParams();
  const { currency } = useCurrency();
  const navigate = useNavigate();

  const { data: orgData } = useGetOrganizationById(id);

  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Product updated successfully.",
  });

  const { mutateAsync, isPending } = useUpdateProduct(id, pid);

  const { data: product, isLoading } = useGetProductById(id, pid);

  const formik = useFormik({
    initialValues: {
      title: product?.title ?? "",
      description: product?.description ?? "",
      amount: product?.price?.value ?? 0,
      oldAmount: product?.price?.oldValue ?? 0,
      externalId: product?.externalId,
      sku: product?.sku,
      quantity: product?.quantity ?? 0,
      salesTaxExcluded: product?.salesTaxExcluded,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter name"),
      description: Yup.string(),
      amount: Yup.string()
        .matches(
          /^(0|[1-9]\d*)(\.\d{2})?$/,
          "Please enter valid amount upto 2 decimals.",
        )
        .required("Please enter amount"),
      externalId: Yup.string(),
      quantity: Yup.string()
        .optional()
        .matches(/^\d+$/, "Value must be an integer")
        .nullable(),
      sku: Yup.string(),
      salesTaxExcluded: Yup.bool(),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        title: values.title,
        description: values.description,
        externalId: values.externalId,
        sku: values.sku,
        quantity: +values.quantity,
        price: {
          currency: getCurrencyCode(orgData?.country),
          value: +values.amount,
          oldValue: +values.oldAmount,
        },
        salesTaxExcluded: values.salesTaxExcluded,
      })
        .then(() => {
          formik.resetForm();
          notifySuccess();
        })
        .catch((e) => {
          if (e.response?.data?.statusCode === 409) {
            notifyError(e.response?.data?.message ?? "Something went wrong");
          } else {
            notifyError();
          }
        });
    },
  });

  return (
    <div className="body p-40 flex-1">
      {notification}

      <div></div>

      <div className="divSpread divAlignItemsOnly">
        <Button
          type="link"
          onClick={() => navigate(`/organizations/${id}/products`)}
        >
          Back
        </Button>
        <Title level={3}>{capitalize(product?.title)}</Title>
        <div></div>
      </div>

      <Flex gap={50} vertical>
        <Skeleton loading={isLoading}>
          <form onSubmit={formik.handleSubmit} className="card p-40">
            <Flex gap={30} vertical style={{ padding: 20 }}>
              <div className="divSpread">
                <div>
                  <span className="infoMsg">
                    {toReadableDateUtc(product?.createdAt)}
                  </span>
                </div>
                <div>
                  <Flex gap={10}>
                    <Button
                      loading={isPending}
                      onClick={formik.submitForm}
                      type="primary"
                    >
                      Save
                    </Button>
                  </Flex>
                </div>
              </div>

              <div>
                <h4>Name</h4>
                <Input placeholder="Name" {...formik.getFieldProps("title")} />
                <ErrorPanel message={formik.errors.title} />
              </div>
              <div>
                <h4>Decription</h4>

                <Input
                  placeholder="Decription"
                  {...formik.getFieldProps("description")}
                />
                <ErrorPanel message={formik.errors.description} />
              </div>

              <div>
                <h4>Price</h4>
                <Input
                  prefix={currency}
                  placeholder="Price"
                  {...formik.getFieldProps("amount")}
                />
                <ErrorPanel message={formik.errors.amount} />
              </div>

              <div>
                <Flex gap={5}>
                  <h4>Old Price</h4>
                  <InfoIcon title="Shown in striked through text" />
                </Flex>
                <Input
                  prefix={currency}
                  placeholder="Old Price..."
                  {...formik.getFieldProps("oldAmount")}
                />
              </div>

              <div>
                <h4>Available Quantity</h4>
                <Input
                  placeholder="BarCode Id"
                  {...formik.getFieldProps("quantity")}
                />
                <ErrorPanel message={formik.errors.quantity} />
              </div>

              <div>
                <h4>Barcode</h4>
                <Input
                  maxLength={100}
                  placeholder="BarCode Id"
                  {...formik.getFieldProps("externalId")}
                />
              </div>

              <div>
                <h4>SKU</h4>
                <Input
                  maxLength={100}
                  placeholder="BarCode Id"
                  {...formik.getFieldProps("sku")}
                />
              </div>

              <Checkbox
                onChange={(e) =>
                  formik.setFieldValue("salesTaxExcluded", e.target.checked)
                }
                checked={formik.values.salesTaxExcluded}
              >
                <h4 className="">Excluded from Sales Tax?</h4>
              </Checkbox>
            </Flex>
          </form>
        </Skeleton>

        <ProductImageSection product={product} />

        <SupplyChainSection product={product} />
      </Flex>
    </div>
  );
}

function ProductImageSection({ product }: { product?: IProduct }) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { messageSuccess, notification, messageError } = useMessage();
  const { id, pid } = useParams();

  const { data: images, isLoading } = useGetProductImages(id, pid);

  const { mutateAsync, isPending } = useCreateProductImage(id, pid);

  const {
    mutateAsync: updateCoverImageAsync,
    isPending: isUpdateCoverImagePending,
  } = useUpdateProductCover(id, pid);

  const { mutateAsync: deleteImageAsync, isPending: isDeletePending } =
    useDeleteProductImage(id, pid);

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file, fileList) => {
      const temp = [...fileList];
      setFileList(temp);
      return false;
    },
    fileList,
  };

  const handleUpload = async () => {
    const totalImages = (images?.length ?? 0) + fileList.length;

    if (totalImages > 5) {
      messageError("Cannot add more than 5 images per product.");
      return;
    }

    const promises = [];
    for (const file of fileList) {
      promises.push(
        getBase64(file).then((image) => {
          return mutateAsync({
            image: image!!,
            name: file.name,
            type: file.type,
          });
        }),
      );
    }

    Promise.all(promises)
      .then(() => messageSuccess("All images uploaded successfully."))
      .catch(() => messageError("Whoops! One or more images failed to upload."))
      .finally(() => setFileList([]));
  };

  const handleDelete = (imageId: string) => {
    deleteImageAsync({ imageId }).catch(() => messageError());
  };

  const handleUpdateCover = (imageId: string) => {
    updateCoverImageAsync({ imageId }).catch(() => messageError());
  };

  return (
    <form onSubmit={() => {}} className="card p-40">
      {notification}
      <div className="divCenterAlign">
        <Title level={3}>Images</Title>
      </div>

      <div className="divSpread my-20">
        <div className="flex-1 divColumn p-20">
          <span className="infoMsg">You can add upto 5 images per product</span>

          <div
            className="divCenterAlign my-80"
            style={{
              border: "1px solid darkgrey",
              padding: 20,
              borderRadius: 20,
              height: "100%",
            }}
          >
            {!product?.cover && (
              <Empty description="No Cover Image Available" />
            )}
            {product?.cover && (
              <Image
                src={product?.cover}
                width={"auto"}
                style={{ border: "1px solid darkgrey" }}
              />
            )}
          </div>
        </div>

        <div className="flex-1 p-20">
          <div className="">
            <Flex gap={10}>
              <Upload {...props} multiple={true} accept="image/png,image/jpeg">
                <Button
                  loading={isPending}
                  disabled={(images && images.length >= 5) || isPending}
                  icon={<UploadOutlined />}
                >
                  Select Image
                </Button>
              </Upload>

              <Button
                type="primary"
                onClick={handleUpload}
                disabled={
                  images && (images.length >= 5 || fileList.length === 0)
                }
              >
                Upload
              </Button>
            </Flex>
          </div>

          <div className="my-80">
            <Skeleton loading={isLoading || isDeletePending}>
              <Row gutter={[30, 30]}>
                {images?.map((image: IProductImage) => (
                  <div className="mx-20 divColumn">
                    <div className="my-10 divRight">
                      <DeleteOutlined
                        style={{ color: "red" }}
                        onClick={() => handleDelete(image.id)}
                      />
                    </div>

                    <Image width={200} src={image.image} preview={false} />
                    <div className="divCenterAlign my-5">
                      {product?.coverId !== image.id && (
                        <Button
                          type="link"
                          disabled={isUpdateCoverImagePending}
                          loading={isUpdateCoverImagePending}
                          onClick={() => handleUpdateCover(image.id)}
                        >
                          Set As Cover
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              </Row>
            </Skeleton>
          </div>
        </div>
      </div>

      <Flex gap={30} vertical style={{ padding: 20 }}>
        <div className="divFlex">
          <div className="flex-1"></div>
        </div>
      </Flex>
    </form>
  );
}

function SupplyChainSection({ product }: { product?: IProduct }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { mutateAsync, isPending } = useUpdateProduct(id, product?._id);
  const { messageSuccess, notification, messageError } = useMessage();

  const { data: vendorsData } = useGetVendors(id);

  const formik = useFormik({
    initialValues: {
      restock: product?.restock,
      vendorId: product?.vendorId ?? "",
      restockQuantity: product?.restockQuantity ?? "",
      restockThreshold: product?.restockThreshold ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      vendorId: Yup.string(),
      restockQuantity: Yup.string()
        .optional()
        .matches(/^\d+$/, "Value must be an integer")
        .nullable(),
      restockThreshold: Yup.string()
        .optional()
        .matches(/^\d+$/, "Value must be an integer")
        .nullable(),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        restock: values.restock,
        vendorId: values.vendorId,
        restockQuantity: +values.restockQuantity,
        restockThreshold: +values.restockThreshold,
      })
        .then(() => {
          messageSuccess("Data successfully saved");
        })
        .catch((e) => {
          messageError("Whoops! Please try again.");
        });
    },
  });

  return (
    <div className="card p-40">
      <form>
        {notification}
        <div className="divCenterAlign">
          <Title level={3}>Supply Chain</Title>
        </div>

        <div className="divRight">
          <Button
            type="primary"
            onClick={formik.submitForm}
            loading={isPending}
          >
            Save
          </Button>
        </div>

        <Flex gap={25} vertical>
          <Checkbox
            checked={formik.values.restock}
            onChange={(e) => formik.setFieldValue("restock", e.target.checked)}
          >
            <h3>Enable Restock</h3>
          </Checkbox>
          <Flex gap={15} vertical>
            <strong>Vendor</strong>
            <Flex gap={5}>
              <Select
                disabled={!formik.values.restock}
                size="large"
                style={{ width: 300 }}
                value={formik.values.vendorId}
                onSelect={(value) => formik.setFieldValue("vendorId", value)}
                options={vendorsData?.map((v: IVendor) => {
                  return {
                    label: v.name,
                    value: `${v.id}`,
                  };
                })}
              />
              {!!formik.values.vendorId && (
                <Button
                  type="link"
                  onClick={() => formik.setFieldValue("vendorId", "")}
                >
                  Reset
                </Button>
              )}
            </Flex>

            <Flex gap={5}>
              <InfoIcon />
              <span className="infoMsg mediumText">
                If left empty, the vendor stored for this Organization will be
                used.
              </span>
            </Flex>

            {vendorsData?.length === 0 && (
              <Button
                type="link"
                onClick={() =>
                  navigate(`/organizations/${id}/settings/vendors`)
                }
              >
                Add Vendor
              </Button>
            )}
          </Flex>
          <Divider />

          <Flex vertical gap={15} className="my-0" style={{ width: 400 }}>
            <Flex vertical gap={10}>
              <strong>Restock Threshold</strong>
              <Input
                maxLength={6}
                disabled={!formik.values.restock}
                placeholder="Quantity"
                style={{ width: 150 }}
                {...formik.getFieldProps("restockThreshold")}
              />
            </Flex>
            <Flex gap={5} style={{ width: 400, lineHeight: 1.3 }}>
              <InfoIcon />
              <span className="infoMsg mediumText">
                A stock request will be automatically generated for the vendor
                once the product availability reaches this quantity
              </span>
            </Flex>
            {formik.errors.restockThreshold && (
              <ErrorPanel message={formik.errors.restockThreshold} />
            )}
          </Flex>

          <Divider />

          <Flex vertical gap={15} className="my-0" style={{ width: 400 }}>
            <strong>Restock Quantity</strong>
            <Input
              maxLength={6}
              disabled={!formik.values.restock}
              placeholder="Quantity"
              style={{ width: 150 }}
              {...formik.getFieldProps("restockQuantity")}
            />
            {formik.errors.restockQuantity && (
              <ErrorPanel message={formik.errors.restockQuantity} />
            )}

            <Flex gap={5}>
              <InfoIcon />
              <span className="infoMsg mediumText">
                Quantity to send to the vendor for restock.
              </span>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </div>
  );
}
