import { Descriptions, Flex, Image, Input, Modal, Select, Table } from "antd";
import moment from "moment";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdateReimbursement } from "../../../api/api";
import {
  useGetOrganizationEmployeesByUsername,
  useGetReimbursementAttachments,
  useGetReimbursementsLifecycle,
} from "../../../api/api.get";
import { useNotifications2 } from "../../../components/notifications/notification";
import { IReimbursement, IReimbursementAttachment } from "../../../types/types";
import {
  getReimbursementActionOptions,
  getReimbursementLabel,
} from "../../../utils/reimbursements";
export default function ViewReimbursementModal({
  open,
  onCancel,
  reimbursement,
}: {
  reimbursement?: IReimbursement;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();

  const [nextStatus, setNextStatus] = useState<string>("");
  const [comments, setComments] = useState<string>("");

  const { notifySuccess, notification, notifyError } = useNotifications2();

  const { mutateAsync } = useUpdateReimbursement(id, reimbursement?.id);

  const { data: lifecycle } = useGetReimbursementsLifecycle(
    id,
    reimbursement?.id,
  );

  const handleUpdate = () => {
    mutateAsync({ status: nextStatus, notes: comments })
      .then(() => {
        setNextStatus("");
        setComments("");
        notifySuccess({
          title: "Success",
          description: "Reimbursement updated successfully.",
        });
        onCancel();
      })
      .catch(() => {
        notifyError();
      });
  };

  const { data: attachments } = useGetReimbursementAttachments(
    id,
    reimbursement?.id,
  );

  const { data: employeeData } = useGetOrganizationEmployeesByUsername(
    id,
    reimbursement?.username,
  );
  const items = useMemo(() => {
    return [
      {
        key: "0",
        label: "Employee",
        children: employeeData?.[0]?.email,
      },
      {
        key: "1",
        label: "Category",
        children: reimbursement?.category,
      },
      {
        key: "2",
        label: "Amount",
        children: reimbursement?.amount,
      },
      {
        key: "3",
        label: "Date",
        children: (
          <span>
            {moment(reimbursement?.expense_date).format("DD MMM YYYY")}
          </span>
        ),
      },
      {
        key: "4",
        label: "Notes",
        children: reimbursement?.notes,
      },
      {
        key: "5",
        label: "Status",
        children: (
          <strong>{getReimbursementLabel(reimbursement?.status!!)}</strong>
        ),
        width: 200,
      },
      {
        key: "5",
        label: "Last Updated",
        children: (
          <span>
            {moment(reimbursement?.last_updated).format("DD MMM YYYY")}
          </span>
        ),
      },
    ];
  }, [reimbursement, employeeData]);

  const options = useMemo(
    () => getReimbursementActionOptions(reimbursement?.status),

    [reimbursement?.status],
  );

  const columns = [
    {
      title: "Comments",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => <span>{getReimbursementLabel(status)}</span>,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (value: string) => (
        <span>{moment.utc(value).local().format("DD MMM YYYY h:mm A")}</span>
      ),
    },
  ];

  return (
    <div>
      {notification}
      <Modal
        title="Reimbursement"
        open={open}
        onOk={handleUpdate}
        onCancel={() => {
          setNextStatus("");
          setComments("");
          onCancel();
        }}
        okText={"Submit"}
        okButtonProps={{ disabled: !nextStatus }}
      >
        <Descriptions layout="vertical" title="" bordered items={items} />

        <div className="my-20">
          {attachments &&
            attachments.map((a: IReimbursementAttachment, index: number) => {
              if (a.mimetype !== "application/pdf") {
                return (
                  <Image
                    preview
                    key={index}
                    alt="reimbursement"
                    src={`data:image/png;base64, ${a.imageData}`}
                    style={{ width: 200, height: "auto" }}
                  />
                );
              }

              return (
                <iframe
                  key={index}
                  title={`reimbursement-${index}`}
                  width="100%"
                  height="400"
                  src={`data:application/pdf;base64, ${a.imageData}`}
                />
              );
            })}
        </div>

        <Flex gap={10} vertical>
          <strong>Action</strong>

          <Select
            defaultValue=""
            style={{ width: 200 }}
            value={nextStatus}
            notFoundContent="No action required"
            onChange={(value) => setNextStatus(value)}
            options={options}
          />

          <Input.TextArea
            placeholder="Comments..."
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </Flex>

        <Table dataSource={lifecycle} columns={columns} />
      </Modal>
    </div>
  );
}
