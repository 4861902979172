import { Button, Flex, Radio, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetSupplyChainStockRequests } from "../../../../api/api.get";
import { useProfile } from "../../../../hooks/useProfile";
import { toReadableDate } from "../../../../utils/date.utils";
import OrganizationDate from "../../organization-date";
import { capitalize } from "../../../../utils/functions";

const { Title } = Typography;

export default function StockRequestsPage() {
  const { id } = useParams();
  const [date, setDate] = useState(moment());
  const navigate = useNavigate();
  const { isMerchant, isVendor, isLoading } = useProfile();
  const [type, setType] = useState<"in" | "out" | undefined>();
  const [columns, setColumns] = useState<any[]>([]);

  const { data: stockRequests, refetch } = useGetSupplyChainStockRequests(
    id,
    type,
    false,
  );

  useEffect(() => {
    if (!isLoading) {
      setType(isMerchant ? "out" : "in");
    }
  }, [isMerchant, isLoading]);

  const handleDateChange = (dateString: string) => {
    setDate(moment(dateString, "MMM YYYY"));
  };

  useEffect(() => {
    if (date && type) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, type]);

  useEffect(() => {
    if (isVendor) {
      setColumns([
        {
          title: "Merchant Name",
          key: "vendor",
          render: (value: any) => (
            <Link to={`/organizations/${id}/stocks/${value.id}`}>
              {capitalize(value.orgName)}
            </Link>
          ),
        },
        {
          title: "Created",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (value: string) => <span>{toReadableDate(value)}</span>,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
      ]);
    } else if (isMerchant) {
      setColumns([
        {
          title: "Vendor Name",
          key: "vendor",
          render: (value: any) => (
            <Link to={`/organizations/${id}/stocks/${value.id}`}>
              {capitalize(value.vendorName)}
            </Link>
          ),
        },
        {
          title: "Created",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (value: string) => <span>{toReadableDate(value)}</span>,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
      ]);
    } else {
      setColumns([]);
    }
  }, [isVendor, isMerchant, id]);

  return (
    <div
      style={{ marginBottom: 100 }}
      className="card divColumn mx-40 my-40 flex-1"
    >
      <div className="divSpread mb-40">
        <OrganizationDate onChange={handleDateChange} />

        <Flex gap={5}>
          <Button
            type="link"
            onClick={() => navigate(`/organizations/${id}/vendors`)}
          >
            Vendors
          </Button>
          <Button type="link" onClick={() => navigate(`add`)}>
            Create
          </Button>
        </Flex>
      </div>
      <div className="divSpread divAlignItemsOnly m-40">
        <Title level={3}>Stock Requests</Title>

        {isVendor && (
          <div>
            <Radio.Group onChange={(e) => setType(e.target.value)} value={type}>
              <Radio value={"in"}>Incoming</Radio>
              <Radio value={"out"}>Outgoing</Radio>
            </Radio.Group>
          </div>
        )}
      </div>
      <Title level={5}>Request inventory from your vendors.</Title>
      <Table bordered dataSource={stockRequests} columns={columns} />
    </div>
  );
}
