import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { EXHUT_BIZ_LOCAL_STORAGE_TOKEN } from "../utils/constants";

export function useUnRegisterAsVendor(id?: string) {
  return _useDelete<any, any, any>({
    path: `organizations/${id}/vendor`,
    keys: ["organizations" + id!!],
  });
}

function _useDelete<T1, T2, T3>({
  path,
  keys,
  onSuccess,
  host,
  replacePathParams,
}: {
  host?: string;
  path: string;
  keys: (string | null | undefined)[];
  onSuccess?: () => void;
  replacePathParams?: string;
}) {
  const _host = host ?? process.env.REACT_APP_BE_URL;
  let url = `${_host}/${path}`;
  const queryClient = useQueryClient();
  return useMutation<T1, T2, T3>({
    mutationFn: (req) => {
      if (replacePathParams) {
        url = url.replace(
          `:${replacePathParams}`,
          (req as any)[replacePathParams],
        );
      }

      return axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem(EXHUT_BIZ_LOCAL_STORAGE_TOKEN),
        },
      });
    },
    mutationKey: keys,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: keys }),
  });
}

export function useDeleteTimeSheet(orgId: string) {
  return _useDelete({
    path: `organizations/${orgId}/timesheets/:timesheetId`,
    keys: ["organizations", orgId, "timesheets"],
    replacePathParams: "timesheetId",
  });
}

export function useDeleteOrgExpense(orgId: string) {
  return _useDelete({
    path: `organizations/${orgId}/expenses/:expenseId`,
    keys: ["organizations", orgId, "expenses"],
    replacePathParams: "expenseId",
  });
}

export function useDeleteInvoice(orgId: string) {
  return _useDelete({
    path: `organizations/${orgId}/invoices/:invoiceId`,
    keys: ["organizations", orgId, "invoices"],
    replacePathParams: "invoiceId",
  });
}

export function useDeleteDeduction(orgId?: string, empId?: string) {
  return _useDelete({
    path: `organizations/${orgId}/employees/${empId}/deductions/:deductionId`,
    keys: ["organizations", orgId, "employees", empId!!, "deductions"],
    replacePathParams: "deductionId",
  });
}

export function useDeletePayrollCycle(orgId: string) {
  return _useDelete({
    path: `organizations/${orgId}/payroll?cycleId=:cycleId`,
    keys: ["organizations", orgId, "payroll-cycles"],
    replacePathParams: "cycleId",
  });
}

export function useDeletePayroll(orgId?: string, cycleId?: string) {
  return _useDelete({
    path: `organizations/${orgId}/payroll?pid=:payrollId`,
    keys: ["organizations", orgId, "payroll"],
    replacePathParams: "payrollId",
  });
}

export function useDeletePayrollBonus(orgId?: string, cycleId?: string) {
  return _useDelete({
    path: `organizations/${orgId}/payroll-cycles/${cycleId}/bonuses/:payrollBonusId`,
    keys: ["organizations", orgId, "payroll-cycles", cycleId, "bonuses"],
    replacePathParams: "payrollBonusId",
  });
}

export function useDeleteOrgReimbursement(orgId: string) {
  return _useDelete({
    path: `organizations/${orgId}/reimbursements/:reimbursementId`,
    keys: ["organizations", orgId, "reimbursements"],
    replacePathParams: "reimbursementId",
  });
}

export function useDeleteReimbursementAttachment(orgId?: string, rid?: string) {
  return _useDelete({
    path: `organizations/${orgId}/reimbursements/${rid}/attachments/:attachmentId`,
    keys: ["organizations", orgId, "reimbursements", rid, "attachments"],
    replacePathParams: "attachmentId",
  });
}

export function useDeleteOrgReward(orgId: string) {
  return _useDelete({
    path: `organizations/${orgId}/rewards-level/:rewardId`,
    keys: ["organizations", orgId, "rewards-level"],
    replacePathParams: "rewardId",
  });
}

export function useDeleteVendor(orgId: string) {
  return _useDelete({
    path: `organizations/${orgId}/vendors/:vendorId`,
    keys: ["organizations", orgId, "vendors"],
    replacePathParams: "vendorId",
  });
}

export function useDeleteStockRequest(orgId: string) {
  return _useDelete({
    path: `organizations/${orgId}/supplychain/stock-requests/:stockReqId`,
    keys: ["supplychain", "stock-requests"],
    replacePathParams: "stockReqId",
  });
}

export function useDeleteOrgInvestment(orgId: string) {
  return _useDelete({
    path: `organizations/${orgId}/investments/:investmentId`,
    keys: ["organizations", orgId, "investments"],
    replacePathParams: "investmentId",
  });
}

export function useDeletePayrollSchedule(orgId: string) {
  return _useDelete({
    path: `organizations/${orgId}/payroll-schedules/:scheduleId`,
    keys: ["organizations", orgId, "payroll-schedules"],
    replacePathParams: "scheduleId",
  });
}

export function useDeleteOrgSales(orgId: string) {
  return _useDelete({
    path: `organizations/${orgId}/sales/:saleId`,
    keys: ["organizations", orgId, "sales"],
    replacePathParams: "saleId",
  });
}

export function useDeleteOrg(orgId?: string) {
  return _useDelete({
    path: `organizations/${orgId}`,
    keys: ["organizations", orgId],
  });
}

export function useDeleteProduct(orgId?: string, productId?: string) {
  return _useDelete({
    path: `organizations/${orgId}/products/:productId`,
    keys: ["products", productId],
    host: process.env.REACT_APP_PRODUCTS_URL,
    replacePathParams: "productId",
  });
}

export function useDeleteProductImage(orgId?: string, productId?: string) {
  return _useDelete({
    path: `organizations/${orgId}/products/${productId}/images/:imageId`,
    keys: ["products", productId],
    host: process.env.REACT_APP_PRODUCTS_URL,
    replacePathParams: "imageId",
  });
}

export function useDeletePayment(orgId: string) {
  return _useDelete({
    path: `organizations/${orgId}/payments/:paymentId`,
    keys: ["organizations", orgId, "payments"],
    replacePathParams: "paymentId",
  });
}

export function useDeleteRestaurantTable(orgId: string) {
  return _useDelete({
    path: `organizations/${orgId}/restaurant-tabs/:tableId`,
    keys: ["organizations", orgId, "restaurant-tabs"],
    replacePathParams: "tableId",
  });
}

export function useDeleteSubscriptions() {
  return _useDelete({
    path: `subscriptions`,
    keys: ["subscriptions"],
  });
}
