export function toFixed(value?: string | number): number {
  if (!value) {
    return 0.0;
  }
  const type = typeof value;
  if (type === "string") {
    value = +value;
  }
  return Math.floor(+value * 100) / 100;
}

export function toFixedDisplay(value?: string | number): string {
  return toFixed(value).toFixed(2);
}
