import { Dayjs } from "dayjs";
import moment, { Moment } from "moment";

export function toSQL(date?: Moment | string | Dayjs): string {
  if (!date) {
    return "";
  }
  if (typeof date === "object") {
    return date.format("YYYY-MM-DD");
  }
  return moment(date).format("YYYY-MM-DD");
}

/**
 * Returns date in DD MMM YYYY
 * @param date
 * @returns
 */
export function toReadableDate(date?: Moment | string | Dayjs): string {
  if (!date) {
    return "";
  }
  if (typeof date === "object") {
    return date.format("Do MMM YYYY");
  }
  return moment(date).format("Do MMM YYYY");
}

export function toReadableDateUtc(date?: string): string {
  return toReadableDate(moment.utc(date));
}
