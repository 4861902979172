import { Alert, Button, Flex, Modal } from "antd";
import { CSSProperties, useState } from "react";
import { lightenDarkenColor, useCSVReader } from "react-papaparse";
import { useParams } from "react-router-dom";
import { useCreateProductMultiple } from "../../../api/api";
export default function AddMultipleProductsModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const [error, setError] = useState<string>();
  const { CSVReader } = useCSVReader();
  const { mutateAsync, isPending } = useCreateProductMultiple(id);
  const [data, setData] = useState<any[]>([]);
  const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
  const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
    DEFAULT_REMOVE_HOVER_COLOR,
    40,
  );
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR,
  );

  const handleUpload = () => {
    mutateAsync(data)
      .then(() => onCancel())
      .catch((err) =>
        setError(
          err.response?.data?.message ??
            "Whoops! Something went wrong. Please try again.",
        ),
      );
  };

  return (
    <Modal
      title="Add Multiple Products"
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <Flex gap={30} vertical style={{ padding: 20 }}>
        <div className="divRight">
          <a
            href="https://help.expensehut.com/business/add-products#bulk"
            target="_blank"
            rel="noreferrer"
          >
            Help
          </a>
        </div>
        <CSVReader
          onUploadAccepted={(results: any) => {
            setData(results);
            setZoneHover(false);
          }}
          onDragOver={(event: DragEvent) => {
            event.preventDefault();
            setZoneHover(true);
          }}
          onDragLeave={(event: DragEvent) => {
            event.preventDefault();
            setZoneHover(false);
          }}
        >
          {({
            getRootProps,
            acceptedFile,
            getRemoveFileProps,
            Remove,
          }: any) => (
            <>
              <div
                {...getRootProps()}
                style={Object.assign(
                  {},
                  styles.zone,
                  zoneHover && styles.zoneHover,
                )}
              >
                {acceptedFile ? (
                  <>
                    <div style={styles.file}>
                      <div className="divCenterAlign">
                        <span>{acceptedFile.name}</span>
                      </div>

                      <div
                        {...getRemoveFileProps()}
                        style={styles.remove}
                        onMouseOver={(event: Event) => {
                          event.preventDefault();
                          setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                        }}
                        onMouseOut={(event: Event) => {
                          event.preventDefault();
                          setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                        }}
                      >
                        <Remove color={removeHoverColor} />
                      </div>
                    </div>
                  </>
                ) : (
                  "Drop CSV file here or click to upload"
                )}
              </div>
            </>
          )}
        </CSVReader>

        <div className="divCenterAlign my-40">
          <Flex gap={10}>
            <Button
              type="primary"
              loading={isPending}
              disabled={data.length === 0 || isPending}
              onClick={() => handleUpload()}
            >
              Start Upload
            </Button>
          </Flex>
        </div>

        <div>{error && <Alert type="error" message={error} />}</div>
      </Flex>
    </Modal>
  );
}

const styles = {
  zone: {
    alignItems: "center",
    border: `2px dashed grey`,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  } as CSSProperties,
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 60,
    width: "90%",
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
    padding: 10,
  } as CSSProperties,
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  size: {
    backgroundColor: "lightgrey",
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  } as CSSProperties,
  name: {
    backgroundColor: "lightgrey",
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  } as CSSProperties,
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  zoneHover: {
    borderColor: "lightgrey",
  } as CSSProperties,
  default: {
    borderColor: "grey",
  } as CSSProperties,
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  } as CSSProperties,
};
