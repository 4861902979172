import { Button, Flex, Modal, Table } from "antd";
import { useMemo, useRef } from "react";
import Barcode from "react-barcode";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  useGetOrganizationById,
  useGetOrganizationSalesById,
} from "../../../api/api.get";
import { useCurrency } from "../../../hooks/useCurrency";
import {
  IOrganization,
  IOrganizationSale,
  IProduct,
} from "../../../types/types";
import { toReadableDate } from "../../../utils/date.utils";
import { capitalize } from "../../../utils/functions";

export default function ReceiptModal({
  open,
  onCancel,
  sales,
}: {
  sales?: IOrganizationSale;
  open: boolean;
  onCancel: () => void;
}) {
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef,
  });
  const { id } = useParams();
  const { toAmountDisplay } = useCurrency();

  const { data: saleData } = useGetOrganizationSalesById(id, sales?.id);
  const { data: orgData } = useGetOrganizationById(id);

  const columns = useMemo(() => {
    if (saleData?.products) {
      const hasAnyItemWithOldPrice = saleData?.products.find(
        (p) => !!p.price.oldValue,
      );
      if (hasAnyItemWithOldPrice) {
        return [
          {
            title: "Item",
            dataIndex: "item",
            key: "item",
          },
          {
            title: "Reg. Price",
            dataIndex: "oldPrice",
            key: "oldPrice",
          },
          {
            title: "Price",
            dataIndex: "price",
            key: "price",
          },
        ];
      }
      return [
        {
          title: "Item",
          dataIndex: "item",
          key: "item",
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
          width: 200,
        },
      ];
    }
  }, [saleData?.products]);

  const dataSource = saleData?.products?.map(
    (product: IProduct, index: number) => {
      return {
        key: index,
        item: product.title,
        price: `${product.price?.value}`,
        oldPrice: `${product.price?.oldValue ? " " + product.price?.oldValue : ""}`,
      };
    },
  );

  return (
    <Modal
      title="Receipt"
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      footer={null}
    >
      <div className="divRight">
        <Button onClick={() => handlePrint()} type="link">
          Print
        </Button>
      </div>

      <div className="my-0 mb-20 p-40" ref={contentRef}>
        <OrganizationDetailsSection org={orgData} />

        <div className="divRight my-20">
          <strong>{toReadableDate(saleData?.created_at)}</strong>
        </div>

        <div className="mt-60">
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
          />
        </div>

        <div>
          <div className="divSpread my-10">
            <h2>Total</h2>
            <h2>{toAmountDisplay(saleData?.total)}</h2>
          </div>

          <div className="divSpread my-10">
            <strong>Items Total</strong>
            <strong>{toAmountDisplay(saleData?.amount)}</strong>
          </div>
          <div className="divSpread my-10">
            <strong>Discount</strong>
            <strong>{toAmountDisplay(saleData?.discountTotal)}</strong>
          </div>
          <div className="divSpread my-10">
            <strong>Tax</strong>
            <strong>{saleData?.tax}%</strong>
          </div>

          <div className="divSpread my-10">
            <strong>Payment</strong>
            <strong>
              {saleData?.payments?.[0]?.paymentType.toUpperCase()}
            </strong>
          </div>

          <div className="divSpread my-10">
            <strong>Customer Phone</strong>
            <strong>{saleData?.payments?.[0]?.customerPhone}</strong>
          </div>
        </div>

        <div className="divCenterAlign my-60">
          <Barcode value={saleData?.externalId ?? ""} />
        </div>
      </div>
    </Modal>
  );
}

function OrganizationDetailsSection({ org }: { org?: IOrganization }) {
  return (
    <div className="divCenterAlign divColumn">
      <h2>{capitalize(org?.name)}</h2>
      <div className="divCenterAlign divColumn">
        <span>{capitalize(org?.street1)}</span>
        <span>{capitalize(org?.street2)}</span>
        <span>{capitalize(org?.city)}</span>
        <Flex gap={2} className="">
          <span>{capitalize(org?.state)},</span>
          <span>{org?.country},</span>
          <span>{org?.zipCode}</span>
        </Flex>
      </div>
    </div>
  );
}
