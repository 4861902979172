import {
  BankOutlined,
  ClusterOutlined,
  DollarCircleOutlined,
  FileDoneOutlined,
  MenuOutlined,
  PhoneOutlined,
  StarOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { ItemType, MenuItemType } from "antd/es/menu/interface";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useGetProfileOnOrganization } from "../../api/api.get";

export function OrgHomeTopPaneComponent() {
  const { id: orgId } = useParams();
  const { data: profile } = useGetProfileOnOrganization(orgId);

  const items: any = useMemo(() => {
    let children: ItemType<MenuItemType>[] = [];

    if (orgId) {
      if (profile?.type === "admin") {
        children = getBusinessOwnerItems(orgId);
      } else if (profile?.type === "employee") {
        if (profile?.settings?.orders) {
          children.push({
            key: "2.1",
            icon: (
              <Link to={`/organizations/${orgId}/sales`}>
                <img src={"/assets/icons/profits.svg"} width={20} alt="sales" />
              </Link>
            ),
            label: "Orders",
          });
        }
        if (profile?.settings?.reimbursements) {
          children.push({
            key: "2",
            icon: (
              <Link to={`/organizations/${orgId}/reimbursements`}>
                <img
                  src={"/assets/icons/scan/receipt.png"}
                  width={20}
                  alt="reimbursements"
                />
              </Link>
            ),
            label: "Reimbursements",
          });
        }

        children.push({
          key: "3",
          icon: (
            <Link to={`/organizations/${orgId}/payroll`}>
              <img src={"/assets/icons/money.png"} width={20} alt="payroll" />
            </Link>
          ),
          label: "Payroll",
        });
      } else {
        children = [];
      }

      return [
        {
          key: "0",
          icon: (
            <div className="divRight w-100">
              <MenuOutlined style={{ cursor: "pointer" }} />
            </div>
          ),
          children,
        },
      ];
    }
  }, [profile, orgId]);

  return (
    <Menu
      id="org-options-menu"
      style={{ width: 60, backgroundColor: "white", border: "1px solid white" }}
      mode="horizontal"
      items={items}
    />
  );
}

const getBusinessOwnerItems = (orgId?: string) => {
  return [
    {
      key: "2",
      icon: (
        <Link to={`/organizations/${orgId}/sales`}>
          <img src={"/assets/icons/profits.svg"} width={20} alt="sales" />
        </Link>
      ),
      label: "Orders",
    },
    {
      key: "2.1",
      icon: (
        <Link to={`/organizations/${orgId}/products`}>
          <img src={"/assets/icons/shopping.svg"} width={20} alt="products" />
        </Link>
      ),
      label: "Products",
    },
    {
      key: "2.2",
      icon: (
        <Link to={`/organizations/${orgId}/invoices`} state={{ animate: true }}>
          <img src={"/assets/icons/invoice.svg"} width={20} alt="invoices" />
        </Link>
      ),
      label: "Invoices",
    },
    {
      key: "3",
      icon: (
        <Link to={`/organizations/${orgId}/payroll-cycles`}>
          <img src={"/assets/icons/profits.svg"} width={20} alt="payroll" />
        </Link>
      ),
      label: "Payroll",
    },
    {
      key: "4",
      icon: (
        <Link to={`/organizations/${orgId}/expenses`}>
          <img src={"/assets/icons/money.png"} width={20} alt="expenses" />
        </Link>
      ),

      label: "Expenses",
    },
    {
      key: "5",
      icon: (
        <Link to={`/organizations/${orgId}/investments`}>
          <img src={"/assets/icons/charts.png"} width={20} alt="investments" />
        </Link>
      ),
      label: "Investments",
    },
    {
      key: "6",
      icon: (
        <Link to={`/organizations/${orgId}/employees`}>
          <img src={"/assets/icons/groups.svg"} width={20} alt="employees" />
        </Link>
      ),
      label: "Employees",
    },
    {
      key: "7",
      icon: (
        <Link to={`/organizations/${orgId}/rewards`}>
          <StarOutlined />
        </Link>
      ),
      label: "Rewards",
    },

    {
      key: "8",
      icon: (
        <Link to={`/organizations/${orgId}/reimbursements`}>
          <img
            src={"/assets/icons/scan/receipt.png"}
            width={20}
            alt="reimbursements"
          />
        </Link>
      ),
      label: "Reimbursements",
    },
    {
      key: "11",
      icon: (
        <img src={"/assets/icons/supply-chain.svg"} width={20} alt="settings" />
      ),
      label: "Supply Chain",
      children: [
        {
          key: "111",
          label: "Vendors",
          icon: (
            <Link to={`/organizations/${orgId}/vendors`}>
              <ClusterOutlined />
            </Link>
          ),
        },
        {
          key: "112",
          label: "Stock Requests",
          icon: (
            <Link to={`/organizations/${orgId}/stocks`}>
              <FileDoneOutlined />
            </Link>
          ),
        },
      ],
    },
    {
      key: "9",
      icon: (
        <img src={"/assets/icons/settings.svg"} width={20} alt="settings" />
      ),
      label: "Settings",
      children: [
        {
          key: "91",
          label: "Organization",
          icon: (
            <Link to={`/organizations/${orgId}/settings`}>
              <BankOutlined />
            </Link>
          ),
        },
        {
          key: "92",
          label: "Tax & Fees",
          icon: (
            <Link to={`/organizations/${orgId}/settings/taxes`}>
              <DollarCircleOutlined />
            </Link>
          ),
        },
        {
          key: "93",
          label: "Invoice",
          icon: (
            <Link to={`/organizations/${orgId}/settings/invoices`}>
              <UpOutlined />
            </Link>
          ),
        },
        {
          key: "94",
          label: "Vendors",
          icon: (
            <Link to={`/organizations/${orgId}/settings/vendors`}>
              <ClusterOutlined />
            </Link>
          ),
        },
      ],
    },
    {
      key: "10",
      icon: (
        <a
          href="https://help.expensehut.com/contactUs"
          target="_blank"
          rel="noreferrer"
        >
          <PhoneOutlined />
        </a>
      ),
      label: "Support",
    },
  ];
};
