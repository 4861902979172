import { IOrganizationRewardLevel } from "../types/types";
import { toFixed } from "./number.utils";

export function calculatePoints(
  itemTotal: number,
  coupons: IOrganizationRewardLevel[],
): number {
  return coupons.reduce((acc, coupon) => {
    if (coupon.type === "POINTS" && itemTotal >= coupon.minAmount) {
      if (coupon.monetaryType === "FLAT") {
        return acc + coupon.value;
      } else if (coupon.monetaryType === "PERCENT") {
        return acc + (coupon.value / 100) * itemTotal;
      }
    }
    return acc;
  }, 0);
}

export interface IApplyCouponResult {
  points: number;
  discount: number;
  coupon?: IOrganizationRewardLevel;
}

/**
 * Use the first available coupon and return the applied coupon
 * @param itemTotal
 * @param coupons
 * @returns
 */
export function applyCoupons(
  itemTotal: number,
  coupons: IOrganizationRewardLevel[],
): IApplyCouponResult {
  for (const coupon of coupons) {
    if (coupon.type === "POINTS" && itemTotal >= coupon.minAmount) {
      if (coupon.monetaryType === "FLAT") {
        return { points: coupon.value, coupon, discount: 0 };
      } else if (coupon.monetaryType === "PERCENT") {
        return {
          points: Math.round((coupon.value / 100) * itemTotal),
          coupon,
          discount: 0,
        };
      }
    }
    if (coupon.type === "CASH" && itemTotal >= coupon.minAmount) {
      if (coupon.monetaryType === "FLAT") {
        return { discount: coupon.value, coupon, points: 0 };
      } else if (coupon.monetaryType === "PERCENT") {
        return {
          discount: (coupon.value / 100) * itemTotal,
          coupon,
          points: 0,
        };
      }
    }
  }
  return { points: 0, discount: 0, coupon: undefined };
}

export function addSalesTax(amount: number | string, taxPercent: number) {
  const toFixedDecimalAmount = toFixed(amount);
  const taxTotal = (toFixedDecimalAmount * taxPercent) / 100;
  return toFixed(toFixedDecimalAmount + taxTotal);
}
