import { InfoCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

export function InfoIcon({ title }: { title?: string }) {
  return (
    <Tooltip title={title}>
      <InfoCircleFilled style={{ color: "var(--exhut-blue)" }} />
    </Tooltip>
  );
}
