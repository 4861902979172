import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Descriptions,
  Divider,
  Flex,
  Input,
  List,
  Menu,
  Modal,
  Row,
  Skeleton,
  Tag,
} from "antd";
import Meta from "antd/es/card/Meta";
import Select from "antd/es/select";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import * as Yup from "yup";
import { useCreateOrgSale } from "../../../../api/api";
import { useDeleteRestaurantTable } from "../../../../api/api.delete";
import {
  useGetOrgSetting,
  useGetRestaurantTableById,
  useSearchAllProducts,
  useSearchProducts,
} from "../../../../api/api.get";
import { useUpdateRestaurantTable } from "../../../../api/api.update";
import { useMessage } from "../../../../components/notifications/message";
import { useNotifications2 } from "../../../../components/notifications/notification";
import { useCurrency } from "../../../../hooks/useCurrency";
import { IProduct } from "../../../../types/types";
import { toFixedDisplay } from "../../../../utils/number.utils";
import { calculateProductTotal } from "../../../../utils/products.utils";
import ErrorPanel from "../../../../components/error.panel.component";

export default function RestaurantTabPage() {
  const { id, tid } = useParams();
  const [searchText, setSearchText] = useState<string>("");
  const navigate = useNavigate();
  const { data: orgSettingsData } = useGetOrgSetting(id);
  const { currency, toAmountDisplay, toAmount } = useCurrency();

  const [items, setItems] = useState<IProduct[]>([]);

  const { data: tableData } = useGetRestaurantTableById(id, tid);

  const [productsToCheckout, setProductsToCheckout] = useState<IProduct[]>([]);

  const [debouncedProductNameSearchText] = useDebounce(searchText, 300);
  const { notification, notifyError, notifySuccess } = useNotifications2();
  const { notification: messageNotifcation, messageSuccess } = useMessage();
  const [confirmCheckout, setConfirmCheckout] = useState<boolean>(false);
  const { mutateAsync: updateTableAsync, isPending: isUpdateTablePending } =
    useUpdateRestaurantTable(id, tid);

  const { mutateAsync: deleteTableAsync } = useDeleteRestaurantTable(id!!);
  const [needsSave, setNeedsSave] = useState<boolean>(false);

  const [searchParams] = useSearchParams();

  const isDebug = searchParams.get("debug") === "true";

  const { data: productsByNameData, isLoading } = useSearchProducts(
    id,
    debouncedProductNameSearchText,
  );

  const { data: allProducts, isLoading: allProductsLoading } =
    useSearchAllProducts(id);

  useEffect(() => {
    if (tableData?.products) {
      setProductsToCheckout(tableData.products);
    }
  }, [tableData]);

  useEffect(() => {
    if (debouncedProductNameSearchText && productsByNameData) {
      setItems(productsByNameData);
    } else {
      setItems(allProducts ?? []);
    }
  }, [debouncedProductNameSearchText, allProducts, productsByNameData]);

  const handleRemoveItems = (index: number) => {
    const _items = [...productsToCheckout];
    _items.splice(index, 1);
    setProductsToCheckout(_items);
  };

  const itemTotal: number = productsToCheckout.reduce(
    (acc, item) => acc + (item.price?.value ?? 0),
    0,
  );

  const handleAddItems = (item: IProduct) => {
    const existingProductsToCheckout = [...productsToCheckout];
    existingProductsToCheckout.push(item);
    setProductsToCheckout(existingProductsToCheckout);
  };

  useEffect(() => {
    formik.setFieldValue("items", productsToCheckout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsToCheckout]);

  const handleCheckoutSuccess = () => {
    setConfirmCheckout(false);
    deleteTableAsync({ tableId: tid })
      .then(() => {
        notifySuccess({
          title: "Success",
          description: "Order successfully processed!",
        });
        setConfirmCheckout(false);
        setProductsToCheckout([]);

        navigate(`/organizations/${id}/sales/restaurants`);
      })
      .catch(() => {
        notifyError("Whoops! Unable to clear table.");
      });
  };

  const handleSaveTable = () => {
    updateTableAsync({
      products: productsToCheckout,
      tip: +formik.values.tip,
      tax: +formik.values.tax,
    })
      .then(() => {
        setNeedsSave(false);
        messageSuccess("Table Saved");
      })
      .catch(() => notifyError());
  };

  const formik = useFormik({
    initialValues: {
      tax: toFixedDisplay(tableData?.tax ?? orgSettingsData?.salesTax),
      items: tableData?.products,
      tip: toFixedDisplay(tableData?.tip) ?? 0,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      tax: Yup.string()
        .matches(
          /^(0|[1-9]\d*)(\.\d{2})?$/,
          "Please enter valid amount upto 2 decimals.",
        )
        .required(),
      tip: Yup.string().matches(
        /^(0|[1-9]\d*)(\.\d{2})?$/,
        "Please enter valid amount upto 2 decimals.",
      ),
      items: Yup.array().min(1),
    }),
    onSubmit: () => {
      setConfirmCheckout(true);
    },
  });

  const grandTotal = useMemo(() => {
    const total = calculateProductTotal(
      productsToCheckout,
      formik.values.tax,
      formik.values.tip,
    );
    return total;
  }, [productsToCheckout, formik.values.tax, formik.values.tip]);

  useEffect(() => {
    setNeedsSave(true);
  }, [productsToCheckout, formik.values.tax, formik.values.tip]);

  return (
    <div className=" divFlex p-20" style={{ height: "100%" }}>
      {notification}
      {messageNotifcation}

      <ConfirmCheckout
        items={productsToCheckout}
        tax={formik.values.tax ? +formik.values.tax : 0}
        grossTotal={grandTotal}
        total={grandTotal}
        open={confirmCheckout}
        onOK={() => handleCheckoutSuccess()}
        onCancel={() => setConfirmCheckout(false)}
        tip={+formik.values.tip}
      />
      <div className="flex-1 p-0 divSpread divColumn">
        <div className="mx-20">
          <div className="divSpread mb-20 mx-0">
            <Button
              type="link"
              disabled={needsSave}
              onClick={() => navigate(`/organizations/${id}/sales/restaurants`)}
            >
              {" "}
              All Tables
            </Button>
            <Input
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              allowClear
              style={{ width: "50%" }}
            />
          </div>

          <div style={{ overflowY: "scroll", height: 600 }}>
            <Row gutter={16}>
              <Skeleton loading={isLoading || allProductsLoading}>
                {items.map((item: IProduct, index: number) => {
                  return (
                    <Card
                      key={index}
                      onClick={() => handleAddItems(item)}
                      hoverable
                      style={{
                        width: 200,
                        marginLeft: 10,
                        marginRight: 10,
                        padding: 10,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                      cover={<img alt="example" src={item.cover} />}
                    >
                      <Meta title={item.title} />
                    </Card>
                  );
                })}
              </Skeleton>
            </Row>
          </div>
        </div>

        <div className="p-20 divColumn"></div>
      </div>

      <div className="flex-1 divColumn divSpread">
        <div>
          <div className="mb-20 divSpread">
            <Button
              type="primary"
              onClick={handleSaveTable}
              loading={isUpdateTablePending || isLoading || allProductsLoading}
              disabled={Object.keys(formik.errors).length > 0}
            >
              Save
            </Button>

            <Flex gap={5}>
              <div className="divAlignItemsOnly">
                <Tag color="green">Table {tableData?.value}</Tag>
              </div>
            </Flex>

            <Button
              type="primary"
              onClick={() => setConfirmCheckout(true)}
              disabled={Object.keys(formik.errors).length > 0 || needsSave}
            >
              Checkout
            </Button>
          </div>

          <Flex gap={5} className="divColumn">
            <List
              itemLayout="horizontal"
              bordered
              dataSource={productsToCheckout}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src={item.cover} />}
                    title={<span>{item.title}</span>}
                    description={currency + "" + item.price?.value}
                  />

                  <Flex gap={20}>
                    <DeleteOutlined
                      style={{ color: "red" }}
                      onClick={() => handleRemoveItems(index)}
                    />
                  </Flex>
                </List.Item>
              )}
            />
          </Flex>
        </div>

        <Divider />

        <div className="divSpread">
          <div className="align-items">
            <Flex gap={10} className="align-items">
              Tip:{" "}
              <Input
                style={{ width: 100 }}
                status={!!formik.errors.tip ? "error" : undefined}
                {...formik.getFieldProps("tip")}
              />
            </Flex>
          </div>

          <div className="divColumn">
            <span className="my-20" style={{ fontSize: 20 }}>
              Total : <strong>{toAmount(itemTotal)}</strong>
            </span>

            <span className="my-20" style={{ fontSize: 20 }}>
              Tax:{" "}
              <Input
                style={{ width: 100 }}
                status={!!formik.errors.tax ? "error" : undefined}
                {...formik.getFieldProps("tax")}
              />
              {isDebug && (
                <span>
                  [{((orgSettingsData?.salesTax ?? 0) / 100) * itemTotal}]
                </span>
              )}
            </span>
            <span style={{ fontSize: 65, fontWeight: "bold" }}>
              {toAmountDisplay(grandTotal)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function ConfirmCheckout({
  open,
  total,
  items,
  grossTotal,
  onCancel,
  tax,
  onOK,
  tip,
}: {
  tip?: number;
  tax: number;
  grossTotal: number;
  items: IProduct[];
  total: number;
  open: boolean;
  onOK: () => void;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { mutateAsync, isPending } = useCreateOrgSale(id);
  const [errorMsg, setErrorMsg] = useState<string>();
  const { toAmountDisplay, areaCode } = useCurrency();

  const formik = useFormik({
    initialValues: {
      phone: "",
      paymentType: undefined,
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid phone number")
        .optional(),
      paymentType: Yup.string().optional(),
    }),
    onSubmit: async (values) => {
      setErrorMsg(undefined);
      if (values.phone && !values.paymentType) {
        formik.setFieldError(
          "paymentType",
          "Payment type is required if phone number is used",
        );
        return;
      }
      if (!values.phone && values.paymentType) {
        formik.setFieldError(
          "phone",
          "Phone is required if payment type is selected",
        );
        return;
      }

      mutateAsync({
        amount: total,
        saleDate: moment().format("YYYY-MM-DD"),
        category: items?.[0].title ?? "Scan Order - unknown",
        products: items.map((item) => item._id),
        tax: tax ?? 0,
        phone: values.phone ? `${areaCode}${values.phone}` : undefined,
        paymentType: values.paymentType,
        type: "RESTAURANT",
        total: grossTotal,
        tip,
      })
        .then(onOK)
        .catch((e) => setErrorMsg("Whoops! Unable to create order."));
    },
  });

  return (
    <div>
      <Modal
        title="Confirm Order?"
        open={open}
        onOk={() => formik.submitForm()}
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
        okButtonProps={{
          disabled: isPending || Object.keys(formik.errors).length > 0,
          loading: isPending,
        }}
      >
        <div className="divColumn">
          <Flex className="divFlex divAlignItemsOnly my-20" gap={10}>
            <strong>Total</strong>
            <h1>{toAmountDisplay(total)}</h1>
          </Flex>

          <div className="divRight p-10">
            {false && (
              <Menu
                mode="horizontal"
                id="options-menu"
                onClick={() => {}}
                style={{ backgroundColor: "inherit", width: 60 }}
                items={[
                  {
                    key: "0",
                    icon: <PlusOutlined />,
                    children: [
                      { label: "Surcharge", key: "SURCHARGE" },
                      {
                        label: "Card Processing Fees",
                        key: "CARD_PROCESSING_FEES",
                      },
                      { label: "Delivery Charges", key: "DELIVERY_CHARGES" },
                      { label: "Other", key: "OTHER" },
                    ],
                  },
                ]}
              />
            )}
          </div>

          <form>
            <Flex vertical gap={10} className="">
              <Descriptions
                bordered
                layout="vertical"
                items={[
                  {
                    label: "Tax",
                    children: <strong>{toFixedDisplay(tax)}%</strong>,
                  },
                  {
                    label: "Tip",
                    children: <strong>{toFixedDisplay(tip)}</strong>,
                  },
                ]}
              />

              <Flex vertical className="my-20" gap={10}>
                <span>Select Payment Type</span>
                <Select
                  id="first-time-select"
                  value={formik.values.paymentType}
                  onChange={(value) =>
                    formik.setFieldValue("paymentType", value)
                  }
                  options={[
                    { value: "", label: " " },
                    { value: "cash", label: "Cash" },
                    {
                      value: "card",
                      label: "Credit Card",
                    },
                  ]}
                />
                <ErrorPanel message={formik.errors.paymentType} />
              </Flex>

              <Flex vertical gap={10}>
                <span>Customer Phone</span>
                <Input prefix={areaCode} {...formik.getFieldProps("phone")} />
                <ErrorPanel message={formik.errors.phone} />
              </Flex>

              <ErrorPanel message={errorMsg} />
            </Flex>
          </form>
        </div>
      </Modal>
    </div>
  );
}
