import { CloseCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Empty,
  Flex,
  Input,
  Modal,
  Popconfirm,
  Row,
  Skeleton,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAddRestaurantTable } from "../../../../api/api";
import { useDeleteRestaurantTable } from "../../../../api/api.delete";
import { useGetRestaurantTables } from "../../../../api/api.get";
import { useMessage } from "../../../../components/notifications/message";
import { useCurrency } from "../../../../hooks/useCurrency";
import { IProduct, IRestaurantTable } from "../../../../types/types";
import { useProfile } from "../../../../hooks/useProfile";

export default function RestaurantsHomePage() {
  const { id: orgId } = useParams();
  const { profileType } = useProfile();
  const { notification, messageSuccess, messageError } = useMessage();
  const { data: tables, isLoading } = useGetRestaurantTables(orgId);

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const { mutateAsync } = useDeleteRestaurantTable(orgId!!);

  const handleClearTable = (id: string) => {
    mutateAsync({ tableId: id })
      .then(() => {
        messageSuccess("Table cleared");
      })
      .catch(() => {
        messageError("Cannot delete table. Please try again.");
      });
  };

  return (
    <div
      style={{ marginBottom: 100, minHeight: 600 }}
      className="p-40 divColumn flex-1"
    >
      {notification}

      <AddTableModal
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
      />

      <div className="divSpread">
        <h2>Tables</h2>
        <Button type="link" onClick={() => setShowAddModal(true)}>
          Add Table
        </Button>
      </div>

      <div>
        {tables?.length === 0 && (
          <div className="mt-80   divCenterAlign divColumn">
            <h1 className="greeting">Let's start tabs</h1>
            <Empty image={"/assets/icons/eatouts.svg"} description="" />
          </div>
        )}

        <Skeleton loading={isLoading}>
          <Row gutter={16}>
            {tables?.map((table: IRestaurantTable, index: number) => {
              const total = table.products?.reduce(
                (acc: number, product: IProduct) => {
                  return acc + (product.price?.value ?? 0);
                },
                0,
              );
              return (
                <Card
                  id={index.toString()}
                  key={index}
                  title={total ?? 0}
                  time={moment.utc(table.created_at).local().format("hh:mm A")}
                  link={`tabs/${table.id}`}
                  value={table.value}
                  onClick={
                    profileType === "admin"
                      ? () => handleClearTable(table.id)
                      : undefined
                  }
                />
              );
            })}
          </Row>
        </Skeleton>
      </div>
    </div>
  );
}

function AddTableModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const [value, setValue] = useState<string>();
  const { id: orgId } = useParams();
  const { messageError, notification } = useMessage();

  const { mutateAsync, isPending } = useAddRestaurantTable(orgId);

  const handleAddTable = () => {
    mutateAsync({ value })
      .then(() => {
        setValue("");
        onCancel();
      })
      .catch((err) =>
        messageError(
          err.response?.data?.message ?? "Whoops! Unable to create.",
        ),
      );
  };

  return (
    <div>
      {notification}
      <Modal
        title="Add Table"
        open={open}
        onOk={handleAddTable}
        onCancel={onCancel}
        okButtonProps={{ loading: isPending }}
      >
        <div className="divColumn">
          <Flex vertical gap={10} className="my-40">
            <Flex vertical gap={10}>
              <span>Table Number</span>
              <Input value={value} onChange={(e) => setValue(e.target.value)} />
            </Flex>
          </Flex>
        </div>
      </Modal>
    </div>
  );
}

interface IProps {
  value?: string;
  id: string;
  title: number;
  link: string;
  time: string;
  onClick?: () => void;
}

function Card({ value, id, title, link, time, onClick }: IProps) {
  const { toAmount } = useCurrency();

  return (
    <div className="card">
      <div className="divRight">
        {!!onClick && (
          <Popconfirm title="Are you sure?" onConfirm={onClick}>
            <CloseCircleOutlined style={{ cursor: "pointer" }} />
          </Popconfirm>
        )}
      </div>
      <span className="infoMsg divCenterAlign">{toAmount(title)}</span>
      <div className="mt-20 divCenterAlign">
        <span style={{ fontSize: "xx-large" }} id={id}>
          {value}
        </span>
      </div>
      <div className="my-t0 divCenterAlign divColumn">
        <div className="my-20">
          <span>{time}</span>
        </div>
        <Link to={link}>View</Link>
      </div>
    </div>
  );
}
