import { Button, Flex, Input, Modal, Select, Steps } from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateRewardLevel } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { useCurrency } from "../../../hooks/useCurrency";

export enum REWARD_TYPE {
  CASH = "CASH",
  POINTS = "POINTS",
}

export enum REWARD_MONETARY_TYPE {
  FLAT = "FLAT",
  PERCENT = "PERCENT",
}

export default function AddRewardLevelModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { currency } = useCurrency();
  const { id } = useParams();

  const { mutateAsync, isPending } = useCreateRewardLevel(id);
  const { notification, notifySuccess, notifyError } = useNotifications();

  const formik = useFormik({
    initialValues: {
      name: "",
      type: REWARD_TYPE.POINTS,
      monetaryType: REWARD_MONETARY_TYPE.PERCENT,
      value: "",
      minAmount: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please give a name"),
      type: Yup.string().required("Please select type"),
      monetaryType: Yup.string().required("Please select type"),
      value: Yup.string()
        .matches(
          /^(0|[1-9]\d*)(\.\d+)?$/,
          "Please enter numbers only. Do not include % or currency symbols",
        )
        .required("Please enter value"),
      minAmount: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter numbers only.")
        .required("Please enter a value"),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        value: +values.value,
        minAmount: +values.minAmount,
      })
        .then(() => {
          notifySuccess();
          formik.resetForm();
          setStep(0);
          onCancel();
        })
        .catch((err) => {
          console.error(err);
          notifyError();
        });
    },
  });

  const [step, setStep] = useState<number>(0);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add a Coupon"
        open={open}
        onOk={formik.submitForm}
        onCancel={onCancel}
        okButtonProps={{ loading: isPending }}
      >
        {notification}

        <Steps
          direction="vertical"
          current={step}
          items={[
            {
              title: <strong>Choose type</strong>,
              description: (
                <div>
                  {step >= 0 && (
                    <div>
                      <h4>What type of coupon is this?</h4>
                      <Select
                        id="select-reward-type"
                        disabled={step !== 0}
                        defaultValue={REWARD_TYPE.POINTS}
                        style={{ width: 170 }}
                        onChange={(value) =>
                          formik.setFieldValue("type", value)
                        }
                        options={[
                          { value: REWARD_TYPE.POINTS, label: "Reward Points" },
                          { value: REWARD_TYPE.CASH, label: "Cash Discount" },
                        ]}
                      />
                      <ErrorPanel message={formik.errors.type} />
                    </div>
                  )}

                  {step === 0 && (
                    <div className="divSpread">
                      <Button
                        type="link"
                        disabled={step >= 2}
                        onClick={() => setStep(step + 1)}
                      >
                        Next
                      </Button>
                    </div>
                  )}
                </div>
              ),
            },
            {
              title: <strong>Percent or Flat</strong>,
              description: (
                <div>
                  {step >= 1 && (
                    <Flex vertical gap={20}>
                      <div>
                        <h4>
                          Do you wish to give flat{" "}
                          {formik.values.type === REWARD_TYPE.POINTS
                            ? "number of points"
                            : "cash discount"}{" "}
                          or % off the bill?
                        </h4>
                        <Select
                          id="select-reward-monetarytype"
                          disabled={step !== 1}
                          defaultValue={REWARD_MONETARY_TYPE.PERCENT}
                          style={{ width: 200 }}
                          onChange={(value) =>
                            formik.setFieldValue("monetaryType", value)
                          }
                          options={[
                            { value: REWARD_MONETARY_TYPE.FLAT, label: "Flat" },
                            {
                              value: REWARD_MONETARY_TYPE.PERCENT,
                              label: "Percentage",
                            },
                          ]}
                        />
                        <ErrorPanel message={formik.errors.type} />
                      </div>

                      <div>
                        <strong>
                          {formik.values.monetaryType ===
                          REWARD_MONETARY_TYPE.PERCENT
                            ? "Enter percentage:"
                            : formik.values.type === REWARD_TYPE.CASH
                              ? "Enter Discount Amount"
                              : "Enter Points"}
                        </strong>
                        <Input
                          disabled={step !== 1}
                          maxLength={6}
                          prefix={
                            formik.values.monetaryType ===
                            REWARD_MONETARY_TYPE.PERCENT
                              ? "%"
                              : formik.values.type === REWARD_TYPE.CASH
                                ? currency
                                : ""
                          }
                          {...formik.getFieldProps("value")}
                        />
                        <ErrorPanel message={formik.errors.value} />
                      </div>
                    </Flex>
                  )}
                  {step === 1 && (
                    <div className="divSpread">
                      <Button
                        type="link"
                        disabled={step <= 0}
                        onClick={() => setStep(step - 1)}
                      >
                        Previous
                      </Button>
                      <Button
                        type="link"
                        disabled={step >= 2}
                        onClick={() => setStep(step + 1)}
                      >
                        Next
                      </Button>
                    </div>
                  )}
                </div>
              ),
            },
            {
              title: <strong>Minimum Bill Amount?</strong>,
              description: (
                <div className="my-20">
                  <strong>Would you like to set a minimum bill amount </strong>

                  <Input
                    disabled={step !== 2}
                    prefix={currency}
                    {...formik.getFieldProps("minAmount")}
                  />
                  <ErrorPanel message={formik.errors.minAmount} />
                  {step === 2 && (
                    <div className="divSpread">
                      <Button type="link" onClick={() => setStep(step - 1)}>
                        Previous
                      </Button>
                      <Button type="link" onClick={() => setStep(step + 1)}>
                        Next
                      </Button>
                    </div>
                  )}
                </div>
              ),
            },

            {
              title: "Confirm",
              description: (
                <div>
                  <h4>Please enter a name</h4>
                  <Input
                    placeholder="Buy one get one..."
                    {...formik.getFieldProps("name")}
                  />
                  <ErrorPanel message={formik.errors.name} />
                  {step === 3 && (
                    <div className="divSpread">
                      <Button type="link" onClick={() => setStep(step - 1)}>
                        Previous
                      </Button>
                    </div>
                  )}
                </div>
              ),
            },
          ]}
        />
      </Modal>
    </form>
  );
}
