import { Button, Divider, Flex, Modal, Select, Table, Tag } from "antd";
import { useRef, useState } from "react";
import generatePDF from "react-to-pdf";

import { SendOutlined } from "@ant-design/icons";
import Alert from "antd/es/alert/Alert";
import { Buffer } from "buffer";
import { useParams } from "react-router-dom";
import { useSendInvoiceReceipt } from "../../../api/api";
import { useGetOrganizationById } from "../../../api/api.get";
import { useMessage } from "../../../components/notifications/message";
import { useCurrency } from "../../../hooks/useCurrency";
import { IInvoice, IProductQuanityRowItem } from "../../../types/types";
import { capitalize, getProductCurrencySymbol } from "../../../utils/functions";
import { toReadableDate } from "../../../utils/date.utils";

export default function ViewReceiptModal({
  open,
  onCancel,
  invoice,
  paymentsTotal,
  netTotal,
}: {
  netTotal?: number;
  paymentsTotal?: number;
  invoice?: IInvoice;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { data: orgData } = useGetOrganizationById(id);
  const { toAmount } = useCurrency();

  const { mutateAsync, isPending } = useSendInvoiceReceipt(id, invoice?.id);

  const { messageError, messageSuccess, notification } = useMessage();

  const contentRef = useRef(null);

  const handleEmail = async (mode: "SMS" | "EMAIL") => {
    const fileName = `receipt_${invoice?.externalId}.pdf`;
    const pdf = await generatePDF(contentRef, {
      filename: fileName,
      method: "build",
    });
    const blob = pdf.output("arraybuffer");
    const base64 = Buffer.from(blob).toString("base64");

    mutateAsync({
      fileName,
      pdfContent: base64,
      customMsg: "Here is your invoice.",
      mode,
    })
      .then(() => {
        messageSuccess("Receipt sent successfully.");
      })
      .catch(() => {
        messageError("Whoops! Something went wrong. ");
      });
  };

  const columns = [
    {
      title: "Item",
      key: "title",
      render: (row: IProductQuanityRowItem) => (
        <span>{capitalize(row.product?.title)}</span>
      ),
    },
    {
      title: "Quantity",
      key: "quantity",
      render: (row: IProductQuanityRowItem) => <span>{row.quantity}</span>,
    },
    {
      title: "Price",
      render: (row: IProductQuanityRowItem) => (
        <span>
          {getProductCurrencySymbol(row.product?.price?.currency)}
          {row.product?.price?.value}
        </span>
      ),
    },
  ];

  return (
    <Modal open={open} onOk={onCancel} onCancel={onCancel} footer={null}>
      {notification}

      <ActionsSection
        invoice={invoice}
        onSend={(mode) => handleEmail(mode)}
        isPending={isPending}
      />

      <Divider />

      <Flex gap={10} vertical style={{ padding: "40px 40px" }} ref={contentRef}>
        <div>
          <div className="divSpread divAlignItemsOnly">
            <h2>{"RECEIPT"}</h2>
            <div className="small-font">#{invoice?.externalId}</div>
          </div>

          <div className="my-10 divFlex">
            <Alert type={"success"} message={"PAID"} />
          </div>

          <div className="my-10">
            <div className="divColumn">
              <strong>{capitalize(orgData?.name)}</strong>
              <span>{orgData?.street1}</span>
              <span>{orgData?.street2}</span>
              <span>{orgData?.city}</span>
              <span>
                {orgData?.state} {orgData?.country} {orgData?.zipCode}
              </span>
            </div>
          </div>
        </div>

        <hr style={{ border: "1 solid black" }} />

        <div>
          <h4> BILL TO </h4>
          <div>
            <div className="my-10">
              <div className="divColumn">
                {(invoice?.customer?.firstName ||
                  invoice?.customer?.lastName) && (
                  <strong>
                    {capitalize(invoice?.customer?.firstName)}{" "}
                    {capitalize(invoice?.customer?.lastName)}
                  </strong>
                )}

                <span>{capitalize(invoice?.customer?.address?.street1)}</span>
                <span>{capitalize(invoice?.customer?.address?.street2)}</span>
                <span>{capitalize(invoice?.customer?.address?.city)}</span>
                <span>
                  {invoice?.customer?.address?.state && (
                    <span>
                      {capitalize(
                        invoice?.customer?.address?.state?.toUpperCase(),
                      )}
                      , {capitalize(invoice?.customer?.address?.countryCode)}
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="my-5">
            <div>
              <strong>Email</strong>
              <span className="mx-10">{invoice?.customer?.email}</span>
            </div>

            <div>
              <strong>Phone</strong>
              <span className="mx-10">{invoice?.customer?.phone}</span>
            </div>
          </div>
        </div>
        <hr style={{ border: "1 solid black" }} />

        <div className="my-0">
          <Table
            bordered
            dataSource={invoice?.items}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
          />
        </div>

        <Flex gap={20} vertical className="divRight my-20">
          <div className="divRight">
            <strong>Net Total: </strong>
            <span className="mx-10">{toAmount(netTotal)}</span>
          </div>
          <div className="divRight">
            <strong>Tax: </strong>
            <span className="mx-10">{invoice?.tax}%</span>
          </div>

          <div className="divRight divAlignItemsOnly">
            <strong>Total: </strong>
            <h2 className="mx-10">{toAmount(invoice?.total)}</h2>
          </div>
        </Flex>
        <hr style={{ border: "1 solid black" }} />

        <div className="divSpread">
          <span>{toReadableDate(invoice?.payments?.[0]?.created_at)}</span>
          <strong>
            Paid: <strong className="mx-10">{toAmount(paymentsTotal)}</strong>
          </strong>
        </div>
      </Flex>
    </Modal>
  );
}

function ActionsSection({
  invoice,
  onSend,
  isPending,
}: {
  invoice?: IInvoice;
  isPending: boolean;
  onSend: (mode: "SMS" | "EMAIL") => void;
}) {
  const [sendMode, setSendMode] = useState<"SMS" | "EMAIL">("EMAIL");

  return (
    <div className="divRight p-20">
      <div className="divFlex divAlignItemsOnly">
        {sendMode === "EMAIL" && !invoice?.customer?.email && (
          <Tag color="error">Customer Email missing</Tag>
        )}

        <span className="infoMsg mx-10">Send via</span>

        <div className="mx-10">
          <Select
            value={sendMode}
            onChange={(value) => setSendMode(value)}
            options={[
              { value: "EMAIL", label: "Email" },
              { value: "SMS", label: "SMS" },
            ]}
          />
        </div>

        <Button
          icon={<SendOutlined />}
          type="text"
          onClick={() => onSend(sendMode)}
          loading={isPending}
          disabled={sendMode === "EMAIL" && !invoice?.customer?.email}
        ></Button>
      </div>
    </div>
  );
}
