import { DeleteOutlined } from "@ant-design/icons";
import { Avatar, Flex, Input, List, Modal, Select } from "antd";
import Alert from "antd/es/alert/Alert";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import * as Yup from "yup";
import {
  useGetOrgSetting,
  useSearchRewardLevelByExternalIdLast4,
} from "../../../../api/api.get";
import ErrorPanel from "../../../../components/error.panel.component";
import { useCurrency } from "../../../../hooks/useCurrency";
import { IProduct } from "../../../../types/types";
import { applyCoupons } from "../../../../utils/orders.utils";
import moment from "moment";
import { useMessage } from "../../../../components/notifications/message";
import { useCreateOrgSale } from "../../../../api/api";
import { calculateProductTotal } from "../../../../utils/products.utils";
import { toFixed } from "../../../../utils/number.utils";

export default function ConfirmCheckout({
  open,
  onCancel,
  onOK,
  itemTotal,
  items,
  onRemoveItems,
}: {
  itemTotal: number;
  items: IProduct[];
  open: boolean;
  onOK: () => void;
  onCancel: () => void;
  onRemoveItems: (item: IProduct) => void;
}) {
  const { id } = useParams();
  const { areaCode, toAmountDisplay } = useCurrency();
  const { data: orgData } = useGetOrgSetting(id);
  const { mutateAsync, isPending } = useCreateOrgSale(id);

  const [searchCouponText, setCouponSearchText] = useState<string>("");
  const [debouncedSearchCouponText] = useDebounce(searchCouponText, 500);
  const { messageError, notification } = useMessage();

  const {
    data: couponData,
    error,
    refetch,
  } = useSearchRewardLevelByExternalIdLast4(
    id,
    debouncedSearchCouponText,
    false,
  );

  const { points: pointsTotal, coupon: appliedCoupon } = applyCoupons(
    itemTotal,
    couponData ? [couponData] : [],
  );

  const formik = useFormik({
    initialValues: {
      tax: orgData?.salesTax,
      paymentType: "",
      customerPhone: "",
      notes: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      tax: Yup.string().matches(
        /^(0|[1-9]\d*)(\.\d{2})?$/,
        "Please enter valid amount upto 2 decimals.",
      ),
      paymentType: Yup.string(),
      customerPhone: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid phone number")
        .notRequired(),
    }),
    onSubmit: (values) => {
      if (values.customerPhone.trim().length > 0 && !values.paymentType) {
        formik.setFieldError("paymentType", "Please select payment type");
        return;
      }
      if (values.customerPhone.trim().length === 0 && !!values.paymentType) {
        formik.setFieldError(
          "customerPhone",
          "Phone number is required if payment type is used.",
        );
        return;
      }
      if (
        searchCouponText.length > 0 &&
        (values.customerPhone.length === 0 || !values.paymentType)
      ) {
        formik.setFieldError(
          "customerPhone",
          "Phone number and payment type required for coupon.",
        );
        return;
      }

      if (searchCouponText.length > 0 && !appliedCoupon) {
        formik.setFieldError("customerPhone", "This coupon cannot be applied.");
        return;
      }
      setCouponSearchText("");

      mutateAsync({
        amount: toFixed(itemTotal),
        saleDate: moment().format("YYYY-MM-DD"),
        category: items?.[0].title ?? "Scan Order - unknown",
        products: items.map((item) => item._id),
        tax: values.tax ? toFixed(values.tax) : 0.0,
        phone: `${areaCode}${values.customerPhone}`,
        paymentType: values.paymentType,
        type: "FASTFOOD",
        total: toFixed(grandTotal),
        coupons: couponData ? [+couponData.id] : [],
        notes: values.notes,
        pointsTotal,
      })
        .then(() => {
          formik.resetForm();
          onOK();
        })
        .catch(() => messageError("Something went wrong! Please try again."));
    },
  });

  const grandTotal = useMemo(() => {
    return calculateProductTotal(items, formik.values.tax);
  }, [items, formik.values.tax]);

  const handleSearchCoupon = () => {
    if (searchCouponText.length > 0) {
      refetch();
    }
  };

  return (
    <Modal
      title="Checkout"
      open={open}
      onOk={() => formik.submitForm()}
      onCancel={onCancel}
      okButtonProps={{ disabled: items.length === 0, loading: isPending }}
    >
      {notification}

      <form onSubmit={formik.handleSubmit}>
        <div className="divColumn">
          <div className="my-20">
            <List
              dataSource={items}
              itemLayout="horizontal"
              bordered
              renderItem={(item, index) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    avatar={<Avatar src={item.cover} />}
                    title={<span>{item.title}</span>}
                    description={toAmountDisplay(item.price?.value)}
                  />

                  <Flex gap={20}>
                    <DeleteOutlined
                      style={{ color: "red" }}
                      onClick={() => onRemoveItems(item)}
                    />
                  </Flex>
                </List.Item>
              )}
            />
          </div>
          <Flex className="divSpread">
            <Flex vertical>
              <Flex gap={10} className="divAlignItemsOnly">
                <strong className="infoMsg">Total</strong>
                <h3>{toAmountDisplay(itemTotal)} </h3>
              </Flex>

              <Flex gap={10} className="divAlignItemsOnly">
                <strong className="infoMsg">Tax</strong>
                <Input {...formik.getFieldProps("tax")} style={{ width: 70 }} />
              </Flex>
              <ErrorPanel message={formik.errors.tax} />
            </Flex>

            <Flex gap={20} className="divAlignItemsOnly">
              <h1>{toAmountDisplay(grandTotal)} </h1>
            </Flex>
          </Flex>

          <Flex vertical gap={10} className="my-20">
            <Flex vertical gap={10} className="my-20">
              <span>Coupon Last 4</span>
              <Input.Search
                allowClear
                value={searchCouponText}
                onChange={(e) => setCouponSearchText(e.target.value)}
                onSearch={() => handleSearchCoupon()}
              />
              {couponData && (
                <Alert
                  type="success"
                  message={
                    <Flex vertical gap={5}>
                      <span>
                        {couponData.monetaryType} coupon: {couponData.value}{" "}
                        with min bill {couponData.minAmount}{" "}
                      </span>
                    </Flex>
                  }
                />
              )}
              {couponData && !!appliedCoupon && (
                <Alert
                  type="success"
                  message={<strong>Points: {pointsTotal}</strong>}
                />
              )}
              {couponData && !appliedCoupon && (
                <Alert type="error" message={"Unable to apply."} closable />
              )}
              {error && (
                <Alert type="error" message={"Coupon not found."} closable />
              )}
            </Flex>

            <Flex vertical gap={10}>
              <span>Select Payment Type</span>
              <Select
                id="first-time-select"
                value={formik.values.paymentType}
                onChange={(value) => formik.setFieldValue("paymentType", value)}
                options={[
                  { value: "", label: " " },
                  { value: "CASH", label: "Cash" },
                  {
                    value: "CARD",
                    label: "Credit Card",
                  },
                  { value: "OTHER", label: "Other" },
                  { value: "CHECK", label: "Check" },
                ]}
              />
              <ErrorPanel message={formik.errors.paymentType} />
            </Flex>

            <Flex vertical gap={10}>
              <span>Customer Phone</span>
              <Input
                prefix={areaCode}
                {...formik.getFieldProps("customerPhone")}
              />
              <ErrorPanel message={formik.errors.customerPhone} />
            </Flex>

            <Flex vertical gap={10}>
              <span>Notes</span>
              <Input.TextArea
                maxLength={200}
                {...formik.getFieldProps("notes")}
              />
            </Flex>
          </Flex>
        </div>
      </form>
    </Modal>
  );
}
