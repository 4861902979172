import { Empty } from "antd";

export default function EmptyComponent({
  title = "No data available",
}: {
  title?: string;
}) {
  return (
    <div className="mt-40 mb-40">
      <Empty description={title} image={"/assets/icons/empty.svg"} />
    </div>
  );
}
