import {
  BookOutlined,
  MailFilled,
  PrinterFilled,
  SaveFilled,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  Popconfirm,
  Row,
  Tooltip,
} from "antd";
import Alert from "antd/es/alert/Alert";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { ICreateInvoicePayload } from "../../../api/api";
import { useDeleteInvoice } from "../../../api/api.delete";
import {
  useGetInvoiceById,
  useGetOrganizationById,
  useGetOrgSetting,
} from "../../../api/api.get";
import { useUpdateUpdateInvoice } from "../../../api/api.update";
import ErrorPanel from "../../../components/error.panel.component";
import { InfoIcon } from "../../../components/icon.component";
import { useMessage } from "../../../components/notifications/message";
import { useNotifications2 } from "../../../components/notifications/notification";
import { ProductQuantityRowItem } from "../../../components/product-quantity.component";
import { useCurrency } from "../../../hooks/useCurrency";
import { IProduct, IProductQuanityRowItem } from "../../../types/types";
import { toReadableDate } from "../../../utils/date.utils";
import { displayPhoneNumber } from "../../../utils/functions";
import {
  calculateProductQuantityTotal,
  validateItems,
} from "../../../utils/products.utils";
import AddProductsModal from "../products/add-products-modal";
import InvoicePaymentsSection from "./invoice.payments.section";
import ViewInvoiceModal from "./view-invoice-modal";
import ViewReceiptModal from "./view-receipt-modal";

export default function EditInvoice() {
  const { id, iid } = useParams();
  const { data: orgData } = useGetOrganizationById(id);
  const { data: orgSettingsData } = useGetOrgSetting(id);

  const { areaCode, toAmount, toAmountDisplay } = useCurrency();
  const { notifySuccess, notification, notifyError } = useNotifications2();
  const { notification: messageNotifcation, messageError } = useMessage();
  const [isSearchInProgress, setSearchInProgress] = useState<boolean>();

  const [invoiceItems, setInvoiceItems] = useState<IProductQuanityRowItem[]>(
    [],
  );

  const { data: invoiceData, refetch: refetchInvoiceData } = useGetInvoiceById(
    id,
    iid,
  );
  const { mutateAsync: deleteAsync } = useDeleteInvoice(id!!);

  const [printMode, setPrintMode] = useState<
    "email" | "download" | "print" | null
  >();
  const [showAddProduct, setShowAddProduct] = useState<boolean>(false);

  const [showReceipt, setShowReceipt] = useState<boolean>(false);

  const { mutateAsync, isPending } = useUpdateUpdateInvoice(id, iid);

  const formik = useFormik({
    initialValues: {
      total: invoiceData?.total ?? 0,
      tax: invoiceData?.tax ?? "",
      customerFirstName: invoiceData?.customer?.firstName ?? "",
      customerLastName: invoiceData?.customer?.lastName ?? "",
      customerStreet1: invoiceData?.customer?.address?.street1 ?? "",
      customerStreet2: invoiceData?.customer?.address?.street2 ?? "",
      customerCity: invoiceData?.customer?.address?.city ?? "",
      customerState: invoiceData?.customer?.address?.state ?? "",
      customerCountryCode: invoiceData?.customer?.address?.countryCode ?? "",
      customerZipCode: invoiceData?.customer?.address?.zipCode ?? "",
      customerPhone:
        displayPhoneNumber(invoiceData?.customer?.phone, orgData?.country) ??
        "",
      customerEmail: invoiceData?.customer?.email ?? "",
      items: invoiceData?.items,
      notes: invoiceData?.notes ?? "",
      internalNotes: invoiceData?.internalNotes ?? "",
      includeFooter: invoiceData?.includeFooter,
      includePaymentLink: invoiceData?.includePaymentLink,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      total: Yup.number(),
      tax: Yup.number()
        .typeError("Please enter valid number")
        .min(1, "Please enter valid percentage")
        .max(100, "Please enter valid percentage")
        .required("Please enter tax percentage"),
      customerFirstName: Yup.string().required("Please enter customer name"),
      customerPhone: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid phone number")
        .required("Please enter phone number"),
      customerEmail: Yup.string().matches(
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
        "Please enter valid email address",
      ),
      customerZipCode: Yup.string().matches(
        /^(0|[1-9]\d*)(\.\d+)?$/,
        "Please enter valid zip code",
      ),
    }),
    onSubmit: async (values) => {
      const result = validateItems(invoiceItems);

      if (!result) {
        messageError("One or more products is incomplete.");
        return;
      }

      const payload: ICreateInvoicePayload = {
        items: invoiceItems,
        tax: +values.tax,
        total: values.total,
        notes: values.notes,
        includeFooter: values.includeFooter,
        internalNotes: values.internalNotes,
        includePaymentLink: values.includePaymentLink,
        customer: {
          firstName: values.customerFirstName,
          lastName: values.customerLastName,
          email: values.customerEmail,
          phone: `${areaCode}${values.customerPhone}`,
          address: {
            street1: values.customerStreet1,
            street2: values.customerStreet2,
            city: values.customerCity,
            state: values.customerState,
            countryCode: values.customerCountryCode,
            zipCode: values.customerZipCode,
          },
        },
      };

      mutateAsync(payload)
        .then(() => {
          formik.resetForm();
          notifySuccess({
            title: "Success",
            description: "Invoice successfully updated",
          });
        })
        .catch(() => {
          notifyError();
        });
    },
  });

  useEffect(() => {
    if (invoiceData) {
      setInvoiceItems(invoiceData.items);
    }
  }, [invoiceData]);

  const netTotal = useMemo(() => {
    return invoiceItems?.reduce(
      (acc, item) => acc + (item.product?.price?.value ?? 0) * item.quantity,
      0,
    );
  }, [invoiceItems]);

  useEffect(() => {
    let grossTotal = calculateProductQuantityTotal(
      invoiceItems,
      +formik.values.tax,
    );
    formik.setFieldValue("total", grossTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceItems, formik.values.tax]);

  const navigate = useNavigate();

  const paymentsTotal = useMemo(() => {
    return invoiceData?.payments?.reduce(
      (acc, payment) => acc + payment.amount,
      0,
    );
  }, [invoiceData]);

  const paymentStatus: "error" | "warning" | "success" | "" = useMemo(() => {
    if (paymentsTotal && invoiceData) {
      if (paymentsTotal > 0) {
        if (paymentsTotal >= invoiceData.total) {
          return "success";
        } else {
          return "warning";
        }
      }
      return "error";
    }
    return "error";
  }, [paymentsTotal, invoiceData]);

  // can edit only when there are no payments
  const canInvoiceEdit = useMemo(() => {
    if (invoiceData?.payments) {
      return invoiceData.payments.length === 0;
    }
  }, [invoiceData?.payments]);

  const handleAddStockRowItem = () => {
    setInvoiceItems([...invoiceItems, { product: null, quantity: 0 }]);
  };

  const handleRemoveItem = (index: number) => {
    const _items = [...invoiceItems];
    _items.splice(index, 1);
    setInvoiceItems([..._items]);
  };

  const handleOnQuantityChange = (index: number, quantity: number) => {
    const items = [...invoiceItems];
    const item = items[index];
    const newItem = {
      ...item,
      quantity,
    };
    items.splice(index, 1, newItem);
    setInvoiceItems(items);
  };

  const handleOnProductChange = (index: number, product: IProduct) => {
    const items = [...invoiceItems];
    const item = items[index];
    const newItem = {
      ...item,
      product,
    };
    items.splice(index, 1, newItem);
    setInvoiceItems(items);
  };

  const handleOnProductClear = (index: number) => {
    const items = [...invoiceItems];
    const item = items[index];
    const newItem = {
      ...item,
      product: null,
    };
    items.splice(index, 1, newItem);
    setInvoiceItems(items);
  };

  const handleOnProductFocussedOut = (index: number) => {
    const element = document.querySelector(
      `#product-quantity-name-${index}`,
    ) as HTMLInputElement;
    const items = [...invoiceItems];
    const item = items[index];
    if (item.product?.title.toLowerCase() !== element?.value?.toLowerCase()) {
      const newItem = {
        quantity: item.quantity,
        product: null,
      };
      items.splice(index, 1, newItem);
    }
    setInvoiceItems(items);
  };

  const isInvoiceComplete =
    (invoiceData?.total ?? 0) > 0 &&
    (paymentsTotal ?? 0) >= (invoiceData?.total ?? 0);

  const handleDelete = (invoiceId?: string) => {
    deleteAsync({ invoiceId })
      .then(() => navigate(`/organizations/${id}/invoices`))
      .catch((err) => {
        messageError(
          err.response?.data?.message ?? "Whoops! Unable to delete data",
        );
      });
  };

  return (
    <div
      style={{ marginBottom: 100, backgroundColor: "var(--exhut-light-grey5)" }}
      className="divColumn p-10 flex-1"
    >
      {notification}
      {messageNotifcation}

      <AddProductsModal
        open={showAddProduct}
        onCancel={() => setShowAddProduct(false)}
      />

      <ViewInvoiceModal
        open={!!printMode}
        action={printMode}
        onCancel={() => setPrintMode(null)}
        invoice={invoiceData}
        paymentsTotal={paymentsTotal}
        netTotal={netTotal}
      />

      <ViewReceiptModal
        open={showReceipt}
        onCancel={() => setShowReceipt(false)}
        invoice={invoiceData}
        paymentsTotal={paymentsTotal}
        netTotal={netTotal}
      />

      <div className="divSpread divAlignItemsOnly px-10">
        <Button
          type="link"
          onClick={() => navigate(`/organizations/${id}/invoices`)}
        >
          Back
        </Button>

        <Flex gap={10} className="divAlignItemsOnly">
          <Button
            onClick={() => setPrintMode("email")}
            disabled={invoiceItems?.length === 0}
            icon={<MailFilled />}
            type="text"
          ></Button>
          <Button
            onClick={() => setPrintMode("download")}
            icon={<SaveFilled />}
            disabled={invoiceItems?.length === 0}
            type="text"
          ></Button>
          <Button
            disabled={invoiceItems?.length === 0}
            onClick={() => setPrintMode("print")}
            icon={<PrinterFilled />}
            type="text"
          ></Button>

          <Tooltip title={"Receipts"}>
            <Button
              disabled={!isInvoiceComplete || invoiceItems?.length === 0}
              onClick={() => setShowReceipt(true)}
              icon={<BookOutlined />}
              type="text"
            ></Button>
          </Tooltip>
          <Button
            loading={isPending}
            onClick={formik.submitForm}
            type="primary"
            disabled={
              !canInvoiceEdit ||
              isInvoiceComplete ||
              isSearchInProgress ||
              (invoiceItems && invoiceItems.length === 0) ||
              Object.keys(formik.errors).length > 0
            }
          >
            Save
          </Button>
        </Flex>
      </div>

      <div className="divRight p-10">
        {!canInvoiceEdit && (
          <div className="divCenterAlign p-10">
            <span className="errorMsg smallText">
              Invoice cannot be modified since it has active payments.
            </span>
          </div>
        )}
      </div>

      <div className="card bg-white">
        <div className="divSpread divAlignItemsOnly">
          <h1>Invoice </h1>
          <span className="infoMsg">#{invoiceData?.externalId}</span>
        </div>

        <Flex className="my-20 divSpread" gap={20}>
          <div>
            <Alert
              type={paymentStatus}
              message={
                isInvoiceComplete
                  ? "Payment Complete"
                  : `Paid ${toAmount(paymentsTotal)}`
              }
            />
          </div>

          <span className="infoMsg">
            {toReadableDate(invoiceData?.createdAt)}
          </span>
        </Flex>

        <Divider />

        <div className="mt-20">
          <Flex gap={20} vertical className="my-40">
            <h3>Items</h3>
            <Flex gap={20} vertical>
              {invoiceItems?.map(
                (item: IProductQuanityRowItem, index: number) => {
                  return (
                    <ProductQuantityRowItem
                      onAddProduct={() => setShowAddProduct(true)}
                      showImage={false}
                      key={index}
                      item={item}
                      index={index}
                      onRemoveItem={(key) => handleRemoveItem(key)}
                      onQuantityChange={handleOnQuantityChange}
                      onProductChange={handleOnProductChange}
                      disabled={isInvoiceComplete || !canInvoiceEdit}
                      onLoading={(loading) => setSearchInProgress(loading)}
                      onClearProduct={handleOnProductClear}
                      onProductFocussedOut={handleOnProductFocussedOut}
                    />
                  );
                },
              )}
            </Flex>
            <div className="my-0">
              <Button
                onClick={() => handleAddStockRowItem()}
                type="link"
                disabled={isInvoiceComplete || !canInvoiceEdit}
              >
                Add Item
              </Button>
            </div>
          </Flex>
        </div>

        <Divider />

        <form onSubmit={formik.handleSubmit}>
          <Flex gap={30} vertical>
            <div>
              <h4>Tax</h4>
              <Input
                prefix={"%"}
                disabled={!canInvoiceEdit}
                placeholder="Taxes"
                {...formik.getFieldProps("tax")}
              />
              <ErrorPanel message={formik.errors.tax} />
            </div>

            <div>
              <span>Total</span>
              <h1>{toAmountDisplay(formik.values.total)}</h1>
            </div>

            <Divider />

            <div>
              <h4 className="mb-40">Customer Details</h4>

              <Row gutter={[20, 20]} className="mt-40">
                <Flex gap={10} vertical className="mx-20">
                  <span>First Name</span>
                  <Input
                    status={
                      !!formik.errors.customerFirstName ? "error" : undefined
                    }
                    disabled={!canInvoiceEdit || isInvoiceComplete}
                    {...formik.getFieldProps("customerFirstName")}
                  />
                </Flex>
                <Flex gap={10} vertical className="mx-20">
                  <span>Last Name</span>
                  <Input
                    disabled={!canInvoiceEdit || isInvoiceComplete}
                    {...formik.getFieldProps("customerLastName")}
                  />
                </Flex>
                <Flex gap={10} vertical className="mx-20">
                  <span>Email</span>
                  <Input
                    status={!!formik.errors.customerEmail ? "error" : undefined}
                    disabled={!canInvoiceEdit || isInvoiceComplete}
                    {...formik.getFieldProps("customerEmail")}
                  />
                  <ErrorPanel message={formik.errors.customerEmail} />
                </Flex>

                <Flex gap={10} vertical className="mx-20">
                  <span>Phone</span>
                  <Input
                    disabled={!canInvoiceEdit || isInvoiceComplete}
                    maxLength={10}
                    prefix={areaCode}
                    status={!!formik.errors.customerPhone ? "error" : undefined}
                    {...formik.getFieldProps("customerPhone")}
                  />
                  <ErrorPanel message={formik.errors.customerPhone} />
                </Flex>

                <Flex gap={10} vertical className="mx-20">
                  <span>Street 1</span>
                  <Input
                    disabled={!canInvoiceEdit || isInvoiceComplete}
                    {...formik.getFieldProps("customerStreet1")}
                  />
                </Flex>

                <Flex gap={10} vertical className="mx-20">
                  <span>Street 2</span>
                  <Input
                    disabled={!canInvoiceEdit || isInvoiceComplete}
                    {...formik.getFieldProps("customerStreet2")}
                  />
                </Flex>
                <Flex gap={10} vertical className="mx-20">
                  <span>City</span>
                  <Input
                    disabled={!canInvoiceEdit || isInvoiceComplete}
                    {...formik.getFieldProps("customerCity")}
                  />
                </Flex>

                <Flex gap={10} vertical className="mx-20">
                  <span>State</span>
                  <Input
                    disabled={!canInvoiceEdit || isInvoiceComplete}
                    {...formik.getFieldProps("customerState")}
                  />
                </Flex>
                <Flex gap={10} vertical className="mx-20">
                  <span>Country</span>
                  <Input
                    disabled={!canInvoiceEdit || isInvoiceComplete}
                    {...formik.getFieldProps("customerCountryCode")}
                  />
                </Flex>

                <Flex gap={10} vertical className="mx-20">
                  <span>Zip Code</span>
                  <Input
                    disabled={!canInvoiceEdit || isInvoiceComplete}
                    {...formik.getFieldProps("customerZipCode")}
                  />
                  <ErrorPanel message={formik.errors.customerZipCode} />
                </Flex>
              </Row>
            </div>

            <div className="my-10">
              <Flex className="divAlignItemsOnly" gap={5}>
                <h4>Notes To Customer</h4>{" "}
                <InfoIcon title="Visible to customers" />
              </Flex>

              <Input.TextArea
                disabled={!canInvoiceEdit || isInvoiceComplete}
                placeholder="Notes"
                {...formik.getFieldProps("notes")}
              />
            </div>
          </Flex>

          <InvoicePaymentsSection
            invoiceData={invoiceData}
            refetchInvoiceData={refetchInvoiceData}
          />

          <div className="my-20">
            <Flex gap={15}>
              <h4>Include Footer</h4>
              <Checkbox
                checked={formik.values.includeFooter}
                onChange={(e) =>
                  formik.setFieldValue("includeFooter", e.target.checked)
                }
              />
            </Flex>
          </div>

          <div className="my-20">
            <Flex gap={25}>
              <Flex gap={10}>
                <h4>Accept Payment</h4>
                <InfoIcon title="Customers will pay via Pay Now button in the invoice email." />
              </Flex>

              <Checkbox
                checked={formik.values.includePaymentLink}
                onChange={(e) =>
                  formik.setFieldValue("includePaymentLink", e.target.checked)
                }
              />
            </Flex>
            {formik.values.includePaymentLink && (
              <span className="infoMsg mediumText">
                By checking the above box, you accept the pricing terms for
                ExpenseHut.
              </span>
            )}
          </div>

          {formik.values.includeFooter === true && (
            <div
              className="my-20 p-10"
              style={{ border: "1px dashed lightgrey", borderRadius: 5 }}
            >
              <p
                style={{ color: "var(--exhut-dark-grey)" }}
                className="mediumText"
              >
                {orgSettingsData?.invoiceFooter}
              </p>
              {orgSettingsData?.invoiceFooterImage && (
                <Image src={orgSettingsData.invoiceFooterImage} width={100} />
              )}
            </div>
          )}

          <Divider />

          <div className="my-40">
            <Flex gap={5}>
              <h4>Internal Notes</h4>{" "}
              <InfoIcon title="For internal use only. Not visible to customers" />
            </Flex>

            <Input.TextArea
              placeholder="Personal notes..."
              {...formik.getFieldProps("internalNotes")}
            />
          </div>

          <div className="divCenterAlign my-80">
            <Popconfirm
              title="Are you sure you want to delete this invoice?"
              onConfirm={() => handleDelete(iid)}
            >
              <Button type="link" danger>
                Delete Invoice
              </Button>
            </Popconfirm>
          </div>
        </form>
      </div>
    </div>
  );
}
