import { Button, Flex, Modal } from "antd";
import { useParams } from "react-router-dom";
import { useGetOrganizationById } from "../../../api/api.get";

import { WarningFilled } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { useRegisterAsVendor } from "../../../api/api";
import { useUnRegisterAsVendor } from "../../../api/api.delete";
import { InfoIcon } from "../../../components/icon.component";
import { useNotifications } from "../../../components/notifications/notification";

export default function VendorSettingsPage() {
  const { id } = useParams();
  const { mutateAsync, isPending } = useRegisterAsVendor(id);
  const { mutateAsync: cancelVendorRegistrationAsync } =
    useUnRegisterAsVendor(id);
  const [modal, contextHolder] = Modal.useModal();

  const { data: orgData } = useGetOrganizationById(id);

  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Organization successfully updated",
  });

  const handleRegisterAsVendor = async () => {
    mutateAsync({})
      .then(() => {
        notifySuccess();
      })
      .catch(() => notifyError());
  };

  const handleRegisterCancellation = async () => {
    cancelVendorRegistrationAsync({})
      .then(() => {
        notifySuccess();
      })
      .catch(() => notifyError());
  };

  const confirm = () => {
    modal.confirm({
      title: "Confirm",
      icon: <WarningFilled />,
      content:
        "This will temporarily unregister you as vendor. Merchants will not be able to send stock requests. You can re-register anytime later.",
      okText: "Confirm",
      onOk: handleRegisterCancellation,
    });
  };

  return (
    <div id="org-tax-settings-container" className="p-40 flex-1">
      {notification}
      {contextHolder}
      <div className={`divCenterAlign divColumn p-32`}>
        <Content
          className="p-20"
          style={{ minWidth: "40%", boxShadow: "3px 1px 5px 5px #e6ddd6" }}
        >
          <div className="w-100">
            <div className="mb-80">
              <div className="title mb-10">Vendor Settings</div>
            </div>

            <div className="mt-80 mb-60">
              <Flex className="divCenterAlign" gap={10}>
                <InfoIcon title="Can be used to show in invoices when shared to customers." />
                <p className="infoMsg mediumText">
                  Register as vendor to accept stock requests.{" "}
                </p>
              </Flex>

              <p className="infoMsg mediumText">
                Manage multiple customers at the same time.
              </p>
              <Flex vertical gap={40}>
                <Flex vertical>
                  <h2>{orgData?.vendorId?.toUpperCase()}</h2>
                </Flex>

                {!orgData?.vendorId && (
                  <Button
                    loading={isPending}
                    type="primary"
                    onClick={() => handleRegisterAsVendor()}
                  >
                    Register As Vendor
                  </Button>
                )}

                {!!orgData?.vendorId && (
                  <Button
                    loading={isPending}
                    type="link"
                    danger
                    onClick={confirm}
                  >
                    Cancel Vendor Subscription
                  </Button>
                )}
              </Flex>
            </div>
          </div>
        </Content>
      </div>
    </div>
  );
}
