import { Button, Checkbox, Divider, Flex, Image, Input, Row } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { ICreateInvoicePayload, useCreateInvoice } from "../../../api/api";
import { useGetOrgSetting } from "../../../api/api.get";
import ErrorPanel from "../../../components/error.panel.component";
import { InfoIcon } from "../../../components/icon.component";
import { useMessage } from "../../../components/notifications/message";
import { useNotifications } from "../../../components/notifications/notification";
import { ProductQuantityRowItem } from "../../../components/product-quantity.component";
import { useCurrency } from "../../../hooks/useCurrency";
import { IProduct, IProductQuanityRowItem } from "../../../types/types";
import {
  calculateProductQuantityTotal,
  validateItems,
} from "../../../utils/products.utils";
import AddProductsModal from "../products/add-products-modal";

export default function AddInvoice() {
  const { id } = useParams();
  const { areaCode, toAmount } = useCurrency();
  const { notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Invoice created successfully.",
  });
  const [showAddProduct, setShowAddProduct] = useState<boolean>(false);
  const { notification: messageNotifcation, messageError } = useMessage();
  const { data: orgSettingsData } = useGetOrgSetting(id);

  const navigate = useNavigate();
  const [isSearchInProgress, setSearchInProgress] = useState<boolean>();

  const { mutateAsync, isPending } = useCreateInvoice(id);
  const [invoiceItems, setInvoiceItems] = useState<IProductQuanityRowItem[]>(
    [],
  );

  const formik = useFormik({
    initialValues: {
      total: 0,
      tax: "",
      customerFirstName: "",
      customerLastName: "",
      customerStreet1: "",
      customerStreet2: "",
      customerCity: "",
      customerState: "",
      customerCountryCode: "",
      customerPhone: "",
      customerEmail: "",
      customerZipCode: "",
      products: [],
      notes: "",
      internalNotes: "",
      includeFooter: false,
    },
    validationSchema: Yup.object({
      total: Yup.number(),
      products: Yup.array().required("Please add products or services"),
      tax: Yup.number()
        .typeError("Please enter valid number")
        .min(1, "Please enter valid percentage")
        .max(100, "Please enter valid percentage")
        .required("Please enter tax percentage"),
      customerFirstName: Yup.string()
        .matches(/^[A-Za-z][A-Za-z ]*$/, "Please enter valid name")
        .required("Please enter customer name"),
      customerPhone: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid phone number")
        .required("Please enter customer phone"),
      customerEmail: Yup.string().matches(
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
        "Please enter valid email address",
      ),
      customerZipCode: Yup.string().matches(
        /^(0|[1-9]\d*)(\.\d+)?$/,
        "Please enter valid zip code",
      ),
      authRequired: Yup.bool(),
    }),
    onSubmit: async (values) => {
      const result = validateItems(invoiceItems);

      if (!result) {
        messageError("One or more products is incomplete.");
        return;
      }

      const payload: ICreateInvoicePayload = {
        items: invoiceItems,
        tax: +values.tax,
        total: values.total,
        notes: values.notes,
        internalNotes: values.internalNotes,
        customer: {
          firstName: values.customerFirstName,
          lastName: values.customerLastName,
          email: values.customerEmail,
          phone: `${areaCode}${values.customerPhone}`,
          address: {
            street1: values.customerStreet1,
            street2: values.customerStreet2,
            city: values.customerCity,
            state: values.customerState,
            countryCode: values.customerCountryCode,
            zipCode: values.customerZipCode,
          },
        },
      };

      mutateAsync(payload)
        .then(() => {
          formik.resetForm();
          setInvoiceItems([]);
          navigate(`/organizations/${id}/invoices`);
        })
        .catch(() => {
          notifyError();
        });
    },
  });

  const handleOnProductFocussedOut = (index: number, text: string) => {
    const items = [...invoiceItems];
    const item = items[index];
    if (item.product?.title.toLowerCase() !== text.toLowerCase()) {
      const newItem = {
        quantity: item.quantity,
        product: null,
      };
      items.splice(index, 1, newItem);
    }
    setInvoiceItems(items);
  };

  const handleAddStockRowItem = () => {
    setInvoiceItems([...invoiceItems, { product: null, quantity: 0 }]);
  };

  const handleRemoveItem = (index: number) => {
    const _items = [...invoiceItems];
    _items.splice(index, 1);
    setInvoiceItems([..._items]);
  };

  const handleOnQuantityChange = (index: number, quantity: number) => {
    const items = [...invoiceItems];
    const item = items[index];
    const newItem = {
      ...item,
      quantity,
    };
    items.splice(index, 1, newItem);
    setInvoiceItems(items);
  };

  const handleOnProductChange = (index: number, product: IProduct) => {
    const items = [...invoiceItems];
    const item = items[index];
    const newItem = {
      ...item,
      product,
    };
    items.splice(index, 1, newItem);
    setInvoiceItems(items);
  };

  useEffect(() => {
    let itemTotal = calculateProductQuantityTotal(
      invoiceItems,
      +formik.values.tax,
    );
    formik.setFieldValue("total", itemTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceItems, formik.values.tax]);

  return (
    <div
      style={{ backgroundColor: "var(--exhut-light-grey5)" }}
      className="divColumn p-10 flex-1"
    >
      {messageNotifcation}
      <AddProductsModal
        open={showAddProduct}
        onCancel={() => setShowAddProduct(false)}
      />
      <div className="my-20 mx-10 divSpread">
        <Button
          type="link"
          onClick={() => navigate(`/organizations/${id}/invoices`)}
        >
          Back
        </Button>

        <Button
          onClick={formik.submitForm}
          loading={isPending}
          type="primary"
          disabled={formik.values.total === 0 || isSearchInProgress}
        >
          Create
        </Button>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div
          className="card p-40"
          style={{ marginBottom: 50, backgroundColor: "var(--exhut-white)" }}
        >
          <div className="divSpread divAlignItemsOnly">
            <h1>Invoice</h1>
          </div>

          <Flex gap={20} vertical className="my-40">
            <h3>Items</h3>
            <Flex gap={20} vertical>
              {invoiceItems.map(
                (item: IProductQuanityRowItem, index: number) => {
                  return (
                    <ProductQuantityRowItem
                      showImage={false}
                      key={index}
                      item={item}
                      index={index}
                      onRemoveItem={(key) => handleRemoveItem(key)}
                      onQuantityChange={handleOnQuantityChange}
                      onProductChange={handleOnProductChange}
                      onLoading={(loading) => setSearchInProgress(loading)}
                      onAddProduct={() => setShowAddProduct(true)}
                      onProductFocussedOut={handleOnProductFocussedOut}
                    />
                  );
                },
              )}
            </Flex>
            {invoiceItems.length === 0 && (
              <ErrorPanel message={"Please add items"} />
            )}

            <div className="divSpread">
              <Button onClick={() => handleAddStockRowItem()} type="link">
                Add Item
              </Button>
            </div>
          </Flex>

          {notification}
          <Flex gap={30} vertical className="mt-40">
            <div>
              <h3>Tax</h3>
              <Input
                size="middle"
                prefix={"%"}
                placeholder="Taxes"
                {...formik.getFieldProps("tax")}
              />
              <ErrorPanel message={formik.errors.tax} />
            </div>

            <div className="mt-40">
              <h3>Total</h3>
              <h1>{toAmount(formik.values.total)}</h1>
            </div>

            <Divider />

            <div>
              <h4 className="mb-40">Customer Details</h4>

              <Row gutter={[20, 20]} className="mt-40">
                <Flex gap={10} vertical className="mx-20">
                  <span>First Name</span>
                  <Input
                    maxLength={100}
                    status={
                      !!formik.errors.customerFirstName ? "error" : undefined
                    }
                    {...formik.getFieldProps("customerFirstName")}
                  />
                </Flex>

                <Flex gap={10} vertical className="mx-20">
                  <span>Last Name</span>
                  <Input {...formik.getFieldProps("customerLastName")} />
                </Flex>

                <Flex gap={10} vertical className="mx-20">
                  <span>Email</span>
                  <Input {...formik.getFieldProps("customerEmail")} />
                  <ErrorPanel message={formik.errors.customerEmail} />
                </Flex>

                <Flex gap={10} vertical className="mx-20">
                  <span>Phone</span>
                  <Input
                    prefix={areaCode}
                    maxLength={10}
                    status={!!formik.errors.customerPhone ? "error" : undefined}
                    {...formik.getFieldProps("customerPhone")}
                  />
                </Flex>

                <Flex gap={10} vertical className="mx-20">
                  <span>Street 1</span>
                  <Input {...formik.getFieldProps("customerStreet1")} />
                </Flex>

                <Flex gap={10} vertical className="mx-20">
                  <span>Street 2</span>
                  <Input {...formik.getFieldProps("customerStreet2")} />
                </Flex>
                <Flex gap={10} vertical className="mx-20">
                  <span>City</span>
                  <Input {...formik.getFieldProps("customerCity")} />
                </Flex>

                <Flex gap={10} vertical className="mx-20">
                  <span>State</span>
                  <Input {...formik.getFieldProps("customerState")} />
                </Flex>

                <Flex gap={10} vertical className="mx-20">
                  <span>Country</span>
                  <Input {...formik.getFieldProps("customerCountryCode")} />
                </Flex>

                <Flex gap={10} vertical className="mx-20">
                  <span>Zip Code</span>
                  <Input {...formik.getFieldProps("customerZipCode")} />
                  <ErrorPanel message={formik.errors.customerZipCode} />
                </Flex>
              </Row>
            </div>
          </Flex>

          <div className="my-20">
            <Flex gap={5}>
              <h4>Notes To Customer</h4>{" "}
              <InfoIcon title="Visible to customers" />
            </Flex>

            <Input.TextArea
              placeholder="Notes"
              {...formik.getFieldProps("notes")}
            />
          </div>

          <div className="my-20">
            <Flex gap={15}>
              <h4>Include Footer</h4>
              <Checkbox
                checked={formik.values.includeFooter}
                onChange={(e) =>
                  formik.setFieldValue("includeFooter", e.target.checked)
                }
              />
            </Flex>
          </div>

          {formik.values.includeFooter === true && (
            <div
              className="my-20 p-10"
              style={{
                border: "1px dashed lightgrey",
                borderRadius: 5,
                color: "white",
              }}
            >
              <p
                style={{ color: "var(--exhut-dark-grey)" }}
                className="mediumText"
              >
                {orgSettingsData?.invoiceFooter}
              </p>
              {orgSettingsData?.invoiceFooterImage && (
                <Image src={orgSettingsData.invoiceFooterImage} width={100} />
              )}
            </div>
          )}
        </div>

        <div
          className="card"
          style={{ marginBottom: 100, backgroundColor: "var(--exhut-white)" }}
        >
          <Flex gap={10} className="divAlignItemsOnly">
            <h3 className="infoMsg">Internal Use Only</h3>
            <InfoIcon title="For internal use only. Not visible to customers" />
          </Flex>

          <div className="my-40">
            <Flex gap={5}>
              <h4>Notes</h4>{" "}
            </Flex>

            <Input.TextArea
              placeholder="Notes"
              {...formik.getFieldProps("internalNotes")}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
