import { DatePicker, Flex, Modal, Radio } from "antd";
import Alert from "antd/es/alert/Alert";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreatePayrollCycle } from "../../../api/api";
const { RangePicker } = DatePicker;
export default function AddPayrollCycleModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();

  const {
    mutateAsync: createPayrollCycleAsync,
    error,
    isPending,
  } = useCreatePayrollCycle(id!!);

  const formik = useFormik({
    initialValues: {
      type: "upcoming",

      startDate: "",
      endDate: "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Please select type"),
      startDate: Yup.string().when("type", {
        is: "dates",
        then: (schema) => schema.required("Please select start date."),
      }),
      endDate: Yup.string().when("type", {
        is: "dates",
        then: (schema) => schema.required("Please select end date."),
      }),
    }),
    onSubmit: async (values) => {
      createPayrollCycleAsync({ ...values })
        .then(() => {
          formik.resetForm();
          onCancel();
        })
        .catch(console.error);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Payroll Schedule"
        open={open}
        onOk={formik.submitForm}
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
        okButtonProps={{ loading: isPending }}
      >
        <Flex gap={30} vertical>
          <Radio.Group
            onChange={(e) => formik.setFieldValue("type", e.target.value)}
            value={formik.values.type}
          >
            <Radio value={"upcoming"}>Upcoming Cycle</Radio>
            <Radio value={"dates"}>Select Dates</Radio>
          </Radio.Group>

          {formik.values.type === "dates" && (
            <div>
              <RangePicker
                onChange={(d, dates) => {
                  formik.setFieldValue("startDate", dates[0]);
                  formik.setFieldValue("endDate", dates[1]);
                }}
              />
            </div>
          )}

          {error && (
            <Alert
              type="error"
              message={`Whoops! ${error?.response?.data?.message ?? "Unable to create cycle."} `}
            />
          )}
        </Flex>
      </Modal>
    </form>
  );
}
