import {
  Button,
  Flex,
  Image,
  Popconfirm,
  Table,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteOrgSales } from "../../../api/api.delete";
import { useGetSales } from "../../../api/api.get";
import EmptyComponent from "../../../components/empty.component";
import { IOrganizationSale } from "../../../types/types";
import { toFixedDisplay } from "../../../utils/number.utils";
import OrganizationDate from "../organization-date";
import AddExpenseModal from "./add-sales-modal";
import ViewSalesModal from "./view-sales-modal";

const { Title } = Typography;

export default function OrganizationSalesHomePage() {
  const { id } = useParams();
  const [date, setDate] = useState(moment());
  const navigate = useNavigate();

  const {
    data: sales,
    refetch,
    isLoading,
    isFetching,
  } = useGetSales(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [viewableSalesObj, setViewableSalesObj] = useState<IOrganizationSale>();

  const { mutateAsync: deleteAsync } = useDeleteOrgSales(id!!);

  const handleDelete = (row: any) => {
    deleteAsync({ saleId: row.id })
      .then(() => refetch())
      .catch(console.error);
  };

  const handleView = (row: any) => {
    setViewableSalesObj(row);
  };

  const handleDateChange = (dateString: string) => {
    setDate(moment(dateString, "MMM YYYY"));
  };

  useEffect(() => {
    if (date) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const columns = [
    {
      title: "Description",
      key: "externalId",
      render: (order: IOrganizationSale) => (
        <Flex vertical>
          <strong>{order.externalId}</strong>
          <span className="mediumText">
            {moment(order.created_at).format("DD MMM YYYY hh:mm A")}
          </span>
        </Flex>
      ),
    },
    {
      title: "Amount",
      dataIndex: "total",
      key: "amount",
      render: (value: number) => <span>{toFixedDisplay(value)}</span>,
    },
    {
      title: "",
      key: "operationView",
      width: 100,
      render: (row: any) => (
        <Button type="link" onClick={() => handleView(row)}>
          View
        </Button>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Delete"
          description="Are you sure to delete this order?"
          onConfirm={() => handleDelete(row)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger type="text" color="danger">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div style={{ marginBottom: 100 }} className="divColumn mx-40 my-40">
      <AddExpenseModal open={isAdd} onCancel={() => setIsAdd(false)} />
      <ViewSalesModal
        open={!!viewableSalesObj}
        onCancel={() => setViewableSalesObj(undefined)}
        sales={viewableSalesObj}
      />
      <div className="divSpread">
        <OrganizationDate onChange={handleDateChange} />

        <Flex gap={20} className="divAlignItemsOnly">
          <Tooltip title="Fast food">
            <Button
              icon={
                <Image src={"/assets/icons/fastfood.png"} preview={false} />
              }
              onClick={() => navigate(`restaurants/ff`)}
            ></Button>
          </Tooltip>

          <Tooltip title="Restaurant Dine In">
            <Button
              icon={
                <Image src={"/assets/icons/restaurant.svg"} preview={false} />
              }
              onClick={() => navigate(`restaurants`)}
            ></Button>
          </Tooltip>

          <Tooltip title="Retail">
            <Button
              id="retail-order-btn"
              icon={<Image src={"/assets/icons/shirt.png"} preview={false} />}
              onClick={() => navigate(`retail`)}
            ></Button>
          </Tooltip>

          <Tooltip title="Quick Order">
            <Button
              id="quick-order-btn"
              icon={
                <Image src={"/assets/icons/quick-order.svg"} preview={false} />
              }
              onClick={() => setIsAdd(!isAdd)}
            ></Button>
          </Tooltip>
        </Flex>
      </div>
      <div className="divSpread divAlignItemsOnly mt-40">
        <Title level={3}>Orders</Title>
      </div>
      <Title level={5}>
        Add every day total sales or individual sale items. Visualize the graphs
        in the home page.
      </Title>

      {!isLoading && sales?.length === 0 && (
        <EmptyComponent title="No orders available." />
      )}
      {(isLoading || (sales && sales?.length > 0)) && (
        <Table
          dataSource={sales}
          columns={columns}
          loading={isLoading || isFetching}
        />
      )}
    </div>
  );
}
