import {
  Alert,
  Button,
  Divider,
  Flex,
  Image,
  Input,
  Modal,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  useGetOrganizationById,
  useGetOrganizationLogoById,
} from "../../api/api.get";
import { useUpdateOrganization } from "../../api/api.update";
import ErrorPanel from "../../components/error.panel.component";
import { getCountryFullName, ROUTES } from "../../utils/constants";

import { UploadOutlined } from "@ant-design/icons";
import { message, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { useDeleteOrg } from "../../api/api.delete";
import { useMessage } from "../../components/notifications/message";
import { useNotifications } from "../../components/notifications/notification";
import { useCurrency } from "../../hooks/useCurrency";
import { getBase64 } from "../../utils/file.utils";
import { capitalize } from "../../utils/functions";

const { Title } = Typography;

export default function OrganizationSettings() {
  const navigate = useNavigate();

  const { id } = useParams();
  const { mutateAsync, isPending } = useUpdateOrganization(id);
  const { areaCode } = useCurrency();

  const { mutateAsync: deleteOrgAsync, isPending: isDeletePending } =
    useDeleteOrg(id);
  const [errorMsg, setErrorMsg] = useState<string>();

  const { data: orgData } = useGetOrganizationById(id);
  const [orgDeleteConfirmText, setOrgDeleteConfirmText] = useState<string>();

  const [deletableOrgId, setDeletableOrgId] = useState<string>();
  const { notifySuccess, notification } = useNotifications({
    title: "Success",
    subtitle: "Organization successfully updated",
  });

  const handleOrgDelete = () => {
    setDeletableOrgId(id);
    deleteOrgAsync(null)
      .then(() => {
        navigate(ROUTES.HOME);
      })
      .catch(() => {
        message.error("Sorry! Something went wrong. Please try again.");
      });
  };

  const formik = useFormik({
    initialValues: {
      website: orgData?.website ?? "",
      street1: orgData?.street1 ?? "",
      street2: "",
      city: orgData?.city ?? "",
      state: orgData?.state ?? "",
      zipCode: orgData?.zipCode ?? "",
      phone:
        (orgData?.phone && areaCode
          ? orgData?.phone.replace(areaCode, "")
          : "") ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      website: Yup.string(),
      street1: Yup.string().required("Please enter name"),
      city: Yup.string().required("Please enter city"),
      state: Yup.string().required("Please enter state"),
      zipCode: Yup.string()
        .matches(/^[0-9]+$/, "Zip code or pin code must be digits")
        .required("Please enter zip.")
        .min(5, "Invalid zipcode")
        .max(7, "Invalid zipcode"),
    }),
    onSubmit: async (values) => {
      setErrorMsg(undefined);
      mutateAsync({
        ...values,
        zipCode: +values.zipCode,
        phone: `${areaCode}${formik.values.phone}`,
      })
        .then(() => {
          notifySuccess();
        })
        .catch((err) => {
          if (err.response.data.message === "Address is invalid") {
            setErrorMsg(
              "Unable to verify address. Please double check if you have entered full address including house or unit number.",
            );
          } else {
            setErrorMsg("Whoops! Unable to submit data. Please try again.");
          }
        });
    },
  });

  return (
    <div id="org-settings-container" className="p-40 flex-1">
      {notification}
      <div className={`divCenterAlign divColumn p-32`}>
        <Modal
          title="Delete Organization"
          open={!!deletableOrgId}
          onOk={handleOrgDelete}
          onCancel={() => setDeletableOrgId(undefined)}
          okText="Confirm"
          cancelText="Cancel"
          okButtonProps={{
            disabled: orgDeleteConfirmText !== "confirm",
            loading: isPending || isDeletePending,
          }}
        >
          <p>Are you sure?</p>
          <p>
            This is a permanent action and you will not be able to recover any
            data associated with this organizaton.
          </p>

          <p>
            <Input
              id="confirm"
              onChange={(e) => setOrgDeleteConfirmText(e.target.value)}
              placeholder="Type 'confirm'"
            />
          </p>
        </Modal>

        <Content
          className="p-20"
          style={{ minWidth: "50%", boxShadow: "3px 1px 5px 5px #e6ddd6" }}
        >
          <div className="w-100">
            <div className="mb-80">
              <div className="title mb-10">{capitalize(orgData?.name)}</div>
            </div>

            <OrganizationLogoSection />

            <div className="mt-80 mb-60 divCenterAlign">
              <form onSubmit={formik.handleSubmit}>
                <Flex vertical>
                  <h4>Website</h4>
                  <div className="divColumn">
                    <Input {...formik.getFieldProps("website")} />
                    <ErrorPanel message={formik.errors.website} />
                  </div>
                </Flex>

                <Flex vertical>
                  <h4>Street 1</h4>

                  <Input {...formik.getFieldProps("street1")} />
                  <ErrorPanel message={formik.errors.street1} />
                </Flex>

                <Flex vertical>
                  <h4>Street 2</h4>

                  <Input {...formik.getFieldProps("street2")} />
                  <ErrorPanel message={formik.errors.street2} />
                </Flex>

                <Flex vertical>
                  <h4>City</h4>

                  <Input {...formik.getFieldProps("city")} />
                  <ErrorPanel message={formik.errors.city} />
                </Flex>

                <Flex vertical>
                  <h4>State</h4>

                  <Input {...formik.getFieldProps("state")} />
                  <ErrorPanel message={formik.errors.state} />
                </Flex>

                <Flex vertical className="mb-40">
                  <h4>Country</h4>
                  <strong id="org-country-value" className="infoMsg">
                    {getCountryFullName(orgData?.country)}
                  </strong>
                </Flex>

                <Flex vertical>
                  <h4>Zipcode</h4>

                  <Input {...formik.getFieldProps("zipCode")} />
                  <ErrorPanel message={formik.errors.zipCode} />
                </Flex>

                <Flex vertical>
                  <h4>Phone</h4>

                  <Input prefix={areaCode} {...formik.getFieldProps("phone")} />
                  <ErrorPanel message={formik.errors.phone} />
                </Flex>

                {errorMsg && (
                  <Alert
                    type="error"
                    message={errorMsg}
                    style={{ width: 300 }}
                  />
                )}

                <Button type="primary" onClick={formik.submitForm}>
                  Update
                </Button>
              </form>
            </div>

            <Divider />

            <Flex
              vertical
              gap={20}
              className="mb-40 p-20"
              style={{ backgroundColor: "#ffcece" }}
            >
              <Title level={4}>Danger Zone</Title>
              <span>
                This is an irreversible action and you will permanently lose
                access to all you content.{" "}
              </span>
              <Button
                type="primary"
                danger
                onClick={() => setDeletableOrgId(id)}
              >
                Delete
              </Button>
            </Flex>
          </div>
        </Content>
      </div>
    </div>
  );
}

function OrganizationLogoSection() {
  const { id } = useParams();
  const { notification, messageSuccess, messageError } = useMessage();

  const [changeMode, setChangeMode] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { mutateAsync, isPending } = useUpdateOrganization(id);
  const { data: orgData } = useGetOrganizationLogoById(id);

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const temp = [...fileList];
      temp.push(file);

      setFileList(temp);

      if (file.size > 10045377) {
      }
      return false;
    },
    fileList,
  };

  const handleUpload = () => {
    if (fileList.length > 0) {
      getBase64(fileList[0]).then((image) => {
        if (image) {
          mutateAsync({ logo: image })
            .then(() => {
              setFileList([]);
              setChangeMode(false);
              messageSuccess("Logo successfully updated.");
            })
            .catch((err) => {
              if (err.status === 413) {
                messageError("Whoops! Please try a smaller image.");
              } else {
                messageError("Whoops! Please try again.");
              }
            });
        } else {
          messageError("Whoops! Please try again.");
        }
      });
    }
  };

  return (
    <div className="divCenterAlign divColumn my-0">
      {notification}
      {!changeMode && (
        <div className="divCenterAlign divColumn">
          <Image
            src={orgData?.logo ?? "/assets/icons/cityscape.png"}
            width={"25%"}
            style={{ borderRadius: 10 }}
          />

          <Button type="link" onClick={() => setChangeMode(!changeMode)}>
            Change
          </Button>
        </div>
      )}
      {changeMode && (
        <Flex vertical gap={20}>
          <Upload
            {...props}
            multiple={true}
            maxCount={5}
            accept="image/png,image/jpeg"
          >
            <Button
              disabled={fileList.length > 0 || fileList.length >= 5}
              icon={<UploadOutlined />}
            >
              Upload Image
            </Button>
          </Upload>

          <Button
            disabled={fileList.length === 0}
            onClick={handleUpload}
            type="primary"
            loading={isPending}
          >
            {" "}
            Upload
          </Button>
        </Flex>
      )}
    </div>
  );
}
