import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Button, Card, Flex, Image, Input, Row, Skeleton, Tooltip } from "antd";
import Meta from "antd/es/card/Meta";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import {
  useSearchAllProducts,
  useSearchProducts,
} from "../../../../api/api.get";
import EmptyComponent from "../../../../components/empty.component";
import { useNotifications2 } from "../../../../components/notifications/notification";
import { useCurrency } from "../../../../hooks/useCurrency";
import { useLayoutContext } from "../../../../providers/layout.provider";
import { IProduct } from "../../../../types/types";
import ConfirmCheckout from "./fastfood.checkout";

export default function FastFoodPage() {
  const { id } = useParams();
  const [searchText, setSearchText] = useState<string>("");
  const { toAmount } = useCurrency();
  const { notification, notifySuccess } = useNotifications2();
  const navigate = useNavigate();

  const [items, setItems] = useState<IProduct[]>([]);
  const { isMobile } = useLayoutContext();

  const [productsToCheckout, setProductsToCheckout] = useState<IProduct[]>([]);

  const [debouncedProductNameSearchText] = useDebounce(searchText, 300);
  const [confirmCheckout, setConfirmCheckout] = useState<boolean>(false);

  const { data: productsByNameData, isLoading } = useSearchProducts(
    id,
    debouncedProductNameSearchText,
  );

  const productCountMap: Map<string, number> = useMemo(() => {
    const map = new Map<string, number>();
    for (const p of productsToCheckout) {
      const existing = map.get(p._id);
      if (existing) {
        map.set(p._id, existing + 1);
      } else {
        map.set(p._id, 1);
      }
    }
    return map;
  }, [productsToCheckout]);

  const { data: allProducts, isLoading: allProductsLoading } =
    useSearchAllProducts(id);

  useEffect(() => {
    if (debouncedProductNameSearchText && productsByNameData) {
      setItems(productsByNameData);
    } else {
      setItems(allProducts ?? []);
    }
  }, [debouncedProductNameSearchText, allProducts, productsByNameData]);

  const handleRemoveItems = (item: IProduct) => {
    const _items = [...productsToCheckout];
    const index = _items.findIndex((p) => p._id === item._id);
    _items.splice(index, 1);
    setProductsToCheckout(_items);
  };

  const itemTotal: number = productsToCheckout.reduce(
    (acc, item) => acc + (item.price?.value ?? 0),
    0,
  );

  const handleAddItems = (item: IProduct) => {
    const existingProductsToCheckout = [...productsToCheckout];
    existingProductsToCheckout.push(item);
    setProductsToCheckout(existingProductsToCheckout);
  };

  return (
    <div className=" p-20" style={{ height: "100%" }}>
      {notification}

      <ConfirmCheckout
        itemTotal={itemTotal}
        open={confirmCheckout}
        onOK={() => {
          setConfirmCheckout(false);
          setProductsToCheckout([]);
          notifySuccess({
            title: "Success",
            description: "Order successfully created.",
          });
        }}
        onCancel={() => setConfirmCheckout(false)}
        items={productsToCheckout}
        onRemoveItems={(item: IProduct) => handleRemoveItems(item)}
      />

      <div className="">
        <div className="divSpread mb-20">
          <Flex gap={20}>
            <Tooltip title="Orders Home">
              <Button
                type="text"
                icon={
                  <Image src="/assets/icons/home-order.svg" preview={false} />
                }
                onClick={() => navigate(`/organizations/${id}/sales`)}
              ></Button>
            </Tooltip>

            <Input
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              allowClear
            />
          </Flex>

          <Flex gap={5} className="divAlignItemsOnly">
            <strong>{toAmount(itemTotal)}</strong>

            <Button
              type="primary"
              onClick={() => setConfirmCheckout(true)}
              disabled={productsToCheckout.length === 0}
              icon={<ShoppingCartOutlined />}
            >
              {productsToCheckout.length}
            </Button>
          </Flex>
        </div>

        <div
          className="card"
          style={{
            margin: 0,
            backgroundColor: "var(--exhut-light-grey5)",
          }}
        >
          <Skeleton loading={allProductsLoading}>
            {items.length === 0 && (
              <EmptyComponent title="No Products Available." />
            )}
            {items.length > 0 && (
              <Row gutter={16}>
                <Skeleton active loading={isLoading || allProductsLoading}>
                  {items.map((item: IProduct, index: number) => {
                    return (
                      <Card
                        key={index}
                        style={{
                          width: isMobile ? "auto" : 200,
                          marginLeft: 10,
                          marginRight: 10,
                          padding: 10,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                        cover={<img alt="No Pic Available" src={item.cover} />}
                        actions={
                          productsToCheckout
                            .map((p) => p._id)
                            .includes(item._id)
                            ? [
                                <MinusCircleOutlined
                                  onClick={() => handleRemoveItems(item)}
                                />,
                                <Button icon={<ShoppingCartOutlined />}>
                                  {productCountMap.get(item._id)}
                                </Button>,
                                <PlusCircleOutlined
                                  onClick={() => handleAddItems(item)}
                                />,
                              ]
                            : [
                                <Button
                                  icon={<ShoppingCartOutlined />}
                                  onClick={() => handleAddItems(item)}
                                >
                                  Add To Cart
                                </Button>,
                              ]
                        }
                      >
                        <Meta
                          title={item.title}
                          description={toAmount(item.price?.value)}
                        />
                      </Card>
                    );
                  })}
                </Skeleton>
              </Row>
            )}
          </Skeleton>
        </div>
      </div>
    </div>
  );
}
