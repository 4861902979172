import { message } from "antd";

export function useMessage() {
  const [messageApi, contextHolder] = message.useMessage();

  const success = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const error = (message?: string) => {
    messageApi.open({
      type: "error",
      content: message ?? "Whoops! Please try again.",
    });
  };

  const warn = (message: string) => {
    messageApi.open({
      type: "warning",
      content: message,
    });
  };

  return {
    messageSuccess: success,
    messageWarn: warn,
    notification: contextHolder,
    messageError: error,
  };
}
