export default function ErrorPanel({
  message,
  bold,
}: {
  message?: string;
  bold?: boolean;
}) {
  return (
    <div style={{ minHeight: 20, marginTop: 10 }}>
      {message && (
        <span
          className={`errorMsg ${bold ? "boldText" : ""}`}
          style={{ fontSize: "small" }}
        >
          {message}
        </span>
      )}
    </div>
  );
}
