import { PlusCircleTwoTone } from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Flex,
  Input,
  Popconfirm,
  Select,
  Spin,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteStockRequest } from "../../../../api/api.delete";
import {
  useGetStockRequestsLifecycle,
  useGetSupplyChainStockRequestsById,
} from "../../../../api/api.get";
import { useUpdateStockRequest } from "../../../../api/api.update";
import BreadCrumbComponent from "../../../../components/breadcrumb.component";
import { useNotifications2 } from "../../../../components/notifications/notification";
import { ProductQuantityRowItem } from "../../../../components/product-quantity.component";
import { SuccessPillWithColor } from "../../../../components/success-pill";
import { useProfile } from "../../../../hooks/useProfile";
import {
  IProduct,
  IProductQuanityRowItem,
  STOCKREQUEST_STATUS,
} from "../../../../types/types";
import { capitalize } from "../../../../utils/functions";
import { getStockLifecycleOptions } from "../../../../utils/stock.utils";
const { Title } = Typography;

export default function ViewStockRequestPage() {
  const [stockRowItems, setStockRowItems] = useState<IProductQuanityRowItem[]>(
    [],
  );

  const navigate = useNavigate();
  const { id, sid } = useParams();
  const {
    isMerchant,
    isVendor,
    isLoading: isProfileLoading,
    profile,
  } = useProfile();

  const { data: stockRequestData, isLoading: isDataLoading } =
    useGetSupplyChainStockRequestsById(id, sid);

  const { data: lifecycleData, isLoading: isLifecycleDataLoading } =
    useGetStockRequestsLifecycle(id, sid);

  const [statusOptions, setStatusOptions] = useState<any[]>([]);

  const { notification, notifyError, notifySuccess } = useNotifications2();
  const { mutateAsync } = useUpdateStockRequest(id, sid);
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const { mutateAsync: deleteAsync } = useDeleteStockRequest(id!!);

  const canEdit = useMemo(() => {
    if (
      !isProfileLoading &&
      stockRequestData?.status &&
      isMerchant &&
      stockRequestData?.status === STOCKREQUEST_STATUS.SAVED
    ) {
      return true;
    }
    return false;
  }, [isMerchant, isProfileLoading, stockRequestData?.status]);

  const canAddStatus = useMemo(() => {
    if (!isProfileLoading && stockRequestData?.status) {
      if (isMerchant) {
        return (
          stockRequestData?.status === STOCKREQUEST_STATUS.SAVED ||
          stockRequestData?.status === STOCKREQUEST_STATUS.ON_ROUTE
        );
      }
      if (isVendor) {
        return (
          stockRequestData?.status === STOCKREQUEST_STATUS.PROCESSING ||
          stockRequestData?.status === STOCKREQUEST_STATUS.ON_ROUTE ||
          stockRequestData?.status === STOCKREQUEST_STATUS.BLOCKED ||
          stockRequestData?.status === STOCKREQUEST_STATUS.DELAYED ||
          stockRequestData?.status === STOCKREQUEST_STATUS.SUBMITTED
        );
      }
      return false;
    }
    return false;
  }, [isMerchant, isVendor, isProfileLoading, stockRequestData?.status]);

  console.log("canadstatus", canAddStatus, canEdit);
  useEffect(() => {
    const options = getStockLifecycleOptions(
      isMerchant,
      isVendor,
      stockRequestData?.status,
    );
    setStatusOptions(options);
  }, [isMerchant, isVendor, stockRequestData]);

  const [notes, setNotes] = useState<string>("");

  const handleUpdate = () => {
    mutateAsync({
      notes,
      items: stockRowItems,
      status: selectedStatus,
    })
      .then(() => {
        setNotes("");
        setSelectedStatus("");
        notifySuccess({
          title: "Success",
          description: "Stock request updated successfully.",
        });
      })
      .catch((err) => {
        notifyError(
          err.response?.data?.message ??
            "Whoops! Unable to submit request. Please try after sometime.",
        );
      });
  };

  const handleDelete = () => {
    deleteAsync({ stockReqId: sid })
      .then(() => {
        navigate(`/organizations/${id}/stocks`);
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (stockRequestData) {
      setStockRowItems(stockRequestData.items);
    }
  }, [stockRequestData]);

  const handleAddStockRowItem = () => {
    setStockRowItems([...stockRowItems, { product: null, quantity: 0 }]);
  };

  const handleRemoveItem = (index: number) => {
    const _items = [...stockRowItems];
    _items.splice(index, 1);
    setStockRowItems([..._items]);
  };

  const handleOnQuantityChange = (index: number, quantity: number) => {
    const items = [...stockRowItems];
    const item = items[index];
    const newItem = {
      ...item,
      quantity,
    };
    items.splice(index, 1, newItem);
    setStockRowItems(items);
  };

  const handleOnProductChange = (index: number, product: IProduct) => {
    const items = [...stockRowItems];
    const item = items[index];
    const newItem = {
      ...item,
      product,
    };
    items.splice(index, 1, newItem);
    setStockRowItems(items);
  };

  const columns = [
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => (
        <span>{moment(value).format("DD MMM YYYY hh:mm A")}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
  ];

  if (isDataLoading) {
    return (
      <div className="divCenterAlign" style={{ height: 400 }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="p-20">
      <BreadCrumbComponent
        items={[
          {
            title: "Home",
            url: `/organizations/${id}`,
          },
          {
            title: "Stocks",
            url: `/organizations/${id}/stocks`,
          },
        ]}
      />
      <div style={{ marginBottom: 100 }} className="card divColumn">
        {notification}
        <div className="divSpread divAlignItemsOnly">
          <Title level={3}>Stock Request</Title>
        </div>

        <div className="divRight">
          <Flex gap={10}>
            <Select
              style={{ width: 150 }}
              disabled={!canAddStatus}
              options={statusOptions}
              value={selectedStatus}
              onSelect={(value) => setSelectedStatus(value)}
            />

            <Popconfirm
              title={"Are you sure?"}
              onConfirm={() => handleUpdate()}
            >
              <Button type="primary" disabled={!selectedStatus}>
                Submit
              </Button>
            </Popconfirm>
          </Flex>
        </div>

        <Flex gap={20} vertical className="my-40">
          <Descriptions
            bordered
            items={[
              {
                key: "1",
                label: "Created Date ",
                children: moment(stockRequestData?.createdAt).format(
                  "DD MMM YYYY",
                ),
              },
              {
                key: "2",
                label: "Vendor",
                children: <>{stockRequestData?.vendorId}</>,
              },
              {
                key: "3",
                label: "Status",
                children: stockRequestData?.status,
              },
            ]}
          />

          <SuccessPillWithColor
            title={capitalize(stockRequestData?.status)}
            color={getStatusColor(stockRequestData?.status)}
            fontColor={getBorderColor(stockRequestData?.status)}
            borderColor={getBorderColor(stockRequestData?.status)}
          />

          <Title level={4}>Items</Title>

          <Flex gap={20} vertical className="mb-40">
            <Flex gap={20} vertical>
              {stockRowItems.map(
                (item: IProductQuanityRowItem, index: number) => {
                  return (
                    <ProductQuantityRowItem
                      size="middle"
                      disabled={!canEdit}
                      onAddProduct={() => {}}
                      onLoading={() => {}}
                      key={index}
                      item={item}
                      index={index}
                      onRemoveItem={(key) => handleRemoveItem(key)}
                      onQuantityChange={handleOnQuantityChange}
                      onProductChange={handleOnProductChange}
                      onProductFocussedOut={() => {}}
                    />
                  );
                },
              )}
            </Flex>

            <Button
              icon={<PlusCircleTwoTone />}
              disabled={!canEdit}
              onClick={() => handleAddStockRowItem()}
            ></Button>
          </Flex>

          <Flex gap={10} vertical>
            <Input.TextArea
              placeholder="Notes..."
              rows={5}
              disabled={!canAddStatus}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Flex>
        </Flex>

        <Table
          loading={isLifecycleDataLoading}
          dataSource={lifecycleData}
          columns={columns}
        />

        {stockRequestData?.username === profile?.username && (
          <Flex className="mt-80 divCenterAlign">
            <Popconfirm
              title="Delete"
              description="Are you sure you want to delete?"
              onConfirm={() => handleDelete()}
              okText="Yes"
              cancelText="No"
            >
              <Button danger type="text" color="danger">
                Delete Stock Request
              </Button>
            </Popconfirm>
          </Flex>
        )}
      </div>
    </div>
  );
}

function getStatusColor(status?: string) {
  if (status === STOCKREQUEST_STATUS.COMPLETED) {
    return "var(--exhut-light-green)";
  } else if (status === STOCKREQUEST_STATUS.BLOCKED) {
    return "var(--exhut-light-red)";
  } else if (status === STOCKREQUEST_STATUS.DELAYED) {
    return "coral";
  } else if (status === STOCKREQUEST_STATUS.CANCELLED) {
    return "lightgrey";
  } else if (status === STOCKREQUEST_STATUS.ON_ROUTE) {
    return "green";
  } else if (status === STOCKREQUEST_STATUS.SAVED) {
    return "lightgrey";
  } else if (status === STOCKREQUEST_STATUS.PROCESSING) {
    return "var(--exhut-ramar-blue)";
  } else {
    return "orange";
  }
}

function getBorderColor(status?: string) {
  if (status === STOCKREQUEST_STATUS.COMPLETED) {
    return "limegreen";
  } else if (status === STOCKREQUEST_STATUS.BLOCKED) {
    return "darkslategrey";
  } else if (status === STOCKREQUEST_STATUS.DELAYED) {
    return "white";
  } else if (status === STOCKREQUEST_STATUS.CANCELLED) {
    return "white";
  } else if (status === STOCKREQUEST_STATUS.ON_ROUTE) {
    return "white";
  } else if (status === STOCKREQUEST_STATUS.SAVED) {
    return "white";
  } else if (status === STOCKREQUEST_STATUS.PROCESSING) {
    return "white";
  } else {
    return "white";
  }
}
