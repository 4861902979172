import { Divider, Flex, Menu } from "antd";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { Link, useLocation } from "react-router-dom";
import * as animationData from "../animations/business-home-lottie.json";
import { useGetAllOrganizations } from "../api/api.get";
import OrganizationTile from "../components/tile.component";
import { capitalize } from "../utils/functions";
import FirstTimePage from "./home/first-time.page";
import { useLayoutContext } from "../providers/layout.provider";
import { MenuOutlined } from "@ant-design/icons";
import { EXHUT_BIZ_LOCAL_STORAGE_TOKEN } from "../utils/constants";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function HomePage() {
  const { state } = useLocation();

  const { animate } = state || {};

  const { isMobile } = useLayoutContext();

  const { data: profiles, isLoading: isProfileLoading } =
    useGetAllOrganizations();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (animate) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  }, [animate]);

  return (
    <div className="divColumn" id="home-body">
      {profiles && profiles.length > 0 && <LeftPaneComponent />}
      <div className={`mb-160 divFlex w-100`}>
        {(isLoading || isProfileLoading) && (
          <div className="divCenterAlign w-100">
            <Lottie options={defaultOptions} height={400} width={400} />
          </div>
        )}

        {!isLoading && (
          <>
            {profiles && profiles.length === 0 && <FirstTimePage />}

            {profiles && profiles.length > 0 && (
              <div className={isMobile ? "divColumn p-20" : "divSpread p-20"}>
                <div className="p-0">
                  {profiles.map((p, i) => {
                    return (
                      <OrganizationTile
                        key={i}
                        title={capitalize(p.org_name)}
                        id={p.org_id.toString()}
                        admin={p.type === "admin"}
                      />
                    );
                  })}
                </div>

                <div
                  className="p-20"
                  style={{
                    backgroundColor: "aliceblue",
                    borderRadius: 30,
                  }}
                >
                  <Flex className="divCenterAlign" gap={40} vertical>
                    <Flex gap={20} vertical>
                      <h2>Tasks</h2>
                      <strong>-</strong>
                    </Flex>
                    <Divider />

                    <Flex className="divCenterAlign" gap={20} vertical>
                      <h2>Next in line</h2>
                      <div className="divCenterAlign divColumn">
                        <img
                          src="/assets/icons/travelling.svg"
                          width={50}
                          alt="travelling"
                        />
                        <span className="my-20 infoMsg">
                          You're all caught up
                        </span>
                      </div>
                    </Flex>

                    <Divider />

                    <Flex className="divCenterAlign p-20" gap={20} vertical>
                      <h2>Useful Tips</h2>
                      <span>
                        {profiles?.[0]?.type === "admin" && (
                          <span>
                            Click on your organization to add orders, employees,
                            rewards and much more.{" "}
                          </span>
                        )}
                        {profiles?.[0]?.type !== "admin" && (
                          <span>
                            Click on your organization to add check your salary,
                            submit timereports and reimbursements.{" "}
                          </span>
                        )}
                      </span>
                    </Flex>
                  </Flex>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function LeftPaneComponent() {
  const items = [
    {
      key: "0",
      icon: (
        <div className="divRight w-100">
          <MenuOutlined style={{ cursor: "pointer" }} />
        </div>
      ),
      children: [
        {
          key: "1",
          icon: (
            <Link to={`/home`}>
              <img src={"/assets/icons/house.svg"} width={20} alt="home" />
            </Link>
          ),
          label: "Home",
        },
        {
          key: "2",
          icon: (
            <Link to={`/profile`}>
              <img src={"/assets/icons/man.svg"} width={20} alt="profile" />
            </Link>
          ),
          label: "Profile",
        },
        {
          key: "3",
          label: "Subscriptions",
          icon: (
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://hub.expensehut.com/subscriptions?exhuttoken=${localStorage.getItem(EXHUT_BIZ_LOCAL_STORAGE_TOKEN)}`}
            >
              <img
                src={"/assets/icons/credit-card.svg"}
                width={20}
                alt="settings"
              />
            </a>
          ),
        },
      ],
    },
  ];

  return (
    <Menu
      id="home-options-menu"
      style={{ width: 60, backgroundColor: "white", border: "1px solid white" }}
      mode="horizontal"
      items={items}
    />
  );
}
