import { notification } from "antd";
import { ReactNode } from "react";

interface IProps {
  title?: string;
  subtitle?: string;
}

export function useNotifications(props?: IProps) {
  const [api, contextHolder] = notification.useNotification();

  const notifySuccess = () => {
    api.success({
      message: props?.title ?? "Success",
      description: props?.subtitle,
    });
  };

  const notifyError = (msg?: string) => {
    api.error({
      message: "Whoops!",
      description: msg ?? "Something went wrong. Please try again.",
    });
  };

  return { notifySuccess, notification: contextHolder, notifyError };
}

export function useNotifications2() {
  const [api, contextHolder] = notification.useNotification();

  const notifySuccess = ({
    title,
    description,
  }: {
    title: string;
    description: string | ReactNode;
  }) => {
    api.success({
      message: title ?? "Success",
      description,
    });
  };

  const notifyError = (msg?: string) => {
    api.error({
      message: "Whoops!",
      description: msg ?? "Something went wrong. Please try again.",
    });
  };

  return { notifySuccess, notification: contextHolder, notifyError };
}
