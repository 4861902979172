import { DeleteFilled } from "@ant-design/icons";
import {
  AutoComplete,
  AutoCompleteProps,
  Button,
  Flex,
  Input,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useSearchProducts } from "../api/api.get";
import { useCurrency } from "../hooks/useCurrency";
import { useLayoutContext } from "../providers/layout.provider";
import { IProduct, IProductQuanityRowItem } from "../types/types";
import {
  capitalize,
  capitalizeWithTrunc,
  getProductCurrencySymbol,
} from "../utils/functions";
import ErrorPanel from "./error.panel.component";
import { toFixedDisplay } from "../utils/number.utils";

export function ProductQuantityRowItem({
  size,
  index,
  item,
  onRemoveItem,
  onProductChange,
  onQuantityChange,
  disabled,
  onLoading,
  onAddProduct,
  onClearProduct,
  onProductFocussedOut,
}: {
  size?: "small" | "middle";
  onAddProduct: () => void;
  onClearProduct?: (index: number) => void;
  onLoading: (loading: boolean) => void;
  disabled?: boolean;
  showImage?: boolean;
  onQuantityChange: (index: number, quantity: number) => void;
  onProductChange: (index: number, product: IProduct) => void;
  onProductFocussedOut?: (index: number, text: string) => void;
  index: number;
  item: IProductQuanityRowItem;
  onRemoveItem: (key: number) => void;
}) {
  const { id } = useParams();
  const [servicesSearchText, setServicesSearchText] = useState<string>("");
  const [options, setOptions] = useState<AutoCompleteProps["options"]>([]);
  const [debouncedProductNameSearchText] = useDebounce(servicesSearchText, 300);
  const { isMobile } = useLayoutContext();

  const { data: productsByNameData, isLoading } = useSearchProducts(
    id,
    debouncedProductNameSearchText,
  );

  useEffect(() => {
    onLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (productsByNameData) {
      setOptions(
        productsByNameData.map((product: IProduct) => {
          return {
            ...product,
            value: product.title,
            label: product.title,
          };
        }),
      );
    }
  }, [productsByNameData, debouncedProductNameSearchText]);

  if (disabled) {
    return <DisabledProductQuantityRowItem item={item} />;
  }

  return (
    <Flex
      gap={20}
      className={"divColumn py-20 px-40 prod-quantity-row"}
      style={{
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "lightgrey",
        borderRadius: 5,
      }}
    >
      <div className="divRight">
        <Button
          type="text"
          size="large"
          icon={<DeleteFilled />}
          onClick={() => onRemoveItem(index)}
          style={{ color: disabled ? "darkgrey" : "lightslategrey" }}
          disabled={disabled}
          className="lightcoral-delete"
        ></Button>
      </div>

      <Flex gap={10} className={"divAlignItemsOnly"}>
        <Flex gap={5} vertical>
          <AutoComplete
            id={`product-quantity-name-${index}`}
            allowClear
            onClear={() => onClearProduct && onClearProduct(index)}
            status={
              productsByNameData?.length === 0 || !item.product
                ? "error"
                : undefined
            }
            options={options}
            defaultValue={capitalize(item.product?.title)}
            onBlur={() => {
              onProductFocussedOut &&
                onProductFocussedOut(index, servicesSearchText);
            }}
            onSelect={(p: any, option: any) => {
              setServicesSearchText(option.value);
              onProductChange(index, option);
            }}
            onSearch={(text) => setServicesSearchText(text)}
            notFoundContent={
              <div>
                {isLoading && <Spin size="small" />}
                {!isLoading && (
                  <div>
                    No products found.
                    <Button onClick={() => onAddProduct()} type="link">
                      Add Product
                    </Button>
                  </div>
                )}
              </div>
            }
            style={{ borderRadius: 10 }}
            disabled={disabled}
          >
            <Input
              style={{
                fontSize: size === "small" ? 15 : size === "middle" ? 18 : 20,
              }}
              size={size}
              placeholder="Search by name..."
            />
          </AutoComplete>

          <div style={{ height: 20, marginTop: 8 }}>
            {(productsByNameData?.length === 0 || !item.product) && (
              <div className="p-5">
                <ErrorPanel message="Product not found." />
              </div>
            )}
          </div>
        </Flex>
      </Flex>

      <Flex vertical gap={60}>
        <Flex gap={20} className={isMobile ? "" : "divAlignItemsOnly"}>
          <Flex gap={5} vertical>
            <Flex gap={5} className="divAlignItemsOnly">
              Qty:
              <Input
                id={`product-quantity-${index}`}
                size={size}
                maxLength={7}
                style={{ width: 100 }}
                placeholder="Quantity"
                defaultValue={item.quantity}
                onChange={(e) => onQuantityChange(index, +e.target.value)}
                disabled={disabled}
                status={
                  !item.quantity ||
                  item.quantity <= 0 ||
                  item.quantity % 1 !== 0
                    ? "error"
                    : undefined
                }
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex vertical>
          {item.product && (
            <strong style={{ fontSize: "x-large", color: "black" }}>
              {getProductCurrencySymbol(item.product?.price?.currency)}
              {""}
              {toFixedDisplay(item.product?.price?.value)}
            </strong>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

function DisabledProductQuantityRowItem({
  item,
}: {
  item: IProductQuanityRowItem;
}) {
  const { isMobile } = useLayoutContext();
  const { toAmountDisplay } = useCurrency();

  return (
    <Flex
      style={{
        height: isMobile ? 200 : "auto",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "lightgrey",
        borderRadius: 5,
      }}
      className={
        isMobile
          ? "p-20 divColumn divSpread"
          : "divSpread divAlignItemsOnly p-20"
      }
    >
      <Flex gap={10} className={"divAlignItemsOnly"} style={{ flex: 2 }}>
        <strong>{capitalizeWithTrunc(item.product?.title, 50)}</strong>
      </Flex>

      <Flex
        className={isMobile ? "" : "divAlignItemsOnly"}
        style={{ flex: 0.5 }}
      >
        <span>Qty: {item.quantity}</span>
      </Flex>

      <Flex vertical style={{ flex: 0.5, backgroundColor: "" }}>
        {item.product && (
          <div
            className={isMobile ? "my-20 divColumn" : "mx-0 divRightTextAlign"}
          >
            <strong style={{ fontSize: 20, backgroundColor: "" }}>
              {toAmountDisplay(item.product?.price?.value)}
            </strong>
          </div>
        )}
      </Flex>
    </Flex>
  );
}
