import {
  LoadingOutlined,
  MinusCircleFilled,
  RightCircleFilled,
  StarFilled,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Descriptions,
  Divider,
  Flex,
  Input,
  List,
  Popconfirm,
  Skeleton,
  Spin,
  Tooltip,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useProcessReturn } from "../../../../api/api";
import {
  useGetOrganizationSalesById,
  useGetReturnEligibleProducts,
  useGetSalesReturns,
} from "../../../../api/api.get";
import { useNotifications2 } from "../../../../components/notifications/notification";
import { useCurrency } from "../../../../hooks/useCurrency";
import { IProduct } from "../../../../types/types";
import { toReadableDate } from "../../../../utils/date.utils";
import { toFixedDisplay } from "../../../../utils/number.utils";

export default function ReturnsPage() {
  const { id, sid } = useParams();

  const [items, setItems] = useState<IProduct[]>([]);
  const [refundableProducts, setRefundableProducts] = useState<IProduct[]>([]);

  const [notes, setNotes] = useState<string>("");
  const { data: salesData, isLoading } = useGetOrganizationSalesById(id, sid);
  const { data: returnsData, isLoading: isReturnsLoading } = useGetSalesReturns(
    id,
    sid,
  );
  const {
    data: returnEligibleProducts,
    isLoading: isReturnEligibleProductsLoading,
  } = useGetReturnEligibleProducts(id, sid);
  const { toAmountDisplay, currency } = useCurrency();
  const { notification, notifyError, notifySuccess } = useNotifications2();
  const { mutateAsync, isPending } = useProcessReturn(id, sid);

  useEffect(() => {
    if (salesData?.products) {
      setItems(salesData.products);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesData?.products]);

  const handleAddToItems = (item: IProduct, index: number) => {
    const _items = [...items];
    _items.push(item);
    setItems(_items);
  };

  const handleRemoveItems = (index: number) => {
    const _items = [...items];
    _items.splice(index, 1);
    setItems(_items);
  };

  const handleAddtoRefundableProducts = (item: IProduct, index: number) => {
    const _items = new Set(refundableProducts);
    _items.add(item);
    setRefundableProducts(Array.from(_items));
    handleRemoveItems(index);
  };

  const handleRemoveFromRefundableProducts = (
    item: IProduct,
    index: number,
  ) => {
    const _items = new Set(refundableProducts);
    _items.delete(item);
    setRefundableProducts(Array.from(_items));
    handleAddToItems(item, index);
  };

  const refundableItemTotal: number = refundableProducts.reduce(
    (acc, item) => acc + (item.price?.value ?? 0),
    0,
  );

  /**
   * This is a super critical function
   *
   */
  const refundableGrossTotal = useMemo(() => {
    // items can have sales tax excluded
    const taxPercent = salesData?.tax ?? 0;

    const individualItemDiscount =
      (salesData?.discountTotal ?? 0) / (salesData?.products ?? []).length;

    return refundableProducts.reduce((acc, product) => {
      const itemPrice = (product.price?.value ?? 0) - individualItemDiscount;

      if (product.salesTaxExcluded === true) {
        return acc + itemPrice;
      }
      return acc + (itemPrice + (itemPrice * taxPercent) / 100);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundableProducts, salesData?.products]);

  const taxRefundTotal = useMemo(() => {
    const taxPercent = salesData?.tax ?? 0;

    const individualItemDiscount =
      (salesData?.discountTotal ?? 0) / (salesData?.products ?? []).length;

    return refundableProducts.reduce((acc, product) => {
      const itemPrice = (product.price?.value ?? 0) - individualItemDiscount;

      if (product.salesTaxExcluded === true) {
        return acc;
      }
      return acc + (itemPrice * taxPercent) / 100;
    }, 0);
  }, [refundableProducts, salesData]);

  const discountRefund = useMemo(() => {
    return (
      ((salesData?.discountTotal ?? 0) / (salesData?.products ?? []).length) *
      refundableProducts.length
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundableProducts, salesData?.products]);

  const handleProcessReturn = () => {
    mutateAsync({
      discountTotal: discountRefund,
      grossTotal: refundableGrossTotal,
      itemsTotal: refundableItemTotal,
      refundPaymentType: salesData?.payments?.[0]?.paymentType.toUpperCase(),
      notes,
      products: refundableProducts.map((product) => product._id),
    })
      .then(() => {
        notifySuccess({
          title: "Success",
          description: "Return successfully processed!",
        });
        setRefundableProducts([]);
      })
      .catch(() => notifyError());
  };

  const salesDataInfo = [
    {
      key: "1",
      label: "Items Total",
      children: toAmountDisplay(salesData?.amount),
    },
    {
      key: "2",
      label: "Discount Total",
      children: toAmountDisplay(salesData?.discountTotal),
    },
    {
      key: "3",
      label: "Total",
      children: toAmountDisplay(salesData?.total),
    },
    {
      key: "4",
      label: "Tax",
      children: `${salesData?.tax}%`,
    },
    {
      key: "5",
      label: "Date",
      children: toReadableDate(salesData?.created_at),
    },

    {
      key: "6",
      label: "Payment Type",
      children: salesData?.payments?.[0]?.paymentType,
    },
  ];

  return (
    <div className="divFlex p-20">
      {notification}

      <div className="flex-1 p-0 divSpread divColumn">
        <div className="" style={{ overflowY: "scroll", height: 600 }}>
          <Flex gap={10} className="p-20">
            <div>
              <strong>Customer Phone: </strong>

              <strong>{salesData?.payments?.[0]?.customerPhone}</strong>
            </div>
          </Flex>

          <Skeleton
            loading={
              isLoading || isReturnsLoading || isReturnEligibleProductsLoading
            }
          >
            <List
              itemLayout="horizontal"
              bordered
              dataSource={items}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src={item.cover} />}
                    title={item.title}
                    description={currency + item.price?.value}
                  />

                  <Flex gap={20}>
                    {item.salesTaxExcluded === true && (
                      <Tooltip title="No sales tax">
                        <StarFilled style={{ color: "orange" }} />
                      </Tooltip>
                    )}

                    <Tooltip
                      title={
                        returnEligibleProducts?.includes(item._id)
                          ? "Product already returned"
                          : ""
                      }
                    >
                      <Button
                        icon={<RightCircleFilled />}
                        disabled={returnEligibleProducts?.includes(item._id)}
                        onClick={() =>
                          handleAddtoRefundableProducts(item, index)
                        }
                      />
                    </Tooltip>
                  </Flex>
                </List.Item>
              )}
            />
          </Skeleton>

          <Divider />

          <Descriptions
            items={salesDataInfo}
            title="Order Details"
            bordered
            layout="vertical"
          />

          <div className="my-40">
            <Input.TextArea
              placeholder="Notes..."
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>

          <Flex gap={5} className="divColumn">
            <h4>Returns</h4>
            <List
              itemLayout="horizontal"
              bordered
              dataSource={returnsData}
              renderItem={(item, index) => (
                <div className="p-20 divSpread" key={index}>
                  <span>{item.id}</span>

                  <strong>{toReadableDate(item.createdAt)}</strong>

                  <strong>{toAmountDisplay(item.grossTotal)}</strong>
                </div>
              )}
            />
          </Flex>
        </div>
      </div>

      <Divider type="vertical" style={{ height: 600 }} />
      <div className="flex-1 divColumn divSpread p-20">
        <div>
          <div className="mb-20 divRight">
            <Popconfirm title="Are you sure?" onConfirm={handleProcessReturn}>
              <Button
                type="primary"
                disabled={refundableProducts.length === 0}
                loading={isPending}
              >
                Process Return
              </Button>
            </Popconfirm>
          </div>

          <Flex gap={5} className="divColumn">
            <List
              itemLayout="horizontal"
              bordered
              dataSource={refundableProducts}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src={item.cover} />}
                    title={item.title}
                    description={currency + item.price?.value}
                  />

                  <Flex gap={20}>
                    <MinusCircleFilled
                      style={{ fontSize: 20 }}
                      onClick={() =>
                        handleRemoveFromRefundableProducts(item, index)
                      }
                    />
                  </Flex>
                </List.Item>
              )}
            />
          </Flex>
        </div>

        <div
          className="w-100 divColumn right-align"
          style={{ marginRight: 20, marginBottom: 0 }}
        >
          <span className="my-20" style={{ fontSize: 20 }}>
            Items Total : <strong>{}</strong>
            <strong>{toFixedDisplay(refundableItemTotal)}</strong>
          </span>

          <span className="my-20" style={{ fontSize: 20 }}>
            Tax Refund : <strong>{toFixedDisplay(taxRefundTotal ?? 0)}</strong>
          </span>

          <span className="my-20" style={{ fontSize: 20 }}>
            Discount Refund :{" "}
            <strong>{toFixedDisplay(discountRefund ?? 0)}</strong>
          </span>

          <span style={{ fontSize: 65, fontWeight: "bold" }}>
            {toAmountDisplay(refundableGrossTotal)}
          </span>
          {isLoading && (
            <Spin indicator={<LoadingOutlined spin />} size="large" />
          )}
        </div>
      </div>
    </div>
  );
}
