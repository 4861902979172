import { EyeOutlined } from "@ant-design/icons";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { Button, Flex, Input } from "antd";
import Alert from "antd/es/alert/Alert";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { useAuthenticate, useAuthenticateWithSSO } from "../api/api";
import ErrorPanel from "../components/error.panel.component";
import LinkComponent from "../components/link.component";
import { useMessage } from "../components/notifications/message";
import TermsConditionsComponent from "../components/other/TermsConditionsComponent";
import { useAuthContext } from "../providers/auth.provider";
import { useLayoutContext } from "../providers/layout.provider";
import {
  EXHUT_BIZ_LOCAL_STORAGE_TOKEN,
  EXHUT_BIZ_REDIRECT,
  FeatureFlags,
  ROUTES,
} from "../utils/constants";
import { downloadAndLoadGSIScript } from "../utils/lib.helper";

export default function LoginPage() {
  const { mutateAsync, isPending: isLoading, isError } = useAuthenticate();
  const { mutateAsync: loginWithSSO } = useAuthenticateWithSSO();
  const { isMobile } = useLayoutContext();
  const { setUser } = useAuthContext();
  const { notification, messageError } = useMessage();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isRegisterSuccess = new URLSearchParams(searchParams).get("register");
  const isEnabled = useFeatureIsOn(FeatureFlags.ENABLE_BUSINESS);

  const handleDocumentClick = (event: any) => {
    if (event.keyCode === 13) {
      formik.submitForm();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleDocumentClick, false);
    return () => {
      document.removeEventListener("keydown", handleDocumentClick, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    downloadAndLoadGSIScript()
      .then(() => {
        (window as any).google.accounts.id.initialize({
          client_id: process.env.REACT_APP_EXHUT_GOOGLE_CLIENT_ID,
          callback: (response: any) => {
            loginWithSSO({ token: response.credential, type: "google" })
              .then((resp) => {
                localStorage.setItem(EXHUT_BIZ_LOCAL_STORAGE_TOKEN, resp.sign);
                const redirect = localStorage.getItem(EXHUT_BIZ_REDIRECT);
                localStorage.removeItem(EXHUT_BIZ_REDIRECT);
                navigate(redirect ?? ROUTES.HOME, { state: { animate: true } });
              })
              .catch(() => {
                messageError("Error signing in. Please try again.");
              });
          },
        });
        (window as any).google.accounts.id.renderButton(
          document.querySelector(".sso-signin"),
          { theme: "dark", size: "medium" }, // customization attributes
        );
      })
      .catch((err) => console.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginWithSSO, navigate]);

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please enter password."),
      email: Yup.string().required("Please enter email."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      mutateAsync({ ...values, email: values.email.toLowerCase() })
        .then((resp) => {
          localStorage.setItem(EXHUT_BIZ_LOCAL_STORAGE_TOKEN, resp.sign);
          const redirect = localStorage.getItem(EXHUT_BIZ_REDIRECT);
          // localStorage.removeItem(EXHUT_BIZ_REDIRECT);
          setUser(resp);
          navigate(redirect ?? ROUTES.HOME, { state: { animate: true } });
        })
        .catch((e) => {
          messageError(
            "The email or password you have entered is incorrect. Please try again.",
          );
        });
    },
  });

  return (
    <div className={`splitright ${isMobile ? "mobile-splitright" : ""}`}>
      {notification}
      <div
        className={`p-0 ${isMobile ? "divColumn" : "divFlex my-0"}`}
        style={{ backgroundColor: "lightgrey" }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <div className="p-10">
            {isError && <span className="divCenterAlign errorMsg"></span>}
            {isLoading && <div className="divCenterAlign"></div>}
          </div>

          <div className="divRight mb-20 mx-40">
            <LinkComponent title="Register" path="/register" />
          </div>

          <div
            style={{
              borderRadius: 10,
              flex: 1,
            }}
          >
            {isEnabled && (
              <form
                onSubmit={formik.handleSubmit}
                className="divAlignItemsOnly divColumn px-20 pb-40"
              >
                {isRegisterSuccess === "success" && (
                  <Alert
                    type="success"
                    message="We have sent a verification link to your email. Please follow the link to complete registration."
                  />
                )}
                {isRegisterSuccess === "complete" && (
                  <Alert
                    type="success"
                    message="We have successfully verified your account. Please login."
                  />
                )}

                <Flex vertical gap={0}>
                  <strong className="font-larger my-10">Email</strong>

                  <Input
                    style={{ width: 240 }}
                    {...formik.getFieldProps("email")}
                  />
                  <ErrorPanel message={formik.errors.email} />
                </Flex>

                <Flex vertical gap={0}>
                  <strong className="font-larger my-10">Password</strong>

                  <Input
                    style={{
                      width: 250,
                      backgroundColor: "white",
                      borderRadius: 5,
                    }}
                    type={showPassword ? "text" : "password"}
                    addonAfter={
                      <EyeOutlined
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    }
                    {...formik.getFieldProps("password")}
                  />
                  <ErrorPanel message={formik.errors.password} />
                </Flex>

                <div>
                  <div className="divColumn">
                    <Button
                      type="primary"
                      loading={isLoading}
                      onClick={formik.submitForm}
                      id="login-button"
                    >
                      Sign In
                    </Button>

                    <div className="sso-signin my-20"></div>

                    <div className="divCenterAlign mt-10 mb-20">
                      <a
                        href="https://hub.expensehut.com/forgot-password"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Forgot Password
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            )}

            {!isEnabled && (
              <div className="my-20 divCenterAlign">
                <h2>Coming Soon!</h2>
              </div>
            )}
          </div>
        </div>
      </div>

      <TermsConditionsComponent />
    </div>
  );
}
