import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePostLogout } from "../api/api";
import { useGetSession } from "../api/api.get";
import { IUser } from "../types/types";
import {
  EXHUT_BIZ_LOCAL_STORAGE_TOKEN,
  EXHUT_BIZ_REDIRECT,
} from "../utils/constants";
import { matchPublicRoutes } from "../utils/functions";
export interface IAuthContext {
  user: IUser | null | undefined;
  setUser: (user: IUser | null) => void;
  signout: (timeout?: boolean) => void;
}

const AuthContext = createContext<IAuthContext>({
  user: null,
  setUser: () => {},
  signout: () => {},
});

export const useAuthContext = () => useContext(AuthContext);

export default function AuthProvider(props: PropsWithChildren) {
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tokenInQuery = queryParams.get("exhuttoken");
  const shouldSession =
    !matchPublicRoutes(pathname) && !queryParams.get("logout");

  const { data: sessionData, isError: sessionError } = useGetSession(
    shouldSession || !!tokenInQuery,
  );
  const { mutateAsync: logoutAsync } = usePostLogout();
  const [user, setUser] = useState<IUser | null>();
  const navigate = useNavigate();

  useEffect(() => {
    if (tokenInQuery) {
      const redirect = localStorage.getItem(EXHUT_BIZ_REDIRECT);
      navigate(redirect ?? "/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenInQuery]);

  useEffect(() => {
    if (sessionError) {
      setUser(null);
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionError]);

  useEffect(() => {
    if (sessionData) {
      setUser(sessionData as any);
    }
  }, [sessionData]);

  const signout = () => {
    localStorage.removeItem(EXHUT_BIZ_REDIRECT);
    logoutAsync()
      .then(() => {
        setUser(null);
        localStorage.removeItem(EXHUT_BIZ_LOCAL_STORAGE_TOKEN);
        navigate("/");
      })
      .catch(console.error);
  };

  return (
    <AuthContext.Provider value={{ user, setUser, signout }}>
      {props.children}
    </AuthContext.Provider>
  );
}
