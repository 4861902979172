import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Alert, Spin } from "antd";
import { useEffect, useState } from "react";

export default function NoAccessPage({ loading }: { loading?: boolean }) {
  const [_loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  }, []);
  return (
    <div id="no-access-page" className="w-100" style={{ height: 600 }}>
      <div className="divCenterAlign" style={{ height: 200 }}>
        {(loading || _loading) && (
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        )}
        {!loading && !_loading && (
          <Alert
            banner
            icon={<InfoCircleOutlined />}
            type="error"
            message="Sorry! You don't have access to this page."
          />
        )}
      </div>
    </div>
  );
}
