import { Button, List, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as animationData from "../../../animations/invoice-lottie.json";
import { useGetInvoices } from "../../../api/api.get";
import { useCurrency } from "../../../hooks/useCurrency";
import { toReadableDate } from "../../../utils/date.utils";
import { capitalize } from "../../../utils/functions";
import OrganizationDate from "../organization-date";

const { Title } = Typography;
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function InvoicesHomePage() {
  const { state } = useLocation();

  const { animate } = state || {};
  const { id } = useParams();
  const [date, setDate] = useState(moment());

  const { toAmount } = useCurrency();
  const navigate = useNavigate();

  const {
    data: invoicesData,
    isLoading: isFetching,
    refetch,
  } = useGetInvoices(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (animate) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [animate]);

  const handleDateChange = (dateString: string) => {
    setDate(moment(dateString, "MMM YYYY"));
  };

  useEffect(() => {
    if (date) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <div
      style={{ marginBottom: 100 }}
      className="body divColumn mx-40 my-40 flex-1"
    >
      {isLoading && (
        <div className="divCenterAlign divColumn w-100">
          <h2>Invoice instantly...</h2>
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      )}

      {!isLoading && (
        <div className="card p-40">
          <div className="divSpread divAlighItemsOnly">
            <OrganizationDate onChange={handleDateChange} />

            <Button
              type="link"
              onClick={() => navigate(`/organizations/${id}/invoices/create`)}
            >
              Create
            </Button>
          </div>
          <div className="divSpread divAlignItemsOnly mt-40">
            <Title level={3}>Invoices</Title>
          </div>
          <List
            loading={isFetching}
            dataSource={invoicesData}
            renderItem={(item, index) => (
              <List.Item extra={toAmount(item.total)} key={index}>
                <List.Item.Meta
                  title={
                    <Link
                      style={{ color: "var(--exhut-blue)" }}
                      to={`/organizations/${id}/invoices/${item.id}`}
                    >
                      {capitalize(item.customer?.firstName)}
                    </Link>
                  }
                  description={toReadableDate(item.createdAt)}
                />
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );
}
