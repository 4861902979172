import { Alert, Button, Flex, Skeleton, Table } from "antd";
import { useMemo, useRef } from "react";
import generatePDF from "react-to-pdf";

import { useLocation } from "react-router-dom";
import { useGetInvoicePublicData } from "../../../api/api.get";
import { IProductQuanityRowItem } from "../../../types/types";
import { toReadableDate } from "../../../utils/date.utils";
import {
  capitalize,
  getCurrencySymbol,
  getProductCurrencySymbol,
} from "../../../utils/functions";

export default function InvoiceReceiptPublicView() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const externalId = queryParams.get("id");
  const contentRef = useRef(null);

  const {
    data: invoiceReceiptData,
    error,
    isLoading,
  } = useGetInvoicePublicData(externalId);

  const handleDownload = () =>
    generatePDF(contentRef, { filename: `receipt_${externalId}.pdf` });

  const columns = [
    {
      title: "Item",
      key: "title",
      render: (row: IProductQuanityRowItem) => (
        <span>{capitalize(row.product?.title)}</span>
      ),
    },
    {
      title: "Quantity",
      key: "quantity",
      render: (row: IProductQuanityRowItem) => <span>{row.quantity}</span>,
    },
    {
      title: "Price",
      render: (row: IProductQuanityRowItem) => (
        <span>
          {getProductCurrencySymbol(row.product?.price?.currency)}
          {row.product?.price?.value}
        </span>
      ),
    },
  ];
  const netTotal = useMemo(() => {
    return invoiceReceiptData?.invoice.items.reduce(
      (acc, item) => acc + (item.product?.price?.value ?? 0) * item.quantity,
      0,
    );
  }, [invoiceReceiptData?.invoice.items]);

  return (
    <div>
      {error && (
        <div style={{ minHeight: 300 }} className="divCenterAlign">
          <Alert showIcon type="error" message="Whoops! Receipt not found." />
        </div>
      )}
      {invoiceReceiptData && (
        <Skeleton loading={isLoading}>
          <Flex
            gap={10}
            vertical
            style={{ padding: "40px 40px" }}
            ref={contentRef}
          >
            <div className="divRight mb-0">
              <Button
                icon={
                  <img
                    src="/assets/icons/save.svg"
                    width={25}
                    height={25}
                    alt="save"
                  />
                }
                type="text"
                onClick={() => handleDownload()}
              ></Button>
            </div>
            <div>
              <div className="divSpread divAlignItemsOnly">
                <h2>{"RECEIPT"}</h2>
                <div className="">#{externalId}</div>
              </div>

              <div className="my-10 divSpread">
                <div className="divColumn">
                  <strong>
                    {capitalize(invoiceReceiptData?.organization?.name)}
                  </strong>
                  <span>{invoiceReceiptData?.organization?.street1}</span>
                  <span>{invoiceReceiptData?.organization?.street2}</span>
                  <span>{invoiceReceiptData?.organization?.city}</span>
                  <span>
                    {invoiceReceiptData?.organization?.state}{" "}
                    {invoiceReceiptData?.organization?.country}{" "}
                    {invoiceReceiptData?.organization?.zipCode}
                  </span>
                </div>

                <span>
                  {toReadableDate(invoiceReceiptData.invoice.createdAt)}
                </span>
              </div>
            </div>

            <hr style={{ border: "1 solid black" }} />

            <div>
              <h4> CUSTOMER</h4>
              <div>
                <div className="my-10">
                  <div className="divColumn">
                    {(invoiceReceiptData?.invoice?.customer?.firstName ||
                      invoiceReceiptData?.invoice?.customer?.lastName) && (
                      <strong>
                        {capitalize(
                          invoiceReceiptData.invoice?.customer?.firstName,
                        )}{" "}
                        {capitalize(
                          invoiceReceiptData.invoice?.customer?.lastName,
                        )}
                      </strong>
                    )}

                    <span>
                      {capitalize(
                        invoiceReceiptData?.invoice?.customer?.address?.street1,
                      )}
                    </span>
                    <span>
                      {capitalize(
                        invoiceReceiptData?.invoice?.customer?.address?.street2,
                      )}
                    </span>
                    <span>
                      {capitalize(
                        invoiceReceiptData?.invoice?.customer?.address?.city,
                      )}
                    </span>
                    <span>
                      {invoiceReceiptData?.invoice?.customer?.address
                        ?.state && (
                        <span>
                          {capitalize(
                            invoiceReceiptData.invoice?.customer?.address?.state?.toUpperCase(),
                          )}
                          ,{" "}
                          {capitalize(
                            invoiceReceiptData.invoice?.customer?.address
                              ?.countryCode,
                          )}
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="my-5">
                <div>
                  <strong>Email</strong>
                  <span className="mx-10">
                    {invoiceReceiptData?.invoice?.customer?.email}
                  </span>
                </div>

                <div>
                  <strong>Phone</strong>
                  <span className="mx-10">
                    {invoiceReceiptData?.invoice?.customer?.phone}
                  </span>
                </div>
              </div>
            </div>
            <hr style={{ border: "1 solid black" }} />

            <div className="my-0">
              <Table
                bordered
                dataSource={invoiceReceiptData?.invoice?.items}
                columns={columns}
                pagination={{ hideOnSinglePage: true }}
              />
            </div>

            <Flex gap={20} vertical className="divRight my-20">
              <div className="divRight">
                <strong>Net Total: </strong>
                <span className="mx-10">
                  {getCurrencySymbol(
                    invoiceReceiptData?.organization?.country,
                  ) + netTotal}
                </span>
              </div>
              <div className="divRight">
                <strong>Tax: </strong>
                <span className="mx-10">
                  {invoiceReceiptData?.invoice?.tax}%
                </span>
              </div>

              <div className="divRight divAlignItemsOnly">
                <strong>
                  Total:{" "}
                  {getCurrencySymbol(
                    invoiceReceiptData?.organization?.country,
                  ) + invoiceReceiptData?.invoice?.total}{" "}
                </strong>
              </div>
            </Flex>
            <hr style={{ border: "1 solid black" }} />

            <div className="divSpread">
              <span className="infoMsg">
                {toReadableDate(invoiceReceiptData.paymentDate)}
              </span>
              <strong>
                Paid:{" "}
                {getCurrencySymbol(invoiceReceiptData?.organization?.country) +
                  (invoiceReceiptData?.paymentTotal ?? 0)}
              </strong>
            </div>
          </Flex>
        </Skeleton>
      )}
    </div>
  );
}
