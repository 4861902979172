import { PropsWithChildren, createContext, useContext, useState } from "react";

interface IFullViewContext {
  fullView: boolean;
  setFullView: (value: boolean) => void;
}

export const FullViewContext = createContext<IFullViewContext>({
  fullView: false,
  setFullView: () => {},
});

export const useFullViewContext = () => useContext(FullViewContext);

export default function FullViewProvider({ children }: PropsWithChildren<{}>) {
  const [fullView, _setFullView] = useState<boolean>(false);

  const setFullView = (value: boolean) => _setFullView(value);

  return (
    <FullViewContext.Provider value={{ fullView, setFullView }}>
      {children}
    </FullViewContext.Provider>
  );
}
