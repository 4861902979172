import {
  Button,
  Flex,
  Image,
  Input,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useGetOrganizationById, useGetOrgSetting } from "../../../api/api.get";
import { useUpdateOrgazanizationSettings } from "../../../api/api.update";

import { DeleteFilled, UploadOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { InfoIcon } from "../../../components/icon.component";
import { useNotifications } from "../../../components/notifications/notification";
import { getBase64 } from "../../../utils/file.utils";
import { capitalize } from "../../../utils/functions";

export default function InvoiceSettingsPage() {
  const { id } = useParams();
  const { mutateAsync, isPending } = useUpdateOrgazanizationSettings(id);

  const { data: orgData } = useGetOrganizationById(id);
  const { data: orgSettingsData } = useGetOrgSetting(id);

  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Organization successfully updated",
  });

  const handleRemoveFooterImage = async () => {
    mutateAsync({ invoiceFooterImage: null })
      .then(() => {
        notifySuccess();
      })
      .catch(() => notifyError());
  };

  const formik = useFormik({
    initialValues: {
      invoiceFooter: orgSettingsData?.invoiceFooter ?? "",
      incomeTaxImage: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      invoiceFooter: Yup.string(),
      incomeTax: Yup.number(),
    }),
    onSubmit: async (values) => {
      try {
        let imgData = await getBase64(fileList[0]);
        await mutateAsync({
          invoiceFooter: values.invoiceFooter,
          invoiceFooterImage: imgData,
        });
        notifySuccess();
      } catch (err) {
        notifyError();
      } finally {
        formik.resetForm();
        setFileList([]);
      }
    },
  });

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file, fileList) => {
      const temp = [...fileList];
      setFileList(temp);
      return false;
    },
    fileList,
  };

  return (
    <div id="org-tax-settings-container" className="p-40 flex-1">
      {notification}
      <div className={`divCenterAlign divColumn p-32`}>
        <Content
          className="p-20"
          style={{ minWidth: "40%", boxShadow: "3px 1px 5px 5px #e6ddd6" }}
        >
          <div className="w-100">
            <div className="mb-80">
              <div className="title mb-10">Invoice Settings</div>
            </div>

            <div className="mt-80 mb-60">
              <form onSubmit={formik.handleSubmit}>
                <Flex vertical gap={40}>
                  <Flex vertical>
                    <h4>
                      Footer Content{" "}
                      <InfoIcon title="Can be used to show in invoices when shared to customers." />
                    </h4>
                    <div className="divColumn">
                      <Input.TextArea
                        allowClear
                        placeholder={`For ex: Please make payments to ${capitalize(orgData?.name)} via the QR code`}
                        maxLength={200}
                        {...formik.getFieldProps("invoiceFooter")}
                      />
                    </div>
                  </Flex>

                  <div className="">
                    <Flex gap={20} vertical>
                      <Upload
                        {...props}
                        multiple={true}
                        accept="image/png,image/jpeg"
                      >
                        <Button disabled={isPending} icon={<UploadOutlined />}>
                          Upload Image
                        </Button>
                      </Upload>

                      {orgSettingsData?.invoiceFooterImage && (
                        <Flex vertical gap={20} className="card divCenterAlign">
                          <div className="divRight">
                            <Button
                              danger
                              icon={<DeleteFilled />}
                              type="text"
                              onClick={() => handleRemoveFooterImage()}
                            ></Button>
                          </div>

                          <Image
                            src={orgSettingsData.invoiceFooterImage}
                            width={100}
                          />
                        </Flex>
                      )}
                    </Flex>
                  </div>

                  <Flex vertical gap={20}>
                    <Button
                      loading={isPending}
                      type="primary"
                      onClick={formik.submitForm}
                    >
                      Save
                    </Button>
                  </Flex>
                </Flex>
              </form>
            </div>
          </div>
        </Content>
      </div>
    </div>
  );
}
