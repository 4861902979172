export const getBase64 = function (file: any): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = function () {
        resolve(fileReader.result as string);
      };
      fileReader.onerror = function (error) {
        reject(error);
      };
    } else {
      return resolve(undefined);
    }
  });
};
