import { CloseCircleOutlined } from "@ant-design/icons";
import { Avatar, Button, Flex, Popconfirm, Table, Typography } from "antd";
import Search from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteProduct } from "../../../api/api.delete";
import { useGetProducts, useSearchProducts } from "../../../api/api.get";
import { PriceComponent } from "../../../components/price.component";
import { IAmount } from "../../../types/types";
import { getProductCurrencySymbol } from "../../../utils/functions";
import AddMultipleProductsModal from "./add-multiple-products-modal";
import AddProductModal from "./add-products-modal";

const { Title } = Typography;

export default function ProductsHomePage() {
  const { id } = useParams();
  const [searchText, setSearchText] = useState<string>("");
  const navigate = useNavigate();
  const { data: products, refetch, isLoading } = useGetProducts(id);

  const { data: searchProducts, refetch: refetchSearch } = useSearchProducts(
    id,
    searchText,
  );
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isAddMultiple, setIsAddMultiple] = useState<boolean>(false);

  const { mutateAsync: deleteAsync, isPending: isDeletePending } =
    useDeleteProduct(id!!);

  const handleDelete = (productId: string) => {
    deleteAsync({ productId })
      .then(() => refetch())
      .catch(console.error);
  };

  useEffect(() => {
    if (searchText) {
      refetchSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const columns = [
    {
      title: "",
      dataIndex: "cover",
      key: "cover",
      render: (value: string) => <Avatar src={value} />,
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price: IAmount) => (
        <PriceComponent
          prefix={getProductCurrencySymbol(price.currency)}
          price={price}
        />
      ),
    },
    {
      title: "Available Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
      render: (value: any) => <span>{value ?? "-"}</span>,
    },
    {
      title: "Sales Tax",
      dataIndex: "salesTaxExcluded",
      key: "salesTaxExcluded",
      width: 50,
      render: (value: boolean) => (
        <span>{!value ? null : <CloseCircleOutlined />}</span>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Delete"
          description="Are you sure to delete this product?"
          onConfirm={() => handleDelete(row._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger type="text" color="danger">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div
      style={{ marginBottom: 100 }}
      className="body divColumn mx-40 my-40 flex-1"
    >
      <AddProductModal open={isAdd} onCancel={() => setIsAdd(false)} />

      <AddMultipleProductsModal
        open={isAddMultiple}
        onCancel={() => setIsAddMultiple(false)}
      />

      <div className="divCenterAlign">
        <Title level={3}>Products</Title>
      </div>
      <div className="divSpread divAlignItemsOnly my-20">
        <Search
          placeholder="Search by name..."
          onSearch={(value) => setSearchText(value)}
          style={{ width: 200 }}
        />

        <Flex>
          <Button type="primary" onClick={() => setIsAdd(!isAdd)}>
            Add Product
          </Button>

          <Button type="link" onClick={() => setIsAddMultiple(!isAddMultiple)}>
            Add Multiple
          </Button>
        </Flex>
      </div>
      <Table
        loading={isLoading || isDeletePending}
        dataSource={searchText ? searchProducts : products}
        columns={columns}
        pagination={{ pageSize: 100 }}
        onRow={(row, rowIndex) => {
          return {
            onClick: (event) => {
              if (
                (event.target as HTMLElement).innerText !== "Delete" &&
                (event.target as HTMLElement).innerText !== "Yes" &&
                (event.target as HTMLElement).innerText !== "No"
              ) {
                return navigate(`/organizations/${id}/products/${row._id}`); // click row
              }
            },
          };
        }}
      />
    </div>
  );
}
