export function getReimbursementLabel(value: string) {
  if (value === "REJECTED") {
    return "Rejected";
  } else if (value === "CREATED") {
    return "Created";
  } else if (value === "MORE_INFO") {
    return "Needs More Info";
  } else if (value === "APPROVED") {
    return "Approved";
  } else if (value === "IN_REVIEW") {
    return "In Review";
  }
  return "";
}

export function getReimbursementActionOptions(value?: string) {
  if (value && (value === "CREATED" || value === "IN_REVIEW")) {
    return [
      { value: "REJECTED", label: "Rejected" },
      { value: "MORE_INFO", label: "Needs More Info" },
      { value: "APPROVED", label: "Approved" },
    ];
  }
  return [];
}

export const reimbursementCategories = [
  {
    value: "advertising",
    label: "Advertising",
  },
  {
    value: "bankfees",
    label: "Bank Fees",
  },
  {
    value: "books",
    label: "Books and magazine subscriptions",
  },
  {
    value: "meals",
    label: "Business Meals",
  },
  {
    value: "carrental",
    label: "Car Rental",
  },
  {
    value: "chairty",
    label: "Charitable Contributions",
  },
  {
    value: "clientgift",
    label: "Client Gifts",
  },

  {
    value: "depreciation",
    label: "Depreciation",
  },
  {
    value: "duessubscriptions",
    label: "Dues & Subscriptions",
  },

  {
    value: "employeeloan",
    label: "Employee  Loan",
  },
  {
    value: "salaryadvance",
    label: "Employee Salary Advance",
  },

  {
    value: "enrollments",
    label: "Enrollments",
  },
  {
    value: "fees",
    label: "Fees",
  },
  {
    value: "food",
    label: "Food",
  },
  {
    value: "furniture",
    label: "Furniture",
  },

  {
    value: "insurance",
    label: "Insurance",
  },
  {
    value: "legal",
    label: "Legal",
  },
  {
    value: "licensepermits",
    label: "License & Permits",
  },

  {
    value: "maintenance",
    label: "Maintenance & Repairs",
  },
  {
    value: "medical",
    label: "Medical",
  },
  {
    value: "moving",
    label: "Moving Expenses",
  },

  {
    value: "mortgageinterest",
    label: "Mortgage Interest",
  },
  {
    value: "other",
    label: "Other",
  },

  {
    value: "otherexpenses",
    label: "Other Professional Expenses",
  },
  {
    value: "officesupplies",
    label: "Office Supplies",
  },
  {
    value: "postageshipping",
    label: "Postage & Shipping",
  },
  {
    value: "printing",
    label: "Printing",
  },
  {
    value: "rent",
    label: "Rent",
  },
  {
    value: "salaries",
    label: "Salaries & Compensation",
  },
  {
    value: "hardware",
    label: "System Hardware",
  },

  {
    value: "software",
    label: "Software",
  },

  {
    value: "travel",
    label: "Travel",
  },

  {
    value: "Utilities",
    label: "Utilities",
  },
];
