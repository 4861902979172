import { UploadOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateProduct, useCreateProductImageLazy } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { useCurrency } from "../../../hooks/useCurrency";
import { IProduct } from "../../../types/types";
import { getBase64 } from "../../../utils/file.utils";

export default function AddProductsModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const [error, setError] = useState<string>();
  const { currency } = useCurrency();
  const { notification } = useNotifications();

  const { currencyCode } = useCurrency();

  const { mutateAsync, isPending } = useCreateProduct(id);
  const { mutateAsync: uploadImageAsync, isPending: isUploadImagePending } =
    useCreateProductImageLazy(id);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      price: "",
      saleDate: "",
      externalId: "",
      sku: "",
      quantity: "",
      salesTaxExcluded: false,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9 ]*$/, "Numbers or letters only")
        .required("Please enter product name"),
      description: Yup.string(),
      price: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid price")
        .required("Please enter price"),
      externalId: Yup.string().matches(
        /^[a-zA-Z0-9]*$/,
        "Numbers or letters only",
      ),
      quantity: Yup.string()
        .optional()
        .matches(/^\d+$/, "Value must be an integer")
        .nullable(),
      sku: Yup.string().matches(/^[a-zA-Z0-9]*$/, "Numbers or letters only"),
      salesTaxExcluded: Yup.bool(),
    }),
    onSubmit: async (values) => {
      setError(undefined);
      const payload = {
        title: values.title,
        description: values.description,
        price: {
          currency: currencyCode,
          value: +values.price,
        },
        externalId: values.externalId,
        sku: values.sku,
        quantity: +values.quantity,
        salesTaxExcluded: values.salesTaxExcluded,
      };

      mutateAsync(payload)
        .then((product: IProduct) => {
          const promises = [];

          for (const file of fileList) {
            promises.push(
              getBase64(file).then((image) => {
                if (image) {
                  return uploadImageAsync({
                    image,
                    name: file.name,
                    type: file.type,
                    productId: product._id,
                  });
                } else {
                  return Promise.reject("Error getting base64 from file");
                }
              }),
            );
          }
          Promise.all(promises)
            .then(() => {
              setFileList([]);
              formik.resetForm();
              onCancel();
            })
            .catch(() => {
              setError(
                "Whoops! Product created but image upload failed. You can go to product view and try re-upload from there.",
              );
            });
        })
        .catch((err) => {
          console.error("Error adding product" + err.message);
          if (err.response?.data?.statusCode === 409) {
            setError(
              err.response?.data?.message ?? "Whoops! Something went wrong",
            );
          } else {
            setError("Whoops! Something went wrong.");
          }
        });
    },
  });

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file, fileList) => {
      if (fileList.length > 5) {
        setError("Please select 5 images or less.");
        return false;
      } else {
        setError(undefined);
      }
      setFileList([...fileList]);

      return false;
    },
    fileList,
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {notification}
      <Modal
        title="Add Product"
        open={open}
        onOk={formik.submitForm}
        onCancel={onCancel}
        okButtonProps={{
          loading: isPending || isUploadImagePending,
          disabled: !!error,
        }}
      >
        <Flex gap={30} vertical style={{ padding: 20 }}>
          <div>
            <Input
              maxLength={50}
              placeholder="Name"
              {...formik.getFieldProps("title")}
            />
            <ErrorPanel message={formik.errors.title} />
          </div>
          <div>
            <Input.TextArea
              placeholder="Decription"
              {...formik.getFieldProps("description")}
            />
            <ErrorPanel message={formik.errors.description} />
          </div>

          <div>
            <Input
              placeholder="Quantity"
              {...formik.getFieldProps("quantity")}
            />
            <ErrorPanel message={formik.errors.quantity} />
          </div>

          <div>
            <Input
              prefix={currency}
              placeholder="Price"
              {...formik.getFieldProps("price")}
            />
            <ErrorPanel message={formik.errors.price} />
          </div>

          <div>
            <Input
              placeholder="BarCode Id"
              maxLength={100}
              {...formik.getFieldProps("externalId")}
            />
            <ErrorPanel message={formik.errors.externalId} />
          </div>

          <div>
            <Input
              maxLength={100}
              placeholder="SKU"
              {...formik.getFieldProps("sku")}
            />
            <ErrorPanel message={formik.errors.sku} />
          </div>

          <Checkbox
            onChange={(e) =>
              formik.setFieldValue("salesTaxExcluded", e.target.checked)
            }
            checked={formik.values.salesTaxExcluded}
          >
            <span>Sales Tax Excluded</span>
          </Checkbox>

          <div className="divSpread my-40">
            <Flex gap={10}>
              <Upload {...props} multiple={true} accept="image/png,image/jpeg">
                <Button
                  loading={isUploadImagePending}
                  disabled={fileList.length > 5 || isUploadImagePending}
                  icon={<UploadOutlined />}
                >
                  Upload Image
                </Button>
              </Upload>
            </Flex>
          </div>

          <div>{error && <Alert type="error" message={error} />}</div>
        </Flex>
      </Modal>
    </form>
  );
}
