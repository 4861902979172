import { IAmount } from "../types/types";

export function PriceComponent({
  price,
  prefix,
}: {
  price: IAmount;
  prefix?: string;
}) {
  if (price.oldValue) {
    return (
      <span>
        {prefix}
        <del>{price.oldValue}</del> {price.value}
      </span>
    );
  }
  return (
    <span>
      {prefix}
      {price.value}
    </span>
  );
}
