import {
  FileAddOutlined,
  LeftCircleTwoTone,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Descriptions,
  Input,
  List,
  Popconfirm,
  Skeleton,
  Table,
  Typography,
} from "antd";
import Modal from "antd/es/modal/Modal";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useAddProductsToVendor } from "../../../api/api";
import { useDeleteVendor } from "../../../api/api.delete";
import {
  useGetVendorById,
  useGetVendorProducts,
  useSearchAllProducts,
  useSearchProducts,
} from "../../../api/api.get";
import BreadCrumbComponent from "../../../components/breadcrumb.component";
import { useMessage } from "../../../components/notifications/message";
import { IProduct } from "../../../types/types";

const { Title } = Typography;

export default function ViewVendorsPage() {
  const { id, vid } = useParams();
  const { data: vendorData } = useGetVendorById(id, vid);
  const navigate = useNavigate();
  const { messageError, notification } = useMessage();
  const { data: vendorProductsData } = useGetVendorProducts(id, vid);
  const { mutateAsync: deleteAsync } = useDeleteVendor(id!!);

  const handleDelete = () => {
    deleteAsync({ vendorId: vid })
      .then(() => {
        navigate(`/organizations/${id}/vendors`);
      })
      .catch(() => messageError("Whoops! Something went wrong"));
  };

  const [isAddProductsMode, setIsAddProductsMode] = useState<boolean>(false);
  return (
    <div className="p-40">
      {notification}

      <AddProductToVendorModal
        onOk={() => setIsAddProductsMode(false)}
        open={isAddProductsMode}
        onCancel={() => setIsAddProductsMode(false)}
      />
      <BreadCrumbComponent
        items={[
          {
            title: "Home",
            url: `/organizations/${id}`,
          },
          {
            title: "Vendors",
            url: `/organizations/${id}/vendors`,
          },
        ]}
      />
      <div
        style={{ marginBottom: 100 }}
        className="card divColumn mx-0 my-0 flex-1"
      >
        <div className="divRight mb-40">
          <Popconfirm
            title="Delete"
            description="Are you sure? This will reset the vendor for any product if used."
            onConfirm={() => handleDelete()}
            okText="Yes"
            cancelText="No"
          >
            <Button danger type="link" color="danger">
              Delete Vendor
            </Button>
          </Popconfirm>
        </div>
        <Descriptions
          bordered
          items={[
            {
              key: "2",
              label: "Name",
              children: vendorData?.name,
            },
            {
              key: "3",
              label: "Email",
              children: vendorData?.email,
            },
            {
              key: "1",
              label: "Phone",
              children: vendorData?.phone,
            },
          ]}
        />

        <div className="divSpread divAlignItemsOnly my-40">
          <Title level={3}>Vendor Products</Title>

          <Button onClick={() => setIsAddProductsMode(!isAddProductsMode)}>
            Add Products
          </Button>
        </div>

        <div>
          <Table
            dataSource={vendorProductsData}
            onRow={(row, rowIndex) => {
              return {
                onClick: () => {
                  return navigate(`/organizations/${id}/products/${row._id}`); // click row
                },
              };
            }}
            columns={[
              {
                dataIndex: "title",
                key: "name",
                title: "Name",
              },
              {
                dataIndex: "restockThreshold",
                key: "restockThreshold",
                title: "Restock Threshold",
              },
              {
                dataIndex: "restockQuantity",
                key: "restockQuantity",
                title: "Restock Quantity",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

function AddProductToVendorModal({
  open,
  onOk,
  onCancel,
}: {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}) {
  const { id, vid } = useParams();
  const [searchText, setSearchText] = useState<string>("");

  const { data: allProducts, isLoading: allProductsLoading } =
    useSearchAllProducts(id);
  const [debouncedProductNameSearchText] = useDebounce(searchText, 300);
  const [items, setItems] = useState<IProduct[]>([]);

  const { data: productsByNameData, isLoading } = useSearchProducts(
    id,
    debouncedProductNameSearchText,
  );

  const { mutateAsync } = useAddProductsToVendor(id, vid);
  const [productsToCheckout, setProductsToCheckout] = useState<IProduct[]>([]);

  useEffect(() => {
    if (debouncedProductNameSearchText && productsByNameData) {
      setItems(productsByNameData);
    } else {
      setItems(allProducts ?? []);
    }
  }, [debouncedProductNameSearchText, allProducts, productsByNameData]);

  const handleRemoveItems = (item: IProduct) => {
    const _items = [...productsToCheckout];
    const index = _items.findIndex((p) => p._id === item._id);
    _items.splice(index, 1);
    setProductsToCheckout(_items);
  };

  const handleAddItems = (item: IProduct) => {
    const existingProductsToCheckout = [...productsToCheckout];
    existingProductsToCheckout.push(item);
    setProductsToCheckout(existingProductsToCheckout);
  };

  const [selectedItemsView, setSelectedItemsView] = useState<boolean>(false);

  const handleAddProductstoVendor = () => {
    mutateAsync({
      productIds: productsToCheckout.map((p) => p._id),
    })
      .then(onOk)
      .catch(console.error);
  };

  return (
    <Modal
      title="Add Products to Vendor"
      open={open}
      onOk={handleAddProductstoVendor}
      onCancel={onCancel}
    >
      <div className="p-20">
        <div className="divRight my-10">
          {selectedItemsView && (
            <Button
              type="text"
              icon={<LeftCircleTwoTone />}
              onClick={() => setSelectedItemsView(false)}
            >
              <strong>{productsToCheckout.length}</strong>
            </Button>
          )}

          {!selectedItemsView && (
            <Button
              type="text"
              icon={<FileAddOutlined />}
              onClick={() => setSelectedItemsView(true)}
            >
              <strong>{productsToCheckout.length}</strong>
            </Button>
          )}
        </div>

        {!selectedItemsView && (
          <div className="my-20">
            <Input
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              allowClear
              placeholder="Search..."
            />

            <div className="my-20">
              <Skeleton loading={allProductsLoading}>
                <List
                  itemLayout="horizontal"
                  dataSource={items}
                  loading={allProductsLoading || isLoading}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        productsToCheckout.map((p) => p._id).includes(item._id)
                          ? [
                              <Button
                                danger
                                type="link"
                                onClick={() => handleRemoveItems(item)}
                                icon={<MinusCircleOutlined />}
                              ></Button>,
                            ]
                          : [
                              <Button
                                type="link"
                                disabled={item.vendorId === vid}
                                onClick={() => handleAddItems(item)}
                                icon={<PlusCircleOutlined />}
                              ></Button>,
                            ],
                      ]}
                    >
                      <List.Item.Meta
                        avatar={<Avatar src={item.cover} />}
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </Skeleton>
            </div>
          </div>
        )}

        {selectedItemsView && (
          <div className="my-40">
            <div className="my-20">
              <List
                itemLayout="horizontal"
                dataSource={productsToCheckout}
                loading={allProductsLoading || isLoading}
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <Button
                        danger
                        type="link"
                        onClick={() => handleAddItems(item)}
                        icon={<MinusCircleOutlined />}
                      ></Button>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={<Avatar src={item.cover} />}
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
