import { Link } from "react-router-dom";

interface IProps {
  isAuthenticated: boolean;
}
export default function LogoComponent({ isAuthenticated }: IProps) {
  return (
    <Link
      to={isAuthenticated ? "/home" : "/"}
      style={{ textDecoration: "none" }}
    >
      <div
        id="logo-container"
        style={{
          padding: 10,
          cursor: "pointer",
        }}
      >
        <div
          style={{
            border: "2px solid white",
            borderRadius: 10,
            padding: 10,
            textAlign: "center",
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 20,
              textDecoration: "none",
            }}
          >
            Business
          </span>
        </div>
        <div style={{ textAlign: "end", padding: 5, fontSize: 12 }}>
          <span style={{ color: "white", textDecoration: "none" }}>by </span>
          <span style={{ color: "white" }}>expense</span>
          <span style={{ color: "yellow" }}>hut</span>
        </div>
      </div>
    </Link>
  );
}
