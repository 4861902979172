import { Button, Table, Typography } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetVendors } from "../../../api/api.get";
import { useNotifications2 } from "../../../components/notifications/notification";
import AddVendorModal from "./add-vendor-modal";

const { Title } = Typography;

export default function VendorsPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: vendors } = useGetVendors(id);

  const { notification, notifySuccess } = useNotifications2();

  const [isAdd, setIsAdd] = useState<boolean>(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Button type="link" onClick={() => navigate(`${row.id}`)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <div
      style={{ marginBottom: 100 }}
      className="card divColumn mx-40 my-40 flex-1"
    >
      {notification}
      <AddVendorModal
        onCancel={() => setIsAdd(false)}
        open={isAdd}
        onOk={() => {
          setIsAdd(false);
          notifySuccess({
            title: "Success",
            description: "Vendor successfully created.",
          });
        }}
      />
      <div className="divSpread divAlignItemsOnly m-40">
        <Title level={3}>Vendors</Title>
        <Button type="link" onClick={() => setIsAdd(true)}>
          Add Vendor
        </Button>
      </div>
      <Title level={5}>Add vendor partners.</Title>
      <Table dataSource={vendors} columns={columns} />
    </div>
  );
}
