import { MenuOutlined } from "@ant-design/icons";
import { Menu, MenuProps } from "antd";
import { useNavigate } from "react-router";
import { useAuthContext } from "../../providers/auth.provider";
import { IUser } from "../../types/types";
import LogoComponent from "./logo.component";
import { useFullViewContext } from "../../providers/fullview.provider";

export default function Header() {
  const { user, signout } = useAuthContext();
  const navigate = useNavigate();
  const { fullView } = useFullViewContext();

  const handleClick = (key: string) => {
    if (key === "whatis") {
      window.location.href = "https://www.expensehut.com";
    } else if (key === "logout") {
      signout();
    } else {
      navigate("/login");
    }
  };

  if (fullView) {
    return null;
  }
  return (
    <div className="bg-purple">
      <div className="divSpread divAlignItemsOnly">
        <LogoComponent isAuthenticated={!!user} />
        <MenuItems onPostClick={handleClick} user={user} />
      </div>
    </div>
  );
}

function MenuItems({
  onPostClick,
  user,
}: {
  onPostClick: (key: string) => void;
  user?: IUser | null;
}) {
  const items = !!user
    ? [
        {
          key: "SubMenu",
          icon: <MenuOutlined style={{ color: "white" }} />,
          children: [{ label: "Log out", key: "logout" }],
        },
      ]
    : [
        {
          key: "SubMenu",
          icon: <MenuOutlined style={{ color: "white" }} />,
          children: [
            { label: "Login", key: "login" },
            { label: "What is ExpenseHut", key: "whatis" },
          ],
        },
      ];

  const onClick: MenuProps["onClick"] = (e) => {
    onPostClick(e.key);
  };

  return (
    <Menu
      id="options-menu"
      onClick={onClick}
      mode="horizontal"
      style={{ backgroundColor: "inherit" }}
      items={items}
    />
  );
}
