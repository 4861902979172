import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

interface IBreadCrumProps {
  items: IBreadCrumbItem[];
}

interface IBreadCrumbItem {
  title: string;
  url: string;
}

export default function BreadCrumbComponent({ items }: IBreadCrumProps) {
  return (
    <div className="p-20">
      <Breadcrumb
        items={items.map((item) => {
          return {
            title: (
              <Link className="plainText" to={item.url}>
                {item.title}
              </Link>
            ),
          };
        })}
      />
    </div>
  );
}
