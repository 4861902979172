import { Flex, Input, InputRef, Modal, Select, Typography } from "antd";
import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateReward } from "../../../api/api";
import { useGetPointsRewardLevels } from "../../../api/api.get";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { getCountryPhoneCode } from "../../../utils/constants";
import { REWARD_MONETARY_TYPE } from "./add-reward-level-modal";

const { Title } = Typography;

export default function AddRewardModal({
  open,
  onCancel,
  countryCode,
}: {
  open: boolean;
  onCancel: () => void;
  countryCode?: string;
}) {
  const { id } = useParams();
  const textInputRef = useRef<InputRef>(null);
  const { mutateAsync, isPending } = useCreateReward(id);

  const { notifySuccess, notifyError } = useNotifications();

  const areaCode = getCountryPhoneCode(countryCode);

  const [rewardsOptions, setRewardsOptions] = useState<any[]>([]);

  const { data: rewardLevelData } = useGetPointsRewardLevels(id);

  useEffect(() => {
    if (textInputRef.current) {
      setTimeout(() => {
        textInputRef.current?.focus();
      }, 500);
    }
  }, [open]);

  useEffect(() => {
    if (rewardLevelData) {
      const options: any[] = [
        {
          label: "--Select--",
          value: "",
        },
      ];
      for (const r of rewardLevelData) {
        options.push({
          label: `[${r.monetaryType}] ${r.name}`,
          value: r.id,
        });
      }
      setRewardsOptions(options);
    }
  }, [rewardLevelData]);

  const formik = useFormik({
    initialValues: {
      phone: "",
      levelId: undefined,
      levelType: undefined,
      billAmount: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid phone number")
        .required("Please enter customer phone number."),
      levelId: Yup.string().required("Please select reward type"),
      levelType: Yup.string(),
      billAmount: Yup.number().typeError("Please enter valid number."),
    }),
    onSubmit: async (values) => {
      const selectedRewardLevel = rewardLevelData?.find(
        (r) => r.id === values.levelId,
      );

      if (
        selectedRewardLevel!!.monetaryType === "PERCENT" ||
        selectedRewardLevel!!.minAmount > 0
      ) {
        if (
          !values.billAmount ||
          +values.billAmount < selectedRewardLevel!!.minAmount
        ) {
          formik.setFieldError(
            "billAmount",
            "This coupon requires bill amount to be more than " +
              selectedRewardLevel!!.minAmount,
          );
          return false;
        }
      }

      mutateAsync({
        ...values,
        phone: `${areaCode}${values.phone}`,
        billAmount: +values.billAmount,
      })
        .then(() => {
          notifySuccess();
          formik.resetForm();
          onCancel();
        })
        .catch((err) => {
          if (err.response.data?.statusCode === 404) {
            notifyError();
          } else {
            notifyError();
          }
        });
    },
  });

  const selectedRewardLevel = useMemo(() => {
    return rewardLevelData?.find((r) => r.id === formik.values.levelId);
  }, [formik.values.levelId, rewardLevelData]);

  useEffect(() => {
    if (selectedRewardLevel) {
      formik.setFieldValue("levelType", selectedRewardLevel?.monetaryType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRewardLevel]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Grant Reward to Customer"
        open={open}
        onOk={formik.submitForm}
        okText={"OK"}
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
        okButtonProps={{
          disabled: isPending || Object.keys(formik.errors).length > 0,
          loading: isPending,
        }}
      >
        <Flex gap={10} vertical>
          <div>
            <p className="infoMsg">Customer phone number</p>

            <Input prefix={areaCode} {...formik.getFieldProps("phone")} />
            <ErrorPanel message={formik.errors.phone} />
          </div>

          <div className="divColumn">
            <p className="infoMsg">Select Points Coupon</p>
            <Select
              style={{ minWidth: 200 }}
              value={formik.values.levelId}
              onChange={(value) => formik.setFieldValue("levelId", value)}
              options={rewardsOptions}
              notFoundContent="No POINTS coupons available"
            />
            <ErrorPanel message={formik.errors.levelId} />
          </div>
          {selectedRewardLevel &&
            (selectedRewardLevel.monetaryType ===
              REWARD_MONETARY_TYPE.PERCENT.toString() ||
              selectedRewardLevel.minAmount > 0) && (
              <div>
                <p className="infoMsg">Total Bill Amount</p>
                <Input {...formik.getFieldProps("billAmount")} />
                <ErrorPanel message={formik.errors.billAmount} />
              </div>
            )}
          <Flex vertical>
            <span className="infoMsg">Rewards Points:</span>
            <Title level={3}>
              {selectedRewardLevel?.monetaryType ===
              REWARD_MONETARY_TYPE.PERCENT.toString()
                ? Math.round(
                    (selectedRewardLevel?.value / 100) *
                      +formik.values.billAmount,
                  )
                : selectedRewardLevel?.value}
            </Title>
          </Flex>
        </Flex>
      </Modal>
    </form>
  );
}
